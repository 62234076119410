﻿@import "../config";

.m-warranty-chat
{
	margin: 0;
	padding: 0 0 15px 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__title
	{
		@include font-size($fontSize + 5);
		font-weight: 400;
		@include line-height($fontHeight + 5);
		margin: 0;
		padding: 0 0 15px 0;
	}
	&__menu
	{
		border-bottom: 1px solid $colorDivider;
		margin: 0 0 15px 0;
		padding: 0 0 15px 0;
		max-width: $maxContentWidth;
		&__item
		{
			background: $colorOffWhite;
			margin: 10px 15px 0 15px;
			padding: 15px;
			position: relative;
			z-index: 1;
			&__reply
			{
				margin: 0;
				padding: 0;
			}
			&__files
			{
				font-size: 0;
				line-height: 0;
				margin: -10px 0 0 -10px;
				padding: 15px 0 0 0;
				&__item
				{
					display: inline-block;
					margin: 0;
					padding: 10px 0 0 10px;
					vertical-align: top;
				}
				&__link
				{
					color: $colorBase;
					display: inline-block;
					@include font-size($fontSize - 1);
					@include min-height(17px);
					@include line-height($fontHeight - 1);
					margin: 0;
					padding: 0 0 0 18px;
					position: relative;
					text-decoration: none;
					z-index: 1;
					&:before, &:after
					{
						content: "";
						display: block;
						margin: 0;
						padding: 0;
						position: absolute;
					}
					&:before
					{
						background: $colorBase;
						display: block;
						height: 8px;
						left: 4px;
						top: 3px;
						width: 6px;
						z-index: 1;
					}
					&:after
					{
						border: 6px solid transparent;
						border-bottom-width: 0;
						border-top-color: $colorBase;
						height: 0;
						left: 1px;
						top: 10px;
						width: 0;
						z-index: 2;
					}
					&:hover
					{
						color: $colorBase;
						text-decoration: underline;
					}
				}
			}
			&__meta
			{
				color: $colorGrey;
				@include font-size($fontSize - 2);
				@include line-height($fontHeight - 2);
				margin: 0;
				padding: 15px 0 0 0;
			}
			&:before
			{
				border: 15px solid transparent;
				border-width: 0 15px 15px 0;
				border-right-color: $colorOffWhite;
				content: "";
				display: block;
				height: 0;
				left: -15px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 0;
				width: 0;
				z-index: 1;
			}
			@include mq-min($bpDesktop)
			{
				margin-right: 30px;
			}
			&:first-child
			{
				margin-top: 0;
			}
			&--alt
			{
				background: $colorOffWhite;
				margin: 10px 15px 0 15px;
				&:before
				{
					border: 15px solid transparent;
					border-width: 15px 15px 0 0;
					border-top-color: $colorOffWhite;
					left: auto;
					right: -15px;
				}
				@include mq-min($bpDesktop)
				{
					margin-left: 30px;
				}
			}
		}
		@include mq-min($bpTabletLarge)
		{
			margin-bottom: 20px;
			padding-bottom: 20px;
		}
		@include mq-min($bpDesktop)
		{
			margin-bottom: 25px;
			padding-bottom: 25px;
		}
		@include mq-min($bpDesktopLarge)
		{
			margin-bottom: 30px;
			padding-bottom: 30px;
		}
	}
	&__add
	{
		margin: 0;
		padding: 0 15px;
		&__title
		{
			@include font-size($fontSize + 5);
			font-weight: 400;
			@include line-height($fontHeight + 5);
			margin: 0;
			padding: 0 0 15px 0;
		}
	}
	&--tight
	{
		padding-bottom: 0;
	}
}