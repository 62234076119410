﻿@import "../config";

.m-category-product {
	background: $colorWhite;
	margin: 0;
	padding: 0;
	position: relative;
	@include transition(box-shadow .2s);
	z-index: 1;

	&--border {
		border: 1px solid #EAEAEA;
		@include box-shadow(1px 1px 2px rgba($colorBlack, .05));
	}

	.m-grid__row--flex & {

		@supports (display: flex) {
			width: 100%;

			@include mq-min($bpTablet) {
				min-height: 100%;
				display: flex;

				.m-category-product__inner {
					display: flex;
					flex-flow: column nowrap;
					height: 100%;
					align-items: flex-start;
					width: 100%;
				}

				.m-category-product__actions {
					margin: auto 0 0 0;
					width: 100%;
				}

				.m-category-product__content,
				.m-category-product__cost,
				.m-category-product__delivery {
					width: 100%;
				}

				.m-category-product__image-wrapper {
					width: 100%;
					position: relative;
				}

				.m-category-product__options {
					z-index: 10;
				}
			}
		}
	}

	&__inner {
		@include min-height(75px);
		margin: 0;
		padding: 10px 10px 10px 120px;

		@include mq-min($bpTablet) {
			padding-left: 10px;
		}

		@include mq-min($bpDesktop) {
			padding: 15px;
		}
	}

	&__image {
		left: 0;
		margin: 0;
		padding: 10px;
		position: absolute;
		top: 0;
		width: 100px;
		z-index: 1;
		overflow: hidden;

		&__link {
			display: block;
			margin: 0;
			padding: 0;

			&:hover, &:focus {
				img {
					@include opacity();
				}
			}
		}

		@include mq-min($bpTablet) {
			left: auto;
			margin: -10px -10px 0 -10px;
			padding: 0;
			position: relative;
			top: auto;
			width: auto;
		}

		@include mq-min($bpDesktop) {
			margin: -15px -15px 0 -15px;
		}
	}

	&__options {
		bottom: 10px;
		@include font-size(11px);
		@include line-height(11px);
		margin: 0;
		padding: 0;
		position: absolute;
		right: 10px;
		z-index: 2;

		&__link {
			text-decoration: underline;

			&:hover,
			&:focus {
				color: $colorSecondary;

				@include mq-min($bpTablet) {
					color: $colorBase;
				}
			}

			@include mq-min($bpTablet) {
				color: $colorBase;
				text-decoration: none;
			}

			&__prefix {
				@include mq-min($bpTablet) {
					display: none;
				}
			}

			&__suffix {
				@include mq-max($bpTablet - 1) {
					display: none;
				}

				@include mq-min($bpTablet) {
					text-transform: none;
				}
			}
		}

		@include mq-min($bpTablet) {
			display: inline-block;
			text-transform: capitalize;
			font-weight: 700;
			padding: 5px 10px;
			background: $colorOrange;
			color: $colorBase;
			left: 0;
			right: auto;
			bottom: -5px;
			transform: none;
		}
	}

	&__content {
		@include min-height(40px);
		margin: 0;
		padding: 0;

		@include mq-min($bpTablet) {
			padding-top: 10px;
		}

		@include mq-min($bpDesktop) {
			padding-top: 15px;
		}
	}

	&__title {
		display: -webkit-box;
		@include font-size($fontSize);
		font-family: $fontBase;
		@include line-height($fontHeight - 1);
		margin: 0;
		overflow: hidden;
		padding: 5px 0 0 0;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

		&__link {
			color: $colorBase;
			display: block;
			font-family: $fontBase;
			font-weight: normal;
			margin: 0;
			padding: 0;

			&:hover, &:focus {
				color: $colorPrimary;
			}

			&.focus-visible {
				@include focus-style-alt();
			}
		}

		@include mq-min($bpTablet) {
			@include font-size($fontSize + 1);
			@include line-height($fontHeight);
		}

		.m-grid__col--2--desktop & {
			@include font-size($fontSize);
			@include line-height($fontHeight - 1);
			padding-top: 0;
		}
	}

	&__cost {
		margin: 0;
		padding: 5px 0 0 0;

		@include mq-min($bpDesktop) {
			padding-top: 15px;
		}
		// Make the products equal height if there is no delivery message
		&--no-delivery-message {
			margin-bottom: 20.8px;

			@include mq-min($bpDesktop) {
				margin-bottom: 22.6px;
			}
		}
	}

	&__price {
		@include box-sizing();
		color: $colorGrey;
		font-size: 0;
		line-height: 0;
		margin: 0 0 0 -10px;
		padding: 0 90px 0 0;

		@include mq-min($bpTablet) {
			padding-right: 0;
		}

		&__list, &__other {
			display: inline-block;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 0 0 0 10px;
		}

		&__list {
			color: $colorBase;
			@include font-size($fontSize + 3);
			font-weight: 700;
			@include line-height($fontHeight + 1);

			&__from {
				@include font-size($fontSize - 2);
				font-weight: 600;
				@include line-height($fontHeight - 2);
			}

			@include mq-min($bpDesktop) {
				@include font-size(21px);
				@include line-height(28px);
			}

			&--sale {
				color: $colorRed;
			}

			.m-grid__col--2--desktop & {
				@include font-size($fontSize + 3);
				@include line-height($fontHeight + 1);
			}
		}

		&__other {
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);

			&--vat {
				color: $colorRed;
				padding: 0;
			}
		}
	}

	&__delivery {
		padding: 5px 0 0 0;

		.m-banded-block & {
			min-height: 34px; // two lines to keep the grid height in check
			@include mq-max($bpTablet - 1) {
				padding-bottom: 15px;
				min-height: 0;
			}
		}
	}

	&__actions {
		display: none;
		margin: 0;
		padding: 10px 0 0 0;

		@include mq-min($bpTablet) {
			display: block;
		}
	}

	&__faux-link {
		bottom: 0;
		display: block;
		left: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		@include text-hidden();
		top: 0;
		z-index: 100;

		@include mq-min($bpTablet) {
			display: none;
		}
	}

	&:hover {
		background: lighten($colorOffWhite, 5%);
		border-color: $colorDivider;
	}
}
