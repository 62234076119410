﻿@import "../config";

.m-checkout-availability
{
  border: 1px solid $colorRed;
	margin: 0;
	padding: 15px;
  &__table
  {
    margin: 0;
    tr
    {
      th, td
      {
        border: none;
        padding: 5px 0;
      }
    }
  }
  &__header
  {
    color: $colorRed;
    font-weight: 600;
    padding: 10px 5px 0;
    th
    {
      padding-bottom: 0;
    }
    &--available
    {
      color: $colorBlue;
    }
  }
	&__edit
	{
		font-size: $fontSize - 2;
		line-height: $fontHeight - 2;
		padding: 10px 0 15px;
	}
}