﻿.c-vin__label {
	display: flex;
	align-content: center;
	align-items: center;
	margin: 0 0 10px 0;
}

.c-vin__label-text {
	padding: 0;
}

.c-vin__help-link {
	margin-left: 10px;
	margin-top: 3px;
}

.c-vin__form {
	width: 100%;
	max-width: 272px;
}

.c-vin__form-row {
	padding-bottom: 5px;
}