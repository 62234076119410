@import "../config";

.m-basket {
	margin: 0;
	padding: 0;

	&__inner {
		@include min-height(200px);
		margin: 0;
		padding: 0;

		@include mq-min($bpTablet) {
			@include min-height(300px);
		}
	}

	tr {
		th, td {
			@include font-size($fontSize);
			@include line-height($fontHeight);
		}
	}

	&__table {
		margin: 0;
		padding: 0;

		&__header {
			background: $colorOffWhite;
			border-top: 1px solid $colorDivider;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: -1px 0 0 0;
			padding: 15px;

			&__title {
				margin: 0;
				padding: 0;
			}

			@include mq-min($bpTabletLarge) {
				border-top: 0;
				margin: 15px 0 5px;
			}

			&:first-child {
				@include mq-min($bpTabletLarge) {
					margin-top: 0;
				}
			}

			&--close {
				@include mq-min($bpTabletLarge) {
					margin-top: 0;
				}
			}

			&--tight {
				@include mq-min($bpTabletLarge) {
					margin-bottom: 0;
				}
			}
		}

		table {
			border-bottom: 1px solid $colorDivider;
			margin-bottom: 0;

			tr {
				th, td {
					padding: 15px 10px;
					vertical-align: middle;

					@include mq-max($bpTabletLarge - 1) {
						border: 0;
						@include box-sizing();
						display: block;
						float: left;
						overflow: hidden;
						padding: 0;
						width: 100%;
					}

					@include mq-min($bpDesktop) {
						padding: 20px 10px;
					}

					&.details {
						font-size: 0;
						line-height: 0;
						padding-left: 0;

						.details {
							margin: 0;
							padding: 0;

							&__image {
								@include box-sizing();
								display: inline-block;
								margin: 0;
								padding: 0;
								vertical-align: middle;
								width: 30%;
								max-width: 135px;

								&__link {
									display: block;
									margin: 0;
									padding: 0;
								}

								@include mq-max($bpTabletLarge - 1) {
									display: block;
									left: 0;
									position: absolute;
									top: 15px;
									width: 110px;
									z-index: 1;
								}
							}

							&__content {
								@include box-sizing();
								display: inline-block;
								@include font-size($fontSize - 1);
								@include line-height($fontHeight - 1);
								margin: 0;
								padding: 0 0 0 15px;
								width: 70%;
								vertical-align: middle;

								@include mq-max($bpTabletLarge - 1) {
									display: block;
									overflow: hidden;
									padding: 0;
									width: auto;
								}
							}

							&__title {
								font-weight: 700;
								@include font-size($fontSize);
								@include line-height($fontHeight);
								margin: 0;
								padding: 0;

								&__link {
									color: $colorBase;
									text-decoration: none;

									&:hover {
										color: $colorBase;
										text-decoration: underline;
									}
								}
							}

							&__info {
								color: $colorGrey;
								@include font-size($fontSize);
								@include line-height($fontHeight);
								margin: 0;
								padding: 5px 0 0 0;

								&__item {
									margin: 0;
									padding: 0;

									b {
										font-weight: 600;
									}
								}

								@include mq-max($bpTabletLarge - 1) {
									padding-bottom: 5px;
								}
							}

							&__message {
								background: lighten($colorOffWhite, 8%);
								border: 1px solid $colorDivider;
								@include box-shadow();
								@include font-size($fontSize - 2);
								@include line-height($fontHeight - 2);
								margin: 10px 0 0 0;
								padding: 10px;
								max-width: 400px;

								&__to {
									margin: 0;
									padding: 0;

									&__email {
										color: $colorGrey;
									}
								}

								&__text {
									font-style: italic;
									margin: 0 0 -5px 0;
									padding: 5px 0 0 0;

									p {
										margin: 0;
										padding: 0 0 5px 0;
									}
								}
							}
						}
					}

					&.price {
						@include mq-max($bpTabletLarge - 1) {
							color: $colorPrimary;
							@include font-size($fontSize + 2);
							font-weight: 700;
							@include line-height($fontHeight + 2);
							margin: 0;
							padding: 10px 0 0 0;
						}

						@include mq-min($bpDesktop) {
							width: 80px;
						}

						@include mq-min($bpDesktopLarge) {
							width: 100px;
						}

						.narrow & {
							@include mq-min($bpTabletLarge) {
								width: 80px;
							}

							@include mq-min($bpDesktopLarge) {
								width: 100px;
							}
						}
					}

					&.quantity, &.hours {
						text-align: center;
						width: 80px;

						.quantity, .hours {
							&__update {
								margin: 19px auto 0 auto;
								padding: 0;
								width: 80px;

								.form-select, .form-text {
									margin: 0 auto;
								}

								@include mq-max($bpTabletLarge - 1) {
									display: inline-block;
									@include font-size($fontSize - 1);
									@include line-height($fontHeight - 1);
									margin: 0;
									padding-right: 10px;
									vertical-align: middle;
								}
							}

							&__action {
								@include font-size($fontSize - 1);
								@include line-height($fontHeight - 1);
								margin: 0;
								padding: 5px 0 0 0;

								.form-button {
									@include font-size($fontSize - 1);
									font-weight: 400;
									@include line-height($fontHeight - 1);
									margin: 0;
									padding: 0;
									min-width: 5px;
								}

								@include mq-max($bpTabletLarge - 1) {
									display: inline-block;
									padding: 0;
									vertical-align: middle;
								}
							}

							&__remove {
								@include font-size($fontSize - 1);
								font-weight: 600;
								@include line-height($fontHeight - 1);
								margin: 0;
								padding: 5px 0 0 0;

								@include mq-max($bpTabletLarge - 1) {
									display: inline-block;
									padding: 0;
									vertical-align: middle;
								}
							}

							&__review {
								margin: 0;
								padding: 0;

								&__helper {
									font-weight: 600;

									@include mq-min($bpTabletLarge) {
										display: none;
									}
								}

								@include mq-max($bpTabletLarge - 1) {
									display: block;
									@include font-size($fontSize - 1);
									@include line-height($fontHeight - 1);
									margin-top: -10px;
								}
							}
						}

						.quantity {
							&__action {
								.js & {
									display: none !important;
								}
							}
						}

						.hours {
							&__update {
								margin-top: 0;
							}

							&__review {
								padding-bottom: 5px;

								@include mq-max($bpTabletLarge - 1) {
									margin-top: 0;
								}
							}

							&__standard {
								color: $colorGrey;
								@include font-size($fontSize - 2);
								@include line-height($fontHeight - 2);
								margin: 0;
								padding: 0 0 5px 0;

								@include mq-min($bpTabletLarge) {
									margin-top: -2px;
								}
							}
						}

						@include mq-max($bpTabletLarge - 1) {
							clear: both;
							float: none;
							font-size: 0;
							line-height: 0;
							margin: 0;
							padding: 15px 0 0 0;
							text-align: left;
							width: auto;
						}

						@include mq-min($bpDesktop) {
							width: 120px;
						}

						@include mq-min($bpDesktopLarge) {
							width: 140px;
						}

						.narrow & {
							@include mq-min($bpTabletLarge) {
								width: 50px;
							}

							@include mq-min($bpDesktopLarge) {
								width: 100px;
							}
						}
					}

					&.total {
						text-align: right;
						width: 100px;

						@include mq-max($bpTabletLarge - 1) {
							display: none;
						}

						@include mq-min($bpDesktop) {
							width: 150px;
						}

						@include mq-min($bpDesktopLarge) {
							text-align: left;
							width: 250px;
						}

						.narrow & {
							width: 80px;

							@include mq-min($bpDesktopLarge) {
								width: 100px;
							}
						}
					}

					&.status {
						@include mq-max($bpTabletLarge - 1) {
							clear: both;
							float: none;
							margin: 0;
							padding: 5px 0 0 0;
						}

						@include mq-min($bpDesktop) {
							width: 100px;
						}

						@include mq-min($bpDesktopLarge) {
							width: 120px;
						}
					}

					&.notes {
						.notes {
							margin: 0;
							padding: 0;

							&__title {
								@include font-size($fontSize);
								font-weight: 600;
								@include line-height($fontHeight);
								margin: 0;
								padding: 0;
							}

							&__menu {
								margin: 0;
								padding: 5px 0 0 0;

								&__item {
									margin: 0;
									padding: 5px 0 0 0;

									&:first-child {
										padding-top: 0;
									}
								}
							}
						}

						@include mq-max($bpTabletLarge - 1) {
							clear: both;
							float: none;
							margin: 0;
							padding: 5px 0 0 0;
						}
					}
				}

				@include mq-max($bpTabletLarge - 1) {
					border-top: 1px solid $colorDivider;
					@include box-sizing();
					display: block;
					float: left;
					@include min-height(142px);
					overflow: hidden;
					padding: 15px 0 15px 120px;
					position: relative;
					width: 100%;
					z-index: 1;
				}

				&.discount {
					th, td {
						background: $colorPrimary;
						background: rgba($colorPrimary, .1);
						border-color: $colorWhite;
						color: $colorPrimary;
						font-weight: 600;
						padding: 8px 10px;

						&.details {
							@include font-size($fontSize - 1);
							@include line-height($fontHeight - 1);

							@include mq-max($bpTabletLarge - 1) {
								@include box-sizing();
								display: block;
								float: left;
								width: 70%;
							}
						}

						&.total {
							@include mq-max($bpTabletLarge - 1) {
								@include box-sizing();
								display: block;
								text-align: right;
								width: 30%;
							}
						}
					}

					@include mq-max($bpTabletLarge - 1) {
						border-color: $colorWhite;
						@include min-height(5px);
						padding: 0;
					}

					&--basket {
						th, td {
							background: $colorYellow;
							color: $colorWhite;
							font-weight: 600;
						}

						@include mq-max($bpTabletLarge - 1) {
							background: $colorYellow;
							border-color: $colorWhite;
							color: $colorWhite;
						}
					}
				}

				&.notes {
					@include mq-max($bpTabletLarge - 1) {
						padding-left: 0;
					}
				}
			}

			.m-basket__small-table-row {
				td,
				th {
					@include mq-min($bpDesktop) {
						padding: 10px;
					}

					div.quantity__update {
						margin-top: 0;
					}
				}
			}

			thead {
				tr {
					th, td {
						padding: 10px;
					}
				}

				@include mq-max($bpTabletLarge - 1) {
					display: none;
				}
			}

			tbody {
				@include mq-max($bpTabletLarge - 1) {
					display: block;
					margin: 0;
					padding: 0;
				}
			}
		}

		&__discount {
			background: $colorPrimary;
			background: rgba($colorPrimary, .1);
			color: $colorPrimary;
			font-size: 0;
			line-height: 0;
			margin: 15px 0;
			padding: 8px 10px;

			&__text, &__price {
				@include box-sizing();
				float: left;
				font-size: $fontSize - 1;
				font-weight: 600;
				line-height: $fontHeight - 1;
				margin: 0;
				padding: 0;
			}

			&__text {
				padding-right: 15px;

				@include mq-min($bpTablet) {
					padding-right: 5px;
					width: 60%;
					width: calc(100% - 100px);
				}

				@include mq-min($bpDesktopLarge) {
					width: calc(100% - 250px);
				}
			}

			&__price {
				@include mq-min($bpTablet) {
					float: right;
					text-align: right;
					width: 100px;
				}

				@include mq-min($bpDesktopLarge) {
					text-align: left;
					width: 250px;
				}
			}

			@include mq-min($bpTabletLarge) {
				padding: 10px;
			}
		}

		&__offer {
			background: $colorPrimary;
			border-top: 5px solid $colorWhite;
			color: $colorWhite;
			@include font-size($fontSize);
			font-weight: 700;
			@include line-height($fontHeight);
			margin: -1px 0 0 0;
			padding: 5px 10px;
			text-align: center;

			&__inner {
				display: inline-block;
				margin: 0;
				padding: 7px 0;
				position: relative;
				z-index: 1;

				&--delivery {
					@include min-height(19px);
					padding-left: 60px;

					&:before {
						@include bg('/images/prima/common/sprite-v2.png', no-repeat, -210px -159px);
						content: "";
						display: block;
						height: 33px;
						left: 5px;
						margin: -16px 0 0 0;
						padding: 0;
						position: absolute;
						top: 50%;
						width: 45px;
						z-index: 1;
					}
				}
			}

			@include mq-min($bpTabletLarge) {
				@include font-size($fontSize + 2);
				@include line-height($fontHeight + 2);
				padding: 10px;
			}
		}
	}

	&__totals {
		margin: 0;
		padding: 15px 0 0 0;
		position: relative;
		z-index: 1;

		&__table {
			margin: 0;
			padding: 0;

			table {
				margin-bottom: 0;

				tr {
					th, td {
						border: 0;
						margin: 0;
						padding: 0 5px 15px 5px;
						text-align: left;
						vertical-align: middle;

						@include mq-min($bpTabletLarge) {
							padding: 0 10px 15px 10px;
						}
					}

					th {
						padding-right: 5px;
						width: 105px;

						@include mq-min($bpTabletLarge) {
							width: auto;
						}
					}

					td {
						.form-row {
							margin: 0;
							padding: 0;

							.form-text {
								width: 58%;
								max-width: 150px;
							}
						}

						.codes {
							font-size: 0;
							line-height: 0;
							margin: -10px 0 0 -10px;
							padding: 0 0 5px 0;

							&__item {
								display: inline-block;
								@include font-size($fontSize - 2);
								@include line-height($fontHeight);
								margin: 0;
								padding: 10px 0 0 10px;
								position: relative;
								z-index: 1;
							}

							&__link {
								background: lighten($colorDivider, 10%);
								border: 1px solid $colorDivider;
								color: $colorBase;
								display: block;
								margin: 0;
								padding: 3px 25px 3px 5px;
								position: relative;
								text-decoration: none;
								z-index: 1;

								.remove {
									@include font-size($fontSize + 6);
									font-weight: 700;
									margin: 0;
									padding: 0;
									position: absolute;
									right: 5px;
									top: 3px;
									z-index: 1;
								}

								&:hover {
									background: $colorRed;
									border-color: $colorRed;
									color: $colorWhite;
								}
							}
						}

						@include mq-min($bpTabletLarge) {
							text-align: right;
							width: 250px;
						}

						@include mq-min($bpDesktopLarge) {
							text-align: left;
						}

						.narrow & {
							@include mq-min($bpTabletLarge) {
								width: 80px;
							}

							@include mq-min($bpDesktopLarge) {
								width: 100px;
							}
						}
					}

					&.form {
						td {
							@include mq-min($bpTabletLarge) {
								text-align: left;
							}
						}
					}
				}

				tfoot {
					th, td {
						border-top: 1px solid $colorDivider;
						@include font-size($fontSize + 2);
						font-weight: 700;
						@include line-height($fontHeight + 2);
						padding: 15px 5px 0 5px;

						@include mq-min($bpTabletLarge) {
							padding: 15px 10px 0 10px;
						}
					}
				}
			}

			@include mq-min($bpTabletLarge) {
				float: right;
				max-width: 100%;
				width: 395px;
			}

			@include mq-min($bpDesktop) {
				width: 410px;
			}

			@include mq-min($bpDesktopLarge) {
				width: 550px;
			}

			@include mq-min($bpLarge) {
				width: 555px;
			}

			.narrow & {
				@include mq-min($bpTabletLarge) {
					width: 265px;
				}

				@include mq-min($bpDesktopLarge) {
					width: 360px;
				}
			}
		}

		&__table__shipping__method__cell {
			@include mq-min($bpDesktop) {
				padding-right: 0 !important;
			}
		}

		&__addresses {
			clear: both;
			margin: 0;
			padding: 20px 0 0 0;

			&__inner {
				border-top: 1px solid $colorDivider;
				margin: 0;
				padding: 20px 0 0 0;

				@include mq-min($bpDesktop) {
					border: 0;
					padding: 0;
				}
			}

			@include mq-min($bpDesktop) {
				clear: none;
				float: left;
				padding: 0;
				width: 510px;
			}

			.narrow & {
				@include mq-min($bpDesktop) {
					width: 420px;
				}
			}
		}

		&__icons {
			margin: 0;
			padding: 0;

			@include mq-min($bpTabletLarge) {
				left: 15px;
				margin: 0;
				position: absolute;
				top: 20px;
				z-index: 1;
			}

			@include mq-min($bpDesktop) {
				top: 25px;
			}
		}

		@include mq-min($bpTabletLarge) {
			padding-top: 20px;
		}

		&--tight {
			padding-top: 0;
		}
	}

	&__actions {
		clear: both;
		margin: 0 auto;
		padding: 20px 0 30px 0;
		max-width: 395px;

		&__menu {
			font-size: 0;
			line-height: 0;
			margin: 0;
			padding: 0;
			text-align: center;

			&__item {
				display: block;
				@include font-size($fontSize);
				@include line-height($fontHeight);
				margin: 0;
				padding: 15px 0 0 0;
				vertical-align: top;

				&__or {
					padding: 0;
					text-align: center;
				}

				.form-button {
					display: inline-block;
					margin: 0;
					min-width: 226px;
					width: 100%;
				}

				&:first-child {
					padding-top: 0;
				}
			}

			@include mq-min($bpTabletLarge) {
				float: right;
			}

			@include mq-min($bpDesktop) {
				text-align: left;
				width: 260px;
			}
		}

		@include mq-min($bpTabletLarge) {
			padding: 25px 0 35px 0;
			max-width: none;
		}

		@include mq-min($bpDesktop) {
			padding: 30px 0 40px 0;
		}

		&--tight {
			padding-bottom: 0;
		}
	}
}

.m-basket__table {

	.product-delivery-cost {
		@include min-height(0);

		@include mq-max($bpTabletLarge - 1) {
			padding-top: 10px;
			padding-bottom: 10px;
		}

		th,
		td {

			@include mq-min($bpTabletLarge) {
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
	}

	.product-delivery-cost__title,
	.product-delivery-cost__context {
		@include font-size($fontSize - 1);
		font-weight: 700;
		@include line-height($fontHeight - 1);
	}

	.product-delivery-cost__spacer,
	.product-delivery-cost__title {

		@include mq-max($bpTabletLarge - 1) {
			display: none;
		}
	}

	.product-delivery-cost__context {

		@include mq-min($bpTabletLarge) {
			display: none;
		}
	}

	.product-delivery-cost__amount {

		@include mq-min($bpTabletLarge) {
			text-align: right;
			width: 100px;
		}

		@include mq-min($bpDesktop) {
			width: 150px;
		}

		@include mq-min($bpDesktopLarge) {
			text-align: left;
			width: 250px;
		}
	}
}
