﻿.c-footer {
  content-visibility: auto;
  contain-intrinsic-size: 600px;
  margin: 0 -10px;
  background-color: $colorBase;
  color: $colorWhite;
  padding: 30px 20px 0 20px;
  text-align: left;

  @include mq-min($bpDesktop) {
    padding: 100px 30px 0 20px;

    &.c-footer--slim {
      padding-top: 40px;
    }
  }
}

.c-footer__inner {
  max-width: 1520px;
  margin: 0 auto;
}

.c-footer__grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 20px;

  @include mq-min($bpTablet) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include mq-min($bpDesktop) {
    grid-template-columns: 170px 17% 1fr;
  }

  @include mq-min($bpLarge) {
    margin-bottom: 90px;
    grid-template-columns: 250px 17% 1fr;

    .c-footer--slim & {
      margin-bottom: 40px;
    }
  }
}

.c-footer__newsletter {
  @include mq-max($bpTablet - 1) {
    grid-column: 1 / span 2;
  }

  @media (min-width: $bpTablet) and (max-width: $bpDesktop - 1px) {
    grid-column: 1/3;
  }

  @include mq-min($bpDesktop) {
    margin-left: auto;
  }
}

.c-footer__title {
  @include font-size(15px);
  font-weight: 600;
  margin: 0 0 10px 0;
  padding: 0;

  @include mq-min($bpTablet) {
    @include font-size(18px);
  }

  @include mq-min($bpDesktop) {
    @include font-size(20px);
  }
}

.c-footer__info__item--phone {
  margin-top: 5px;
}

.c-footer__link {
  color: $colorWhite;

  &:hover,
  &:focus {
    color: $colorWhite;
    text-decoration: underline;
    text-underline-offset: 1px;
  }

  &.focus-visible {
    @include focus-style-alt($colorPrimary);
  }
}

.c-footer__link--bold {
  font-weight: 700;

  @include mq-min($bpDesktop) {
    @include font-size(16px);
  }
}

.c-footer__link--email {
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.c-footer__partners {

  @include mq-min($bpLargeMobile) {
    display: flex;
    align-items: end;
  }
}

.c-footer__bailey {
  @include mq-min($bpTablet) {
    display: flex;
    align-items: center;
  }
}

.c-footer__bailey__logo {
  display: inline-block;
  width: 75px;
  margin: 0;

  @include mq-min($bpDesktop) {
    width: 143px;
  }
}

.c-footer__bailey__name {
  display: block;
  text-transform: uppercase;
  white-space: nowrap;

  @include mq-min($bpTablet) {
    display: inline-block;
    margin-left: 20px;
  }
}

.c-footer__gibe {
  margin: 15px 0 0 0;

  @include mq-min($bpLargeMobile) {
    margin: 0 0 0 auto;
  }
}

.c-footer__gibe__link strong {
  text-transform: uppercase;
}

.c-footer__bottom {
  @include font-size(12px);
  margin-top: 20px;
  padding: 20px 0;
  border-top: 1px solid #585858;
  text-align: center;

  @include mq-min($bpTablet) {
    text-align: left;
    margin-top: 30px;
    padding: 30px 0;
  }

  @include mq-min($bpDesktop) {
    display: flex;
    position: relative;
  }

  @include mq-min(1800px) {
    border-top: none;

    &:before {
      content: '';
      background-color: #585858;
      position: absolute;
      top: 0;
      left: -120px;
      right: -120px;
      height: 0.25px;
    }
  }
}

.c-footer__bottom__company-info {
  margin-left: auto;
  opacity: 0.8;
  padding: 0;
}

.c-footer__bottom__copyright {
  margin-bottom: 10px;

  @include mq-min($bpLargeMobile) {
    padding-right: 20px;
  }

  @include mq-min($bpDesktop) {
    margin-bottom: 0;
  }
}

.c-footer__bottom__copyright__text {
  @include mq-min($bpTablet) {
    display: inline-block;
    margin: 0 30px 0 0;
  }
}

.c-footer__bottom__copyright__list {
  padding: 0;
  margin: 10px 0;

  @include mq-min($bpTablet) {
    display: inline-block;
    margin: 0;
  }
}

.c-footer__bottom__copyright__link {
  font-weight: 700;
  padding: 0 10px;
}

.c-footer__bottom__copyright__item:first-child .c-footer__bottom__copyright__link {

  @include mq-min($bpLargeMobile) {
    padding-left: 0;
  }
}

.c-footer__bottom__copyright__item {
  display: inline-block;
  padding: 0;
  border-right: 1px solid #FFF;
  line-height: 1;
}

.c-footer__bottom__copyright__item:last-child {
  border-right: 0;
}

.c-footer__hours-container {
  margin-top: 10px;

  @include mq-min($bpLarge) {
    margin-top: 50px;
  }
}

.c-footer__hours {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  max-width: 310px;
}

.c-footer__hours__title,
.c-footer__hours__content {
  margin-bottom: 5px;
}

.c-footer__hours__content {
  font-weight: 700;
}

.c-footer__newsletter__summary p {
  padding: 0;
  margin-bottom: 10px;

  @include mq-min($bpDesktop) {
    margin-bottom: 25px;
  }
}

.c-footer__newsletter__row {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  margin-bottom: 20px;

  @include mq-min($bpDesktop) {
    margin-bottom: 45px;
    min-width: 370px;
  }
}

.c-footer__newsletter__input {
  background-color: transparent;
  border: 1px solid $colorWhite;
  color: $colorWhite;
  @include font-size(16px);
  font-family: inherit;
  padding: 15px;
  flex: 1;
  border-radius: 0;
  @include placeholder-text($colorWhite, $colorWhite);

  &.focus-visible {
    @include focus-style-alt($colorPrimary);
  }
}

.c-footer__newsletter__button {
  background-color: $colorWhite;
  border: none;
  height: 100%;
  color: #2D2D2D;
  @include font-size(15px);
  font-family: inherit;
  font-weight: 700;
  cursor: pointer;
  width: 110px;
  border: 1px solid $colorWhite;
  transition: all 300ms;

  &:hover {
    background-color: $colorBase;
    color: $colorWhite;
  }

  &.focus-visible {
    @include focus-style-alt($colorPrimary);
  }
}

.c-footer__share-container {

  @include mq-max($bpTablet - 1) {

    @include mq-min($bpLargeMobile) {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;

      .c-footer__share__list {
        padding-bottom: 0;
      }

      .c-footer__title {
        margin: -5px 10px 0 0;
      }
    }
  }
}

.c-footer__share__list {
  display: flex;
  align-items: center;
}

.c-footer__share__item {
  padding: 0;
  margin-right: 15px;
}

.c-footer__share__link {
  transition: opacity 300ms;
  color: $colorWhite;

  &.focus-visible {
    @include focus-style-alt($colorPrimary);
  }
}

.c-footer__share__link:hover {
  opacity: 0.7;
}

.c-footer__share__icon {
  color: $colorWhite;

  @include mq-max($bpTablet - 1) {
    width: 44px;
    height: auto;
  }
}

.c-footer__share__icon--x {

  @include mq-max($bpTablet - 1) {
    width: 42px;
  }
}
