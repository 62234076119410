﻿@import "../config";

.m-notice
{
	background: $colorOffWhite;
	@include box-sizing();
	margin: 0 0 15px 0;
	padding: 15px;
	max-width: $maxContentWidth;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__title
	{
		@include font-size($fontSize + 10);
		font-weight: 400;
		@include line-height($fontHeight + 10);
		margin: 0;
		padding: 0 0 15px 0;
	}
	&__content
	{
		margin: 0;
		padding: 0;
		a
		{
			color: $colorBase;
			font-weight: 400;
			text-decoration: underline;
			&:hover
			{
				color: $colorBase;
				text-decoration: none;
			}
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding: 20px;
	}
	@include mq-min($bpDesktop)
	{
		padding: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding: 30px;
	}
	&--tight
	{
		margin-bottom: 0;
	}
}