﻿@import "../config";

.m-flash {
	display: none;
	left: -38px;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 14px;
	width: 120px;
	height: 14px;
	z-index: 100;
	font-size: 10px;
	line-height: 14px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 700;
	background-color: $colorBase;
	color: $colorWhite;
	transform: rotate(-45deg);
	padding: 5px;
	white-space: nowrap;

	&__text {
		display: block;
		max-width: 80px;
		margin: 0 auto;
	}

	&--exclusive, &--sale, &--preorder, &--made-to-order {
		display: block;
	}

	&--sale {
		background-color: #c10000;
	}

	&--made-to-order {
		top: 8px;
		height: 28px;
		white-space: normal;
	}
}