@import "config";
@import "base/reset";

// links
a
{
	color: $colorLink;
	text-decoration: none;
	&:hover
	{
		color: $colorBase;
		text-decoration: none;
	}
	&:focus
	{
		outline: 1px dotted $colorLink;
	}
}

// headers
h1, h2, h3, h4, h5, h6
{
	@include font-size($fontSize + 4);
	font-weight: 600;
	@include line-height($fontHeight + 4);
	margin: 0;
	padding: 0 0 15px 0;
}

// content
p
{
	margin: 0;
	padding: 0 0 15px 0;
}

small
{
	color: $colorGrey;
	font-size: 85%;
}

b, strong
{
	font-weight: 700;
}

hr
{
	margin: 20px 0 15px 0;
	@include mq-min($bpTabletLarge)
	{
		margin: 25px 0 20px 0;
	}
	@include mq-min($bpDesktop)
	{
		margin: 30px 0 25px 0;
	}
	@include mq-min($bpDesktopLarge)
	{
		margin: 35px 0 30px 0;
	}
	&.close
	{
		margin-top: 0;
	}
	&.tight
	{
		margin-bottom: 0;
	}
}

// table

table
{
	border: 0;
	border-collapse: collapse;
	margin: 0 0 15px 0;
	padding: 0;
	width: 100%;
	tr
	{
		th, td
		{
			border-top: 1px solid $colorDivider;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 10px 5px;
			text-align: left;
			vertical-align: top;
			a
			{
				display: inline;
			}
		}
	}
	thead
	{
		tr
		{
			th, td
			{
				border-top: 0;
				@include font-size($fontSize - 1);
				font-weight: 600;
				@include line-height($fontHeight - 1);
			}
		}		
	}
	&.table-no-divider
	{
		border-bottom: 1px solid $colorDivider;
		thead
		{
			tr
			{
				th, td
				{
					border-bottom: 1px solid $colorDivider;
				}
			}			
		}
		tbody
		{
			tr
			{
				th, td
				{
					border-top: 0;
				}
			}
		}
	}
	&.table-striped
	{
		tbody
		{
			tr
			{
				&:nth-child(even) 
				{
					th, td
					{
						background: lighten($colorOffWhite, 3%);
					}
				}
			}
		}
	}
	&.table-hover
	{
		tbody
		{
			tr
			{
				&:hover
				{
					th, td
					{
						background: $colorOffWhite;
					}
				}
			}
		}
	}
}

// focus
.js-focus-visible .focus-visible {
	outline: 2px solid $colorBase !important;
}

.js-focus-visible a.focus-visible,
.js-focus-visible button.focus-visible {
	outline-offset: 1px;
}
