﻿.c-video-link {
  display: inline-flex;
  align-items: center;
  position: relative;
  min-height: 32px;
  @include font-size(14px);
  @include line-height(19px);
  padding: 0;
  color: $colorPrimary;

  &:hover,
  &:focus {
    color: $colorBase;

    &:before {
      border-color: $colorBase;
    }

    &:after {
      border-left-color: $colorBase;
    }
  }
}

.c-video-link__icon {
    margin: 0 10px 0 0;
}