﻿.c-top-bar {
    position: relative;
    z-index: 8889;
    background-color: #F7F7F7;
    margin: 0 -10px;
    text-align: left;

    @include mq-max($bpDesktop - 1) {
        display: none;
    }

    .l-nav__sub {
        border-top: none;
    }
}

.c-top-bar__inner {
    display: flex;
    margin: 0 auto;
    max-width: 1920px;
    min-height: 72px;  
}

.c-top-bar__left {
}

.c-top-bar__right {
    margin-left: auto;
}

.c-top-bar__ups {
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;
}

.c-top-bar__ups-item {
    position: relative;
    padding: 14px 10px;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 22px;

    @include mq-min(1600px) {
        padding: 18px 30px;
    }

    &:after {
        display: flex;
        align-items: center;
        content: '|';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        color: #D1D1D1;
    }
}

.c-top-bar__ups-item--no-border:after {
    display: none;
}

.c-top-bar__ups-item-icon {
    margin-right: 5px;
}

.c-top-bar__ups-item--trustpilot {
    display: flex;
    align-items: center;
    color: $colorPrimary;

    &:after {
        display: none;
    }

    @include mq-min($bpDesktop) {
        padding-top: 24px;
        padding-left: 0;
    }
}

.c-top-bar__ups-item--hide-small {
    @include mq-max(1600px) {
        display: none;
    }
}

.c-top-bar__ups-trustpilot-title {
    font-weight: 700;
    margin-left: 3px;
}

.c-top-bar__account {
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;
}

.c-top-bar__account-item {
    padding: 0;
    height: 100%;

    &.with-sub {
        position: relative;
    }

    &:hover {
        .c-top-bar__account-link {
            background-color: $colorBase;
            color: $colorWhite;
        }

        .l-nav__sub {
            display: block;
        }
    }
}

.c-top-bar__account-link {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    @include font-size(14px);
    color: $colorBase;
    transition: all 300ms;

    @include mq-min($bpLarge) {
        padding: 0 30px;
    }

    &:hover,
    &:focus {
        background-color: $colorBase;
        color: $colorWhite;
    }
}

.c-top-bar__account-link:focus ~ .l-nav__sub {
    display: block;
}

.c-top-bar__account-link--account {
    border-right: 1px solid #CECECE;
}

.c-top-bar__account-link--bailey {
    background-color: $colorPrimary;
    color: $colorWhite;
    font-weight: 600;

    &:hover,
    &:focus {
        background-color: $colorBase;
        color: $colorWhite;
    }
}

.c-top-bar__account-link.c-top-bar__account-link--basket {
    background-color: $colorBase;
    color: $colorWhite;
    font-weight: 600;

    &:hover,
    &:focus {
        background-color: $colorPrimary;
        color: $colorWhite;
    }
}

.c-top-bar__account-icon {
    margin: 0 10px 0 0;
}

.c-top-bar__account-icon--right {
    margin: 0 0 0 10px;
}

.c-top-bar__account-basket-total,
.c-top-bar__account-wishlist-total {
    display: inline-block;
    margin-left: 5px;
}

.c-top-bar__account-same-line {
    white-space: nowrap;
}