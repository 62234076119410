@import "../config";
$bpSliderLimit: $bpLarge + 60;

.bx {
	&-prev, &-next {
		//background: yellow;
		bottom: 0;
		margin: 0;
		@include opacity();
		padding: 0;
		position: absolute;
		@include text-hidden();
		top: 0;
		width: 35px;
		z-index: 100;

		&:before {
			@include bg('/images/prima/common/sprite-v2.png', no-repeat, -236px -202px);
			content: "";
			display: block;
			height: 50px;
			left: 50%;
			margin: -25px 0 0 -9px;
			padding: 0;
			position: absolute;
			top: 50%;
			width: 18px;
			z-index: 1;

			.bx-small & {
				background-position-y: -213px;
				height: 28px;
				margin-top: -14px;
			}
		}

		&:hover {
			@include opacity(1);
		}

		&.disabled {
			display: none;
		}
	}

	&-prev {
		left: 0;

		&:before {
			.bx-green & {
				background-position-x: -292px;
			}

			.bx-white & {
				background-position: -292px -142px;
			}
		}

		.bx-offset & {
			@include mq-min($bpSliderLimit) {
				left: -35px;
			}
		}
	}

	&-next {
		right: 0;

		&:before {
			background-position: -264px -202px;

			.bx-green & {
				background-position-x: -320px;
			}

			.bx-white & {
				background-position: -320px -142px;
			}
		}

		.bx-offset & {
			@include mq-min($bpSliderLimit) {
				right: -35px;
			}
		}
	}

	&-pager {
		bottom: 5px;
		font-size: 0;
		left: 5px;
		line-height: 0;
		margin: -5px 0 0 -5px;
		padding: 0;
		position: absolute;
		text-align: left;
		z-index: 1000;

		&-item {
			@include box-sizing();
			display: inline-block;
			margin: 0;
			padding: 5px 0 0 5px;

			@include mq-min($bpTabletLarge) {
				padding: 6px 0 0 6px;
			}

			@include mq-min($bpDesktop) {
				padding: 7px 0 0 7px;
			}

			@include mq-min($bpDesktopLarge) {
				padding: 8px 0 0 8px;
			}
		}

		&-link {
			background: $colorOffWhite;
			display: block;
			height: 6px;
			margin: 0;
			padding: 0;
			@include text-hidden();
			width: 6px;

			&:hover, &:focus, &.active {
				background: $colorPrimary;
			}

			@include mq-min($bpTabletLarge) {
				height: 8px;
				width: 8px;
			}

			@include mq-min($bpDesktop) {
				height: 10px;
				width: 10px;
			}
		}

		@include mq-min($bpTabletLarge) {
			bottom: 10px;
			left: 10px;
			margin: -6px 0 0 -6px;
		}

		@include mq-min($bpDesktop) {
			bottom: 15px;
			left: 15px;
			margin: -7px 0 0 -7px;
		}

		@include mq-min($bpDesktopLarge) {
			bottom: 20px;
			left: 20px;
			margin: -8px 0 0 -8px;
		}
	}
}
