﻿@import "../config";

.m-promos
{
	margin: 0 auto;
	padding: 25px 0 15px 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__menu
	{
		font-size: 0;
		line-height: 0;
		margin: -15px 0 0 -15px;
		padding: 0;
		text-align: center;
		&__item
		{
			@include box-sizing();
			display: inline-block;
			@include font-size($fontSize);
			@include line-height($fontHeight);
			margin: 0;
			padding: 15px 0 0 15px;
			vertical-align: top;
			width: 100%;
			max-width: 332px;
			@include mq-min($bpTabletLarge)
			{
				width: 33.3333%;
				max-width: none;
			}
			@include mq-min($bpDesktop)
			{
				padding: 20px 0 0 20px;
			}
			@include mq-min($bpDesktopLarge)
			{
				padding: 25px 0 0 25px;
			}
			@include mq-min($bpLarge)
			{
				padding: 30px 0 0 30px;
			}
		}
	}
	&__item
	{
		background: $colorWhite;
		margin: 0;
		padding: 0;
		&__link
		{
			border: 1px solid $colorPrimary;
			display: block;
			margin: 0;
			padding: 5px;
			position: relative;
			z-index: 1;
			&:hover
			{
				@include opacity();
			}
			@include mq-min($bpTabletLarge)
			{
				padding-bottom: 60px;
			}
		}
		&__image
		{
			margin: 0 auto;
			max-width: 300px;
		}
		&__summary
		{
			color: $colorPrimary;
			@include font-size($fontSize - 2);
			@include line-height($fontHeight - 2);
			margin: 0;
			padding: 10px 5px;
			@include mq-min($bpTabletLarge)
			{
				bottom: 0;
				left: 0;
				position: absolute;
				right: 0;
				z-index: 1;
			}
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding: 50px 0 20px 0;
	}
	@include mq-min($bpDesktop)
	{
		padding: 75px 0 30px 0;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding: 100px 0 30px 0;
	}
	&--tight
	{
		padding-bottom: 0;
	}
	&--close
	{
		padding-top: 0;
	}
}