// configs
@import "config";
@import "reset";
@import "cms";
@import "forms";


// base
html {
  background: $colorWhite;
  @include min-height(100%);
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba($colorBlack, 0);
  @include text-size-adjust();
  min-width: 320px;

  @include mq-min($bpDesktop) {
    min-width: 960px;
  }
}

body {
  color: $colorBase;
  font-family: $fontBase;
  @include font-size($fontSize);
  height: 100%;
  @include line-height($fontHeight);
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;
  min-width: 320px;
  z-index: 1;

  @include mq-min($bpDesktop) {
    min-width: 960px;
  }

  &.claim {
    padding-top: 50px;
  }

  &.debug {
    &:before {
      background: #f8d928;
      content: "Mobile S";
      color: #fff;
      font-family: Verdana;
      font-size: 9px;
      left: 0;
      line-height: 9px;
      margin: 0;
      padding: 2px 3px;
      position: fixed;
      text-transform: uppercase;
      top: 0;
      z-index: 9000;

      @include mq-min($bpMobileLarge) {
        background: #f26522;
        content: "Mobile L";
      }

      @include mq-min($bpTablet) {
        background: #8dc63f;
        content: "Tablet S";
      }

      @include mq-min($bpTabletLarge) {
        background: #4caeeb;
        content: "Tablet L";
      }

      @include mq-min($bpDesktop) {
        background: #662d91;
        content: "Desktop S";
      }

      @include mq-min($bpDesktopLarge) {
        background: #ed145b;
        content: "Desktop L";
      }

      @include mq-min($bpLarge) {
        background: #da2229;
        content: "Large";
      }
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  *, ::before, ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}


// layout
@import "layout/page";
@import "layout/header";
@import "layout/nav";
@import "layout/breadcrumbs";
@import "layout/content";
@import "layout/newsletter";
@import "layout/back-to-top";
@import "layout/sitemap";


// plugins
@import "plugins/bx-slider";


// modules
@import "modules/grid";
@import "modules/popups";
@import "modules/basket-overlay";
@import "modules/side-nav";
@import "modules/nav-section";
@import "modules/back";
@import "modules/section-block";
@import "modules/banded-block";
@import "modules/header";
@import "modules/banner";
@import "modules/image-block";
@import "modules/news-block";
@import "modules/content-banner";
@import "modules/category-landing";
@import "modules/category-product";
@import "modules/category-pager";
@import "modules/flash";
@import "modules/promos";
@import "modules/search-block";
@import "modules/model-summary";
@import "modules/refine";
@import "modules/filter";
@import "modules/vin-search";
@import "modules/vin-lookup";
@import "modules/pager";
@import "modules/sort-options";
@import "modules/product";
@import "modules/swatch";
@import "modules/product-compatibility";
@import "modules/product-preview";
@import "modules/bundle-preview";
@import "modules/rating";
@import "modules/review";
@import "modules/basket";
@import "modules/payment-icons";
@import "modules/checkout-steps";
@import "modules/checkout-summary";
@import "modules/checkout-help";
@import "modules/checkout-group";
@import "modules/checkout-terms";
@import "modules/checkout-actions";
@import "modules/checkout-availability";
@import "modules/shipment-header";
@import "modules/dispatch-group";
@import "modules/recents";
@import "modules/share";
@import "modules/print-link";
@import "modules/help-link";
@import "modules/notice";
@import "modules/orders";
@import "modules/order-summary";
@import "modules/addresses";
@import "modules/contact-header";
@import "modules/contact-details";
@import "modules/tabs";
@import "modules/warranty-bar";
@import "modules/warranty-summary";
@import "modules/warranty-files";
@import "modules/warranty-chat";
@import "modules/history";
@import "modules/history-event";
@import "modules/blog-category";
@import "modules/blog-post";
@import "modules/toggle-nav";
@import "modules/filter-block";
@import "modules/dealer-search";
@import "modules/trade-search";
@import "modules/link-list";
@import "modules/more";
@import "modules/rss";
@import "modules/add-to-basket-overlay";

// components
@import "components/alert-banner";
@import "components/section";
@import "components/recommended-extras";
@import "components/quantity";
@import "components/small-product";
@import "components/vin";
@import "components/dispatch-date";
@import "components/image";
@import "components/skip-link";
@import "components/truncate";
@import "components/pre-footer";
@import "components/footer";
@import "components/top-bar";
@import "components/banner";
@import "components/video-link";
@import "components/search-overlay";
@import "components/cookie-policy";
@import "components/paypal-info";

// helpers
@import "base/helpers";

// fixes
@import "base/fixes";

// utils
@import "utils";
