﻿@import "../config";

.l-sitemap
{
	margin: 0 -10px;
	padding: 15px;
	&__inner
	{
		@include box-sizing();
		font-size: 0;
		line-height: 0;
		margin: 0 auto;
		padding: 0;
		text-align: left;
		max-width: $maxPageWidth;
	}
	&__group
	{
		@include font-size($fontSize - 1);
		@include line-height($fontHeight - 1);
		margin: 0;
		padding: 0 0 15px 0;
		vertical-align: top;
		width: 100%;
		&__title
		{
			@include font-size($fontSize + 1);
			font-weight: 600;
			@include line-height($fontHeight + 1);
			margin: 0;
			padding: 0 0 10px 0;
			a
			{
				color: $colorPrimary;
				&:hover
				{
					color: $colorBase;
				}
			}
		}
		&__inner
		{
			font-size: 0;
			line-height: 0;
			margin: 0;
			padding: 0;
			@include mq-min($bpTablet)
			{
				margin: -15px 0 0 -15px;
			}
			@include mq-min($bpDesktop)
			{
				margin: 0;
			}
		}
		&__menu
		{
			font-size: 0;
			line-height: 0;
			margin: -5px 0 0 -5px;
			padding: 0;
			&__item
			{
				@include box-sizing();
				display: inline-block;
				@include font-size($fontSize - 1);
				@include line-height($fontHeight - 1);
				margin: 0;
				padding: 5px 0 0 5px;
				vertical-align: top;
				width: 50%;
				@include mq-min($bpTablet)
				{
					display: block;
					width: auto;
				}
			}
			&__link
			{
				color: $colorBase;
				display: block;
				margin: 0;
				padding: 5px 0;
				text-decoration: none;
				&:hover
				{
					color: $colorBase;
					text-decoration: underline;
				}
			}
		}
	}
	&__contact
	{
		color: $colorPrimary;
		@include font-size($fontSize);
		font-weight: 700;
		@include line-height($fontHeight);
		margin: 0;
		padding: 15px 0 0 0;
		&__title
		{
			color: $colorBase;
			display: inline-block;
			font-weight: 700;
			text-transform: lowercase;
			width: 15px;
			&:after
			{
				content: ".";
			}
		}
		&__link
		{
			color: $colorPrimary;
			&:hover
			{
				color: $colorBase;
			}
		}
		@include mq-min($bpDesktop)
		{
			@include font-size($fontSize + 1);
			@include line-height($fontHeight + 1);
			padding-top: 25px;
		}
	}
	&__address
	{
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0 0 15px 0;
		&__item
		{
			display: inline-block;
			@include font-size($fontSize - 1);
			font-weight: 400;
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 0;
			&:after
			{
				content: ",";
				margin: 0;
				padding: 0 3px 0 0;
			}
			&:last-child
			{
				&:after
				{
					display: none;
				}
			}
		}
		@include mq-min($bpTablet)
		{
			padding: 15px 0 0 15px;
		}
		@include mq-min($bpDesktop)
		{
			padding: 0 0 15px 0;
		}
	}
	&__map
	{
		@include box-sizing();
		display: inline-block;
		margin: 0;
		padding: 0 0 20px 0;
		vertical-align: top;
		width: 100%;
		&__image
		{
			margin: 0;
			padding: 0;
		}
		@include mq-min($bpTablet)
		{
			padding: 15px 0 0 15px;
			width: 50%;
		}
		@include mq-min($bpDesktop)
		{
			display: block;
			padding: 0 0 20px 0;
			width: auto;
		}
	}
	&__hours
	{
		&__list
		{
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 0;
			&__title, &__data
			{
				@include box-sizing();
				float: left;
				margin: 0;
				padding: 5px 0 0 0;
				width: 65%;
				word-wrap: break-word;
			}
			&__title
			{
				clear: both;
				font-weight: 400;
				padding-right: 5px;
				width: 35%;
				max-width: 100px;
			}
			&__data
			{
				font-weight: 600;
			}
		}
	}
	@include mq-min($bpTabletLarge)
	{
		border-top: 0;
		padding: 25px 15px;
	}
	@include mq-min($bpDesktop)
	{
		padding: 35px 15px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding: 45px 15px;
	}
}