.c-dispatch-date {
	margin: 0;
	padding: 0 0 0 30px;
	background-size: 20px 17px;
	background-repeat: no-repeat;
	background-position: 0 0;
	min-height: 30px;
	font-family: Helvetica, Arial, sans-serif;
	font-size: 12px;
	line-height: 1.3;


	a {
		color: $colorPayPalBlue;
		text-decoration: underline;
		text-underline-offset: 1px;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
}

.c-dispatch-date--in {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMC4wMiIgaGVpZ2h0PSIxNS4wMTUiIHZpZXdCb3g9IjAgMCAyMC4wMiAxNS4wMTUiPg0KICA8cGF0aCBpZD0iaWNvbm1vbnN0ci1kZWxpdmVyeS01XzFfIiBkYXRhLW5hbWU9Imljb25tb25zdHItZGVsaXZlcnktNSAoMSkiIGQ9Ik00LjE3MSwxNS41MTJIMy4zMzdhLjgzNC44MzQsMCwwLDEtLjgzNC0uODM0VjEzLjAxaDEwLjAxVjUuNWgzLjg5M2ExLjczNiwxLjczNiwwLDAsMSwxLjYxNS45MjNjLjUuNzg0LDEuMjgxLDIuMDI5LDEuNzU5LDIuODE4YTEuNjc0LDEuNjc0LDAsMCwxLC4yNC44NjN2My43MzdhMS41NzksMS41NzksMCwwLDEtMS42NjgsMS42NjhoLS44MzRhMi41LDIuNSwwLDAsMS01LjAwNSwwSDkuMTc2YTIuNSwyLjUsMCwwLDEtNS4wMDUsMFptMi41LTFhMSwxLDAsMSwxLTEsMUExLDEsMCwwLDEsNi42NzMsMTQuNTExWm04LjM0MiwwYTEsMSwwLDEsMS0xLDFBMSwxLDAsMCwxLDE1LjAxNSwxNC41MTFaTTYuNjczLDcuMTcxSDBWNS41SDYuNjczVjQuNjY4SDIuNVYzLjgzNEEuODM0LjgzNCwwLDAsMSwzLjMzNywzaDcuNTA3YS44MzQuODM0LDAsMCwxLC44MzQuODM0djguMzQySDIuNXYtMi41SC44MzRWOEg2LjY3M1ptNy41MDgsMHYyLjVoMy45MzNsLTEuMTktMi4wODJhLjgzMy44MzMsMCwwLDAtLjcyNC0uNDJaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0zKSIgZmlsbD0iIzFlYWI2ZiIgZmlsbC1ydWxlPSJldmVub2RkIi8+DQo8L3N2Zz4NCg==);
}

.c-dispatch-date--order {
	background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iaWNvbm1vbnN0ci1kZWxpdmVyeS0zIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMC4wMjMiIGhlaWdodD0iMTYuNjg2IiB2aWV3Qm94PSIwIDAgMjAuMDIzIDE2LjY4NiI+DQogIDxwYXRoIGlkPSJpY29ubW9uc3RyLWRlbGl2ZXJ5LTMtMiIgZGF0YS1uYW1lPSJpY29ubW9uc3RyLWRlbGl2ZXJ5LTMiIGQ9Ik0zLjMzNywxNi4xODNIMi41YS44MzUuODM1LDAsMCwxLS44MzQtLjgzNFYxMy42OEgxMi41MTRWNi4xNzFoMy44OTRhMS43MzYsMS43MzYsMCwwLDEsMS42MTUuOTI0Yy41Ljc4NCwxLjI4MSwyLjAyOSwxLjc2LDIuODE4YTEuNjc0LDEuNjc0LDAsMCwxLC4yNC44NjN2My43MzhhMS41NzgsMS41NzgsMCwwLDEtMS42NjksMS42NjlIMTcuNTJhMi41LDIuNSwwLDAsMS01LjAwNiwwSDguMzQzYTIuNSwyLjUsMCwwLDEtNS4wMDYsMFptMi41LTFhMSwxLDAsMSwxLTEsMUExLDEsMCwwLDEsNS44NCwxNS4xODJabTkuMTc3LDBhMSwxLDAsMSwxLTEsMUExLDEsMCwwLDEsMTUuMDE3LDE1LjE4MlpNMS42NjksMTAuMTI3QTQuNTg4LDQuNTg4LDAsMSwxLDguMTI3LDMuNjY5aDIuNzE5YS44MzUuODM1LDAsMCwxLC44MzQuODM0djguMzQzSDEuNjY5Wk0xNC4xODMsNy44NHYyLjVoMy45MzRMMTYuOTI2LDguMjYxYS44MzMuODMzLDAsMCwwLS43MjQtLjQyWk00LjU4OSwzLjY2OWEyLjkyLDIuOTIsMCwxLDEtMi45MiwyLjkyQTIuOTIxLDIuOTIxLDAsMCwxLDQuNTg5LDMuNjY5Wm0uNDE3LDIuNUg2LjY3NHYuODM0aC0yLjVWNC41aC44MzRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0yKSIgZmlsbD0iI2ZlY2UyZiIgZmlsbC1ydWxlPSJldmVub2RkIi8+DQo8L3N2Zz4NCg==);
}

.c-dispatch-date--unavailable {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMC4wMiIgaGVpZ2h0PSIxNS4wMTUiIHZpZXdCb3g9IjAgMCAyMC4wMiAxNS4wMTUiPg0KICA8cGF0aCBpZD0iaWNvbm1vbnN0ci1kZWxpdmVyeS01XzFfIiBkYXRhLW5hbWU9Imljb25tb25zdHItZGVsaXZlcnktNSAoMSkiIGQ9Ik00LjE3MSwxNS41MTJIMy4zMzdhLjgzNC44MzQsMCwwLDEtLjgzNC0uODM0VjEzLjAxaDEwLjAxVjUuNWgzLjg5M2ExLjczNiwxLjczNiwwLDAsMSwxLjYxNS45MjNjLjUuNzg0LDEuMjgxLDIuMDI5LDEuNzU5LDIuODE4YTEuNjc0LDEuNjc0LDAsMCwxLC4yNC44NjN2My43MzdhMS41NzksMS41NzksMCwwLDEtMS42NjgsMS42NjhoLS44MzRhMi41LDIuNSwwLDAsMS01LjAwNSwwSDkuMTc2YTIuNSwyLjUsMCwwLDEtNS4wMDUsMFptMi41LTFhMSwxLDAsMSwxLTEsMUExLDEsMCwwLDEsNi42NzMsMTQuNTExWm04LjM0MiwwYTEsMSwwLDEsMS0xLDFBMSwxLDAsMCwxLDE1LjAxNSwxNC41MTFaTTYuNjczLDcuMTcxSDBWNS41SDYuNjczVjQuNjY4SDIuNVYzLjgzNEEuODM0LjgzNCwwLDAsMSwzLjMzNywzaDcuNTA3YS44MzQuODM0LDAsMCwxLC44MzQuODM0djguMzQySDIuNXYtMi41SC44MzRWOEg2LjY3M1ptNy41MDgsMHYyLjVoMy45MzNsLTEuMTktMi4wODJhLjgzMy44MzMsMCwwLDAtLjcyNC0uNDJaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0zKSIgZmlsbD0iIzllOWU5ZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+DQo8L3N2Zz4NCg==)
}

.c-dispatch-date--small {
	background-size: 20px 17px;
	min-height: 18px;
	padding: 0 0 0 25px;
	font-size: 11px;
	line-height: 1.5;
	color: $colorGreyMid;
}
