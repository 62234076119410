﻿@import "../config";

.l-breadcrumbs {
	position: relative;
	margin: -15px -10px 0 -10px;
	padding: 15px 10px 9px 10px;
	margin-bottom: 15px;
	text-transform: uppercase;
	background-color: $colorOffWhite;
	border-top: 1px solid $colorWhite;

	@include mq-min($bpDesktop) {
		border-top: none;
		margin: -15px 0 0 0;
		padding: 15px 0 9px 0;

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			width: 50vw;
			height: 100%;
			background-color: $colorOffWhite;
		}

		&:before {
			left: -50vw;
		}

		&:after {
			right: -50vw;
		}
	}

	&__inner {
		margin: 0 auto;
		padding: 0;
		text-align: left;
		max-width: $maxPageWidth;
	}

	&__menu {
		color: $colorGreyMid;
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0;

		&__item {
			@include box-sizing();
			display: inline-block;
			@include font-size(12px);
			@include line-height(17px);
			font-weight: 600;
			margin: 0;
			padding: 0 6px 6px 0;
			position: relative;
			vertical-align: top;
			z-index: 1;

			&:after {
				content: ">";
				letter-spacing: -1px;
				margin: 0;
				padding: 0 0 0 2px;

				@include mq-min($bpTabletLarge) {
					padding-left: 4px;
				}
			}

			&:last-child {
				padding-right: 0;

				&:after {
					display: none;
				}
			}

			@include mq-min($bpTabletLarge) {
				padding-right: 8px;
			}

			> span {
				display: inline-block;
				padding: 5px 0;
			}
		}

		&__link {
			color: $colorPrimary;
			text-decoration: none;
			padding: 5px 0;
			display: inline-block;

			&:hover {
				color: $colorPrimary;
				text-decoration: underline;
			}
		}
	}

	@include mq-min($bpTabletLarge) {
		margin-bottom: 20px;
	}

	@include mq-min($bpDesktop) {
		margin-bottom: 25px;
	}

	@include mq-min($bpDesktopLarge) {
		margin-bottom: 30px;
	}

	@include mq-min($bpLarge) {
		margin-bottom: 35px;
	}

	&--tight {
		padding-bottom: 0;
	}
}