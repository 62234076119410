﻿@import "../config";

@-moz-keyframes pulsate 
{
  0% 
	{
		background-color: $colorWhite;
  }
  50% 
	{
		background-color: $colorOffWhite;
  }
  100% 
	{
		background-color: $colorWhite;
  }
}

@-webkit-keyframes pulsate 
{
  0% 
	{
		background-color: $colorWhite;
  }
  50% 
	{
		background-color: $colorOffWhite;
  }
  100% 
	{
		background-color: $colorWhite;
  }
}

@keyframes pulsate 
{
  0% 
	{
		background-color: $colorWhite;
  }
  50% 
	{
		background-color: $colorOffWhite;
  }
  100% 
	{
		background-color: $colorWhite;
  }
}

.m-dealer-search
{
	margin: 0 auto;
	padding: 0 0 15px 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__form
	{
		background: $colorPrimary;
		color: $colorWhite;
		margin: 0;
		padding: 8px;
		&__title
		{
			@include font-size($fontSize + 4);
			font-weight: 400;
			@include line-height($fontHeight + 4);
			margin: 0;
			padding: 0 0 10px 0;
			@include mq-min($bpTablet)
			{
				float: left;
				margin: -8px 0;
				padding: 14px 15px 14px 0;
				max-width: 35%;
			}
		}
		&__inner
		{
			margin: 0;
			padding: 0;
			@include mq-min($bpTablet)
			{
				float: left;
				width: 100%;
				max-width: 65%;
			}
			@include mq-min($bpDesktop)
			{
				max-width: 400px;
			}
		}
		.form-row
		{
			margin: 0;
			padding: 0;
			.form-text
			{
				max-width: 80%;
				max-width: calc(100% - 38px);
			}
			.form-button
			{
				margin: 0;
			}
		}
		@include mq-min($bpDesktop)
		{
			padding: 8px 15px;
		}
		.form-error {
			color: $colorWhite;
		}
	}
	&__map
	{
		background: $colorOffWhite;
		display: none;
		height: 250px;
		margin: 0;
		padding: 8px;
		@include mq-min($bpTablet)
		{
			height: 300px;
		}
		@include mq-min($bpTabletLarge)
		{
			height: 350px;
		}
		.js &
		{
			display: block;
		}
	}
	&__results
	{
		margin: 0;
		padding: 1px 0 0 0;
		&__table
		{
			margin: 0;
			padding: 10px 0 0 0;
			table
			{
				margin: 0;
				thead
				{
					@include mq-max($bpTablet - 1)
					{
						display: none;
					}
				}
				tbody
				{
					@include mq-max($bpTablet - 1)
					{
						display: block;
					}	
				}
				tr
				{
					th, td
					{
						@include mq-max($bpTablet - 1)
						{
							border: 0;
							display: block;
							margin: 0;
							padding: 10px 0 0 0;
						}	
						&.index, &.dealer
						{
							@include mq-max($bpTablet - 1)
							{
								display: inline-block;
								padding: 0 5px 0 0;
								vertical-align: middle;
							}	
						}
						&.index
						{
							@include mq-min($bpTablet)
							{
								width: 25px;
							}	
						}
						&.dealer
						{
							@include mq-max($bpTablet - 1)
							{
								padding-right: 0;
							}	
							@include mq-min($bpTablet)
							{
								width: 100px;
							}	
							@include mq-min($bpDesktop)
							{
								width: 150px;
							}	
							@include mq-min($bpDesktopLarge)
							{
								width: 200px;
							}	
						}
						&.distance
						{
							&:before
							{
								content: "Distance: ";
								font-weight: 600;
								@include mq-min($bpTablet)
								{
									display: none;
								}	
							}
							@include mq-min($bpTablet)
							{
								width: 80px;
							}	
							@include mq-min($bpDesktop)
							{
								width: 100px;
							}	
						}
					}
					@include mq-max($bpTablet - 1)
					{
						border-top: 1px solid $colorDivider;
						display: block;
						margin: 15px 0 0 0;
						padding: 15px 0 0 0;
					}	
					&:first-child
					{
						@include mq-max($bpTablet - 1)
						{
							border-top: 0;
							margin-top: 0;
							padding-top: 0;
						}	
					}
					&.pulse
					{
						th, td
						{
							@include mq-min($bpTablet)
							{
								@include animation(pulsate 1s 3);
							}
						}
					}
				}
				@include mq-max($bpTablet - 1)
				{
					display: block;
				}		
			}
		}
	}
	&__content
	{
		background: $colorOffWhite;
		margin: 0;
		padding: 15px 8px 0 8px;
		@include mq-min($bpDesktop)
		{
			padding: 15px 15px 0 15px;
		}
	}
	&--tight
	{
		padding-bottom: 0;
	}
}