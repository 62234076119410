@import "../config";

.m-help-link
{
	background: $colorPrimary;
	display: inline-block;
	height: 19px;
	margin: 0 0 -1px 0;
	overflow: hidden;
	padding: 0;
	position: relative;
	@include text-hidden();
	vertical-align: middle;
	width: 19px;
	z-index: 1;
	border-radius: 100%;
	&:before
	{
		color: $colorWhite;
		content: "?";
		display: block;
		font-size: 12px;
		font-weight: 600;
		left: 0;
		line-height: 12px;
		margin: 0;
		padding: 0;
		position: absolute;
		text-align: center;
		text-indent: 0;
		right: 0;
		top: 4px;
		z-index: 1;
	}
	&:hover
	{
		background: $colorBase;
	}
}

.m-help-link--alternate {
	text-indent: 0;
	width: auto;
	height: auto;
	background: none;
	border-radius: 0;
	background: none;
	display: inline-block;
	position: relative;
	padding: 2px 0 0 25px;
	@include font-size(12px);
	@include line-height(17px);
	font-weight: 600;
	min-height: 20px;

	&:before {
		color: $colorWhite;
		background: $colorPrimary;
		content: "?";
		display: block;
		width: 20px;
		height: 20px;
		font-size: 12px;
		font-weight: 600;
		left: 0;
		line-height: 20px;
		margin: 0;
		padding: 0;
		position: absolute;
		text-align: center;
		top: 0;
		border-radius: 100%;
	}

	&:hover,
	&:focus {
		background: none;

		&:before {
			background: $colorBase;
		}
	}
}