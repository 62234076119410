@import "../config";

.l-page
{
	background: $colorWhite;
	margin: 0;
	padding: 0 10px;
	overflow-x: hidden;
	&__inner
	{
		margin: 0;
		padding: 0 0 25px 0;
	}
	&__content
	{
		@include box-sizing();
		margin: 0 auto;
		padding: 15px 0 25px 0;
		text-align: left;
		max-width: $maxPageWidth;
	}

	.html--search-overlay-open & {
		overflow: visible;
	}
}