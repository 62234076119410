@import "../config";

// wrapper
.form-wrapper
{
	margin: 0 auto;
	padding: 0 0 20px 0;
	text-align: left;
	&:last-child, &--tight
	{
		padding-bottom: 0;
	}
	&--divider
	{
		border-bottom: 1px solid $colorDivider;
		margin: 0 0 20px 0;
		padding: 0 0 20px 0;
		&:last-child
		{
			border-bottom: 0;
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
	&--centralised
	{
		text-align: center;
		max-width: 450px;
	}
}

// row
.form-row
{
	@include box-sizing();
	font-size: 0;
	line-height: 0;
	margin: 0;
	padding: 0 0 15px 0;
	text-align: left;
	> * 
	{
		@include font-size($fontSize);
		@include line-height($fontHeight);
	}
	&--divider
	{
		border-bottom: 1px solid $colorDivider;
		margin: 0 0 15px 0;
		padding: 0 0 15px 0;
		&:last-child
		{
			border-bottom: 0;
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
	&--inline
	{
		font-size: 0;
		line-height: 0;
		margin: -15px 0 0 -10px;
		padding: 0 0 15px 0;
		max-width: 460px;
		.form-row
		{
			@include box-sizing();
			display: inline-block;
			@include font-size($fontSize);
			@include line-height($fontHeight);
			padding: 15px 0 0 10px;
			vertical-align: top;
			width: 100%;
			@include mq-min($bpTabletLarge)
			{
				width: 33.3333%;
				max-width: 153px;
			}
			&--half
			{
				@include mq-min($bpMobileLarge)
				{
					width: 50%;
					max-width: 230px;
				}				
			}
		}
	}
	&--group
	{
		padding-bottom: 5px;
		.form-row
		{
			padding-bottom: 10px;
		}
	}
}

// action
.form-action
{
	font-size: 0;
	line-height: 0;
	margin: 0;
	padding: 10px 0 0 0;
	> * 
	{
		@include font-size($fontSize);
		@include line-height($fontHeight);
	}
	&:first-child
	{
		padding-top: 0;
	}
}

// labels
label
{
	cursor: pointer;
	.form-row &
	{
		display: block;
		padding-bottom: 5px;
		text-align: left;
	}
}

// required
.form-required
{
	color: $colorRed !important;
	font-family: $fontSimple;
}

// inputs
.form-text, .form-textarea, .form-select
{
	border: 1px solid $colorDivider;
	@include border-radius(0);
	@include box-sizing();
	@include box-shadow(none);
	color: $colorBase;
	display: inline-block;
	font-family: $fontSimple;
	@include font-size(16px);
	height: 38px;
	@include line-height($fontHeight);
	margin: 0;
	padding: 5px 10px;
	@include placeholder-text($colorBase, rgba($colorBase,.8));
	vertical-align: middle;
	width: 100%;
	max-width: 450px;
	&[type=search] 
	{
		-webkit-appearance: textfield;
	}
	&:hover 
	{
		border-color: darken($colorBorder, 10%);
	}
	&:focus 
	{
		border-color: darken($colorBorder, 20%);
	}
	&.disabled, &[disabled]
	{
		background: lighten($colorOffWhite, 10%);
		border-color: $colorBorder !important;
		cursor: not-allowed;
		@include opacity();
		@include placeholder-text($colorBase, rgba($colorBase, .25));
	}
	&.input-validation-error,
	&.braintree-hosted-fields-invalid
	{
		border-color: $colorRed;
		color: $colorRed;
		@include placeholder-text($colorRed, $colorRed);
		&:focus 
		{
			background-color: $colorWhite;
			border-color: $colorRed;
			outline: 1px solid $colorRed;
			color: $colorBase;
			@include placeholder-text($colorBase, rgba($colorBase,.8));
			&:placeholder-shown 
			{
				@include placeholder-text($colorRed, $colorRed);
			}
		}
	}
	&--small
	{
		margin-right: 5px;
		width: 30.3%;
		max-width: 150px;
	}
	&--medium
	{
		margin-right: 5px;
		width: 65%;
		max-width: 300px;
	}
	&--large
	{
		margin-right: 5px;
		width: 85%;
		max-width: 450px;
	}
	&--no-border
	{
		border-color: $colorWhite;
		&:hover 
		{
			border-color: $colorWhite;
		}
		&:focus 
		{
			border-color: $colorWhite;
		}
	}
}

.form-upload
{
	border: 1px solid $colorDivider;
	@include box-sizing();
	display: inline-block;
	margin: 0;
	padding: 10px;
	vertical-align: middle;
	width: 100%;
	max-width: 450px;
}

.form-text
{
	-webkit-appearance: none;
}

.form-textarea 
{
	height: 150px;
	padding: 8px 10px;
	resize: vertical;
  vertical-align: top;
	-webkit-appearance: none;
}

.form-select
{
	padding: 5px;
}

.form-input-group__item {
	padding: 0 0 15px 0;
}

@include mq-min($bpDesktop) {
	.form-input-group {
		display: flex;
		gap: 20px;
		max-width: 450px;
	}

	.form-input-group__item {
		padding: 0;
	}
}

// checks/radios
.form-checks
{
	font-size: 0;
	line-height: 0;
	list-style: none;
	margin: 0;
	padding: 0 0 15px 0;
	text-align: left;
	max-width: 450px;
	&__item
	{
		@include box-sizing();
		@include font-size($fontSize);
		@include line-height($fontHeight);
		list-style: none;
		margin: 0;
		padding: 10px 0 0 0;
		label
		{
			cursor: pointer;
			display: inline-block;
			@include min-height(15px);
			margin: 0;
			padding: 0 0 0 20px;
			position: relative;
			z-index: 1;
			input
			{
				left: 0;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 2px;
				z-index: 1;
				@include mq-min($bpTabletLarge)
				{
					top: 3px;
				}
				&.focus-visible {
					@include focus-style-alt();
				}
			}
		}
		&:first-child
		{
			padding-top: 0;
		}
		&--spaced
		{
			padding-top: 15px;
		}
		&--tight
		{
			padding-top: 2px;
		}
	}
	&--inline
	{
		margin-top: -5px;
		&__item
		{
			display: inline-block;
			padding-top: 5px;
			vertical-align: top;
			width: 50%;
			&:first-child
			{
				padding-top: 5px;
			}
			&--full
			{
				width: 100%;
			}
		}
		@include mq-min($bpTabletLarge)
		{
			padding-top: 0;
		}
	}
	&--tight
	{
		padding-bottom: 0;
	}
}

.m-filter .form-checks__item label input {
	top: 7px;
} 

// buttons
.form-button
{
	background: $colorLink no-repeat 50% 50%;
	border: 1px solid darken($colorLink, 10%);
	@include border-radius(0);
	@include box-sizing();
	color: $colorWhite;
	cursor: pointer;
	display: inline-block;
	font-family: $fontBase;
	@include font-size($fontSize);
	font-weight: normal;
	@include line-height($fontSize);
	margin: 0 10px 0 0;
	padding: 10px 15px;
	position: relative;
  text-align: center;
	text-decoration: none !important;
	@include user-select();
	vertical-align: middle;
  white-space: nowrap;
	min-width: 150px;
	z-index: 2;
	&:hover 
	{
		background-color: darken($colorLink, 10%);
		border-color: darken($colorLink, 10%);
		color: $colorWhite;
		text-decoration: none;
	}
	&:focus 
	{
		outline: 1px dotted darken($colorLink, 10%);
	}
	&:last-child
	{
		margin-right: 0;
	}
	&.disabled, &[disabled] 
	{
		background: $colorDivider !important;
		border-color: $colorDivider !important;
		color: $colorGrey !important;
		cursor: not-allowed !important;
		@include opacity(.8);
	}
}

.form-button {
	&--tight {
		margin-left: 0;
	}

	&--inline {
		margin-left: 0;
		padding: 11px 20px;
		min-width: 10px;
	}

	&--large {
		@include mq-min($bpTabletLarge) {
			@include font-size($fontSize + 2);
			@include line-height($fontSize + 2);
		}
	}

	&--block {
		display: block;
		margin: 0;
		width: 100%;
	}
}

// info
.form-info
{
	clear: both;
	color: $colorGrey;
	display: block;
	@include font-size($fontSize - 1);
	@include line-height($fontHeight - 1);
	margin: 0;
	padding: 15px 0 0 0;
	text-align: left;
	max-width: 450px;
	a
	{
		@include font-size($fontSize - 1);
		@include line-height($fontHeight - 1);
	}
}

.form-message,
.form-error,
.form-warning,
.form-success {
	background: $colorOffWhite;
	@include box-sizing();
	clear: both;
	color: $colorBase;
	display: block;
	@include font-size($fontSize);
	@include line-height($fontHeight);
	margin: 0 0 15px 0;
	padding: 10px 15px;
	position: relative;
	text-align: left;
	z-index: 1;

	a {
		color: $colorBase;
		text-decoration: underline;

		&:hover {
			color: $colorBase;
			text-decoration: none;
		}
	}

	&--tight {
		margin-bottom: 0;
	}

	&[aria-hidden="true"] {
		display: none;
	}
}

.form-error {
	background: $colorRed;
	color: $colorWhite;

	a {
		color: $colorWhite;
		text-decoration: underline;

		&:hover {
			color: $colorWhite;
			text-decoration: none;
		}
	}

	.form-row &, .form-checks & {
		background: none;
		color: $colorRed;
		clear: both;
		display: none;
		@include font-size($fontSize - 1);
		@include line-height($fontHeight - 1);
		margin: 0;
		padding: 5px 0 0 0;
		max-width: 450px;

		a {
			color: $colorRed;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			text-decoration: underline;

			&:hover {
				color: $colorRed;
				text-decoration: underline;
			}
		}

		&.field-validation-error {
			display: block;
			font-weight: bold;
		}

		&[aria-hidden="true"] {
			display: none;
		}
	}
}

.form-warning
{
	background: $colorYellow;
	color: $colorWhite;
	a 
	{
		color: $colorWhite;
		text-decoration: underline;
		&:hover
		{
			color: $colorWhite;
			text-decoration: none;
		}
	}
}

.form-success
{
	background: $colorBlue;
	color: $colorWhite;
	a 
	{
		color: $colorWhite;
		text-decoration: underline;
		&:hover
		{
			color: $colorWhite;
			text-decoration: none;
		}
	}
}

.form-text-prefix__group {
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	.form-text-prefix {
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		height: 38px;
		padding: 5px 10px;
		border: 1px solid $colorDivider;
		border-right: none;
		color: $colorGreyMid;
		font-weight: 600;
		@include font-size(13px);
		max-width: 120px;
	}

	.form-text {
		flex: 1;
	}
}

