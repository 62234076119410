﻿@import "../config";

.m-category-pager
{
	font-size: 0;
	line-height: 0;
	margin: 0;
	padding: 0 0 15px 0;
	&__group
	{
		@include box-sizing();
		float: left;
		@include font-size($fontSize);
		@include line-height($fontHeight);
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;
		&--primary
		{
			padding-right: 5px;
			width: 55%;
			max-width: 250px;
			@include mq-min($bpTablet)
			{
				width: 50%;
			}
			@include mq-min($bpDesktop)
			{
				width: 35%;
			}
			@include mq-min($bpLarge)
			{
				width: 30%;
			}
		}
		&--secondary
		{
			float: right;
			padding-left: 5px;
			text-align: right;
			width: 45%;
			max-width: 750px;
			@include mq-min($bpTablet)
			{
				width: 50%;
			}
			@include mq-min($bpDesktop)
			{
				width: 65%;
			}
			@include mq-min($bpLarge)
			{
				width: 70%;
			}
		}
	}
	&__top
	{
		@include font-size($fontSize - 1);
		@include line-height($fontHeight - 1);
		margin: 0;
		padding: 0;
		&__link
		{
			color: $colorPrimary;
			display: flex;
			align-items: center;
			font-weight: 600;
			margin: 0;
			padding: 0;
			position: relative;
			text-decoration: none;
			z-index: 1;
			&__icon {
				margin-right: 5px;
			}
			&:hover
			{
				color: $colorGrey;
				text-decoration: none;
				&:before
				{
					border-bottom-color: $colorPrimary;
				}
			}
		}
		@include mq-min($bpTabletLarge)
		{
			bottom: 100%;
			left: 0;
			padding-bottom: 30px;
			position: absolute;
			z-index: 1;
		}
		@include mq-min($bpDesktop)
		{
			padding-bottom: 35px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-bottom: 40px;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding-bottom: 20px;
	}
	@include mq-min($bpDesktop)
	{
		padding-bottom: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding-bottom: 30px;
	}
	&--divider-top
	{
		padding-top: 30px;
		&__inner
		{
			border-top: 1px solid $colorDivider;
			padding-top: 15px;
			@include mq-min($bpTabletLarge)
			{
				padding-top: 20px;
			}
			@include mq-min($bpDesktop)
			{
				padding-top: 25px;
			}
			@include mq-min($bpDesktopLarge)
			{
				padding-top: 30px;
			}
		}
		@include mq-min($bpTabletLarge)
		{
			padding-top: 40px;
		}
		@include mq-min($bpDesktop)
		{
			padding-top: 50px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-top: 55px;
		}
	}
	&--tight
	{
		padding-bottom: 0;
	}
}