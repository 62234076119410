﻿@import "../config";

.m-toggle-nav
{
	margin: 0;
	padding: 0 0 15px 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__title
	{
		font-family: $fontBase;
		@include font-size($fontSize);
		font-weight: 600;
		@include line-height($fontHeight);
		margin: 0;
		padding: 0 0 10px 0;
		position: relative;
		z-index: 1;
		&:before
		{
			border: 5px solid transparent;
			border-bottom-color: $colorBase;
			border-top-width: 0;
			content: "";
			display: none;
			height: 0;
			margin: -3px 0 0 0;
			padding: 0;
			position: absolute;
			right: 10px;
			top: 50%;
			width: 0;
			z-index: 1;
			@include mq-min($bpTabletLarge)
			{
				display: block;
			}
		}
		&.closed
		{
			&:before
			{
				border-bottom-color: transparent;
				border-bottom-width: 0;
				border-top-color: $colorBase;
				border-top-width: 5px;
			}
		}
		@include mq-min($bpTabletLarge)
		{
			border-bottom: 1px solid $colorDivider;
			cursor: pointer;
			@include font-size($fontSize + 2);
			@include line-height($fontHeight + 2);
			margin-bottom: 5px;
			padding-right: 20px;
		}
	}
	&__content
	{
		margin: 0;
		padding: 0;
		@include mq-max($bpTabletLarge - 1)
		{
			display: block !important;
		}
	}
	&__menu
	{
		margin: 0;
		padding: 0;
		&__item
		{
			margin: 0;
			padding: 0;
		}
		&__link
		{
			color: $colorBase;
			display: block;
			@include font-size($fontSize - 1);
			font-weight: 400;
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 5px 10px;
			text-decoration: none;
			&:hover
			{
				color: $colorGrey;
				text-decoration: none;
			}
			@include mq-min($bpTabletLarge)
			{
				font-weight: 600;
				padding: 5px 0;
			}
			@include mq-min($bpDesktop)
			{
				padding: 5px 10px;
			}
		}
		&__count
		{
			color: $colorGrey;
			@include font-size($fontSize - 1);
			font-weight: 400;
			@include line-height($fontHeight - 1);
		}
	}
	&__sub
	{
		margin: 0;
		padding: 0;
		&__menu
		{
			margin: 0;
			padding: 0;
			&__item
			{
				margin: 0;
				padding: 0;
			}
			&__link
			{
				color: $colorGrey;
				display: block;
				@include font-size($fontSize - 2);
				font-weight: 400;
				@include line-height($fontHeight - 2);
				margin: 0;
				padding: 3px 20px;
				text-decoration: none;
				&:hover
				{
					color: $colorBase;
					text-decoration: none;
				}
				@include mq-min($bpTabletLarge)
				{
					padding: 3px 10px;
				}
				@include mq-min($bpDesktop)
				{
					padding: 3px 20px;
				}
			}
			&__count
			{
				color: $colorGrey;
				@include font-size($fontSize - 2);
				font-weight: 400;
				@include line-height($fontHeight - 2);
			}
		}
		&.active
		{
			display: block;
		}
		.js &
		{
			display: none;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding-bottom: 20px;
	}
	&--tight
	{
		padding-bottom: 0;
	}
}