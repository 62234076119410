﻿@import "../config";

.m-filter
{
	background: lighten($colorDivider, 10%);
	border-top: 1px solid $colorWhite;
	margin: 0;
	padding: 10px;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__title
	{
		display: none;
		font-family: $fontBase;
		@include font-size(15px);
		line-height: 1.5;
		font-weight: 600;
		margin: 0;
		padding: 0 0 10px 0;
		@include mq-min($bpTabletLarge)
		{
			display: block;
		}
		&--price {
			padding: 0 0 20px 0;
		}
	}
	&__title-name {
		text-transform: lowercase;
	}
	&__content
	{
		margin: 0;
		padding: 0;
		.form-text, .form-select
		{
			max-width: 100%;
		}
		.form-checks
		{
			&__item
			{
				@include font-size(15px);
				@include line-height(28px);
				small
				{
					@include font-size(14px);
					@include line-height(27px);
				}
			}
		}
		&__default
		{
			@include mq-max($bpTabletLarge - 1)
			{
				display: none;
			}
		}
		&__mobile
		{
			@include mq-min($bpTabletLarge)
			{
				display: none;
			}
		}
		@include mq-min($bpTabletLarge)
		{
			max-height: 150px;
			overflow-y: auto;
		}
		@include mq-min($bpDesktop)
		{
			max-height: 175px;
		}
	}
	&__range
	{
		font-size: 0;
		line-height: 0;
		margin: -10px 0 0 -10px;
		padding: 0;
		.form-row
		{
			@include box-sizing();
			display: inline-flex;
			align-items: center;
			margin: 0;
			padding: 10px 0 0 10px;
			max-width: 49%;
			vertical-align: top;
			label
			{
				color: $colorBase;
				font-size: $fontSize - 1;
				line-height: $fontHeight - 1;
				display: inline-block;
				margin: 0;
				padding: 0 10px 0 0;
				vertical-align: middle;
			}
			.form-text
			{
				max-width: 70px;
				@include mq-min($bpDesktop)
				{
					max-width: 60px;
				}
				@include mq-min($bpDesktopLarge)
				{
					max-width: 75px;
				}
			}
			&.to
			{
				label
				{
					text-transform: lowercase;
				}
			}
		}
		&__slider
		{
			display: none;
			margin: 0 11px;
			padding: 10px 0 15px 10px;
			.ui-slider
			{
				background: #BCBCBC;
				border: 0;
				border-radius: 3px;
				height: 6px;
				margin: 0;
				padding: 0 11px;
				position: relative;
				max-width: 300px;
				z-index: 1;
				&-handle
				{
					background: $colorPrimary;
					border: 0;
					@include border-radius(100%);
					cursor: pointer;
					display: block;
					height: 22px;
					margin: 0 0 0 -11px;
					padding: 0;
					position: absolute;
					top: -8px;
					width: 22px;
					z-index: 2;
					&:hover
					{
						background: lighten($colorPrimary, 10%);
					}
				}
				&-range
				{
					background: $colorPrimary;
					border: 0;
					@include border-radius(0);
					display: block;
					height: 6px;
					margin: 0;
					padding: 0;
					position: absolute;
					top: 0;
					z-index: 1;
				}
			}
			@include mq-min($bpTabletLarge)
			{
				display: block;
			}
		}
	}
	@include mq-min($bpTabletLarge)
	{
		background: none;
		border: 0;
		padding: 0 0 15px 0;
	}
	@include mq-min($bpDesktop)
	{
		padding-bottom: 20px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding-bottom: 25px;
	}
	@include mq-min($bpLarge)
	{
		padding-bottom: 30px;
	}
	&:first-child
	{
		@include mq-max($bpTabletLarge - 1)
		{
			border-top: 0;
		}	
	}
	&--divider
	{
		@include mq-min($bpTabletLarge)
		{
			border-bottom: 1px solid $colorDivider;
			margin-bottom: 15px;
		}
		@include mq-min($bpDesktop)
		{
			margin-bottom: 20px;
		}
		@include mq-min($bpDesktopLarge)
		{
			margin-bottom: 25px;
		}
		@include mq-min($bpLarge)
		{
			margin-bottom: 30px;
		}
	}
	&--tight
	{
		@include mq-min($bpTabletLarge)
		{
			padding-bottom: 0;
		}		
	}
}