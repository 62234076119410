@import "config";

// cms
.cms
{
	max-width: $maxContentWidth;
	h1, h2, h3, h4, h5, h6
	{
		font-weight: 600;
		padding-bottom: 10px;
	}
	h2
	{
		@include font-size($fontSize + 6);
		@include line-height($fontHeight + 6);
	}
	h3
	{
		@include font-size($fontSize + 4);
		@include line-height($fontHeight + 4);
	}
	h4
	{
		@include font-size($fontSize + 3);
		@include line-height($fontHeight + 3);
	}
	h5
	{
		@include font-size($fontSize + 2);
		@include line-height($fontHeight + 2);
		padding-bottom: 5px;
	}
	h6
	{
		@include font-size($fontSize);
		@include line-height($fontHeight);
		padding-bottom: 5px;
	}
	a
	{
		color: $colorLink;
		font-weight: 600;
		text-decoration: none;
		&:hover
		{
			color: $colorLinkHover;
			text-decoration: none;
		}
	}
	ul, ol
	{
		margin: 0;
		padding: 0 0 10px 25px;
		> li 
		{
			list-style: none;
			margin: 0;
			padding: 0 0 5px 0;
			position: relative;
			z-index: 1;
		}
		ul, ol 
		{
			margin-bottom: -5px;
			padding-bottom: 0;
			padding-top: 5px;
		}
	}
	ul
	{
		> li
		{
			&:before
			{
				background: $colorBase;
				@include border-radius(100%);
				content: "";
				display: block;
				height: 4px;
				left: -12px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 8px;
				width: 4px;
				z-index: 1;
			}
		}
	}
	ol 
	{
		counter-reset: cms-ol;
		> li
		{
			&:before
			{
				content: counter(cms-ol)".";
				counter-increment: cms-ol;
				display: block;
				@include font-size($fontSize - 2);
				font-weight: 700;
				@include line-height($fontHeight - 2);
				left: -30px;
				margin: 0;
				overflow: hidden;
				padding: 0;
				position: absolute;
				text-align: right;
				top: 2px;
				width: 25px;
				z-index: 1;
			}
			> ol
			{
				counter-reset: cms-ol;
				> li
				{
					&:before
					{
						content: counter(cms-ol, lower-alpha)".";
						counter-increment: cms-ol;
					}
					> ol
					{
						counter-reset: cms-ol;
						> li
						{
							&:before
							{
								content: counter(cms-ol, lower-roman)".";
								counter-increment: cms-ol;
							}
						}
					}
				}
			}
		}		
	}
	blockquote
	{
		border-left: 4px solid $colorSecondary;
		color: $colorSecondary;
		@include font-size($fontSize + 2);
		font-weight: 600;
		@include line-height($fontHeight + 2);
		margin: 0 0 20px 0;
		padding: 5px 0 5px 15px;
		text-align: left;
		p
		{
			padding-bottom: 0;
		}
		a
		{
			color: $colorSecondary;
			text-decoration: underline;
			&:hover
			{
				color: $colorSecondary;
				text-decoration: none;
			}
		}
		@include mq-min($bpDesktop)
		{
			@include font-size($fontSize + 3);
			@include line-height($fontHeight + 3);
		}
		@include mq-min($bpDesktopLarge)
		{
			@include font-size($fontSize + 4);
			@include line-height($fontHeight + 4);
		}
		@include mq-min($bpLarge)
		{
			@include font-size($fontSize + 5);
			@include line-height($fontHeight + 5);
		}
	}
	picture, img
	{
		margin: 0 auto 15px auto;
		padding: 0;
	}
	figure, p
	{
		img
		{
			margin-bottom: 0;
		}
	}
	iframe
	{
		display: block;
		margin: 0 0 15px 0;
		padding: 0;
	}
	table
	{
		tbody
		{
			tr
			{
				&:nth-child(even)
				{
					th, td
					{
						background: lighten($colorOffWhite, 8%);
					}
				}
			}		
		}
	}
	&-table
	{
		margin: 0 0 15px 0;
		overflow-x: auto;
		padding: 0 0 10px 0;
		-webkit-overflow-scrolling: touch;
		table
		{
			@include mq-max($bpTablet - 1)
			{
				margin: 0;
				min-width: $bpMobileLarge;
			}
		}
		@include mq-min($bpDesktop)
		{
			margin: 0;
			overflow-x: visible;
			padding: 0;
		}
	}
	&-video
	{
		height: 0;
		margin: 0 auto 15px auto;
		overflow: hidden;
		padding: 35px 0 56.25% 0;
		position: relative;
		max-width: 800px;
		z-index: 1;
		iframe
		{
			display: block;
			height: 100%;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 0;
			width: 100%;
			max-width: 100%;
			z-index: 1;
		}
	}
	.larger
	{
		font-size: $fontSize + 4;
		line-height: $fontHeight + 4;
	}
	.button:not(a)
	{
		display: block;
		padding-top: 5px;	
	}
	.button a, a .button
	{
		background: $colorPrimary;
		border: 1px solid $colorPrimary;
		color: $colorWhite;
		display: inline-block;
		font-weight: 600;
		margin: 0;
		padding: 10px;
		padding: 10px 15px;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		min-width: 100px;
		&:hover, &:focus
		{
			background: $colorBase;
			border-color: $colorBase;
			color: $colorWhite;
		}
	}
	&--no-restrict
	{
		max-width: 100%;
	}
}