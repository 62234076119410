﻿.c-alert-banner {
    background-color: $colorOrange;
    color: $colorBase;
    padding: 14px 10px;
    @include font-size(15px);
    font-weight: 600;
    text-align: center;
}

.c-alert-banner__content {
    max-width: $maxPageWidth;
    margin: 0 auto;

    a {
        color: $colorBase;
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    p:first-of-type {
        display: inline-block;
    }

    p:last-child {
        padding-bottom: 0;
    }
}

.c-alert-banner__icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;

    @include mq-min($bpDesktop) {
        margin-right: 10px;
    }
}