@import "../config";

.m-rating {
	margin: 0;
	padding: 0;

	&__stars {
		@include bg("/images/prima/common/sprite-v2.png", no-repeat, -150px -33px);
		display: block;
		float: left;
		height: 20px;
		margin: 0 10px 0 0;
		padding: 0;
		@include text-hidden();
		position: relative;
		width: 113px;
		z-index: 1;

		&__inner {
			@include bg("/images/prima/common/sprite-v2.png", no-repeat, -35px -33px);
			bottom: 0;
			display: block;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 0;
			width: 0%;
			z-index: 1;
		}
	}

	&__aside {
		color: $colorGreyMid;
		float: left;
		@include font-size($fontSize - 1);
		@include line-height($fontHeight - 1);
		margin: 0;
		padding: 1px 0;

		&__link {
			font-weight: 600;
		}
	}
}
