@import "../config";

.m-blog-post {
	margin: 0;
	padding: 0;

	&__meta {
		color: $colorGreyMid;
		font-size: 0;
		line-height: 0;
		margin: -5px 0 0 0;
		padding: 0 0 15px 0;

		&__item {
			@include box-sizing();
			display: inline-block;
			@include font-size($fontSize - 3);
			@include line-height($fontHeight - 5);
			margin: 0;
			padding: 0 13px 0 0;
			position: relative;
			text-transform: uppercase;
			z-index: 1;

			&:before {
				background: $colorDivider;
				content: "";
				display: block;
				height: 10px;
				margin: 0;
				padding: 0;
				position: absolute;
				right: 6px;
				top: 3px;
				width: 1px;
				z-index: 1;

				@include mq-min($bpTabletLarge) {
					right: 10px;
				}
			}

			&:last-child {
				padding-right: 0;

				&:before {
					display: none;
				}
			}

			@include mq-min($bpTabletLarge) {
				padding-right: 20px;
			}
		}

		@include mq-min($bpTabletLarge) {
			margin-top: -10px;
			padding-bottom: 20px;
		}

		@include mq-min($bpDesktop) {
			margin-top: -15px;
			padding-bottom: 25px;
		}

		@include mq-min($bpDesktopLarge) {
			margin-top: -20px;
			padding-bottom: 30px;
		}
	}

	&__image {
		margin: 0;
		padding: 0 0 15px 0;

		@include mq-min($bpTabletLarge) {
			padding-bottom: 20px;
		}

		@include mq-min($bpDesktop) {
			padding-bottom: 25px;
		}

		@include mq-min($bpDesktopLarge) {
			padding-bottom: 30px;
		}
	}

	&__share {
		margin: 0;
		padding: 15px 0 0 0;
	}

	&__tags {
		color: $colorGrey;
		@include min-height(16px);
		margin: 0;
		padding: 15px 0 0 22px;
		position: relative;
		max-width: $maxContentWidth;
		z-index: 1;

		&:before {
			@include bg('/images/prima/common/sprite-v2.png', no-repeat, -250px -108px);
			content: "";
			display: block;
			height: 15px;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 16px;
			width: 16px;
			z-index: 1;
		}

		&__menu {
			font-size: 0;
			line-height: 0;
			margin: -5px 0 0 -5px;
			padding: 0;

			&__item {
				@include box-sizing();
				display: inline-block;
				margin: 0;
				padding: 5px 0 0 5px;

				&:after {
					content: ",";
					@include font-size($fontSize - 1);
					@include line-height($fontHeight - 1);
				}

				&:last-child {
					&:after {
						display: none;
					}
				}
			}

			&__link {
				color: $colorGrey;
				@include font-size($fontSize - 2);
				@include line-height($fontHeight - 2);
				text-decoration: none;
				text-transform: uppercase;

				&:hover {
					color: $colorBase;
					text-decoration: none;
				}
			}
		}
	}

	&__comments {
		margin: 0;
		padding: 15px 0 0 0;
		max-width: $maxContentWidth;
	}
}
