﻿@import "../config";

.m-product-compatibility
{
	margin: 0;
	padding: 0 0 15px;
	&__inner
	{
		margin: 0;
		overflow-y: auto;
		padding: 0 5px 0 0;
		max-height: 500px;
	}
	&__group
	{
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0 8px 5px 8px;
		&__year
		{
			background: $colorOffWhite;
			color: $colorBase;
			@include font-size($fontSize);
			font-weight: 600;
			@include line-height($fontHeight);
			margin: 0 -8px 15px -8px;
			padding: 5px 8px;
		}
		&__make
		{
			@include font-size($fontSize);
			@include line-height($fontHeight);
			margin: 0;
			padding: 0 8px;
			&__title
			{
				font-weight: 600;
				margin: 0;
				padding: 0 0 3px 0;
			}
		}
		&__models
		{
			font-size: 0;
			line-height: 0;
			margin: -3px 0 0 -3px;
			padding: 0 0 10px 0;
			&__item
			{
				@include box-sizing();
				display: inline-block;
				@include font-size($fontSize - 2);
				@include line-height($fontHeight - 2);
				margin: 0;
				padding: 3px 0 0 13px;
				position: relative;
				vertical-align: top;
				width: 100%;
				z-index: 1;
				&:before
				{
					background: $colorGrey;
					content: "";
					display: block;
					height: 1px;
					left: 3px;
					margin: 0;
					padding: 0;
					position: absolute;
					top: 11px;
					width: 5px;
					z-index: 1;
				}
				@include mq-min($bpMobileLarge)
				{
					width: 50%;
				}
				@include mq-min($bpTablet)
				{
					width: 33.3333%;
				}
			}
		}
	}
	&--tight
	{
		padding-bottom: 0;
	}
}