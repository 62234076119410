﻿@import "../config";

.m-header
{
	position: relative;
	background-color: $colorOffWhite;
	margin: 0;
	padding: 20px 0;

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 0;
		width: 50vw;
		height: 100%;
		background-color: $colorOffWhite;
	}
	&:before {
		left: -50vw;
	}
	&:after {
		right: -50vw;
	}
	@include mq-min($bpDesktop)
	{
		margin-top: -15px;
		padding: 60px 0;
	}
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__image
	{
		display: block;
		float: left;
		margin: 0;
		padding: 0 15px 0 0;
		width: 20%;
		max-width: 240px;
		@include mq-min($bpTabletLarge)
		{
			padding-right: 20px;
		}
		@include mq-min($bpDesktop)
		{
			padding-right: 25px;
		}
		&--small
		{
			max-width: 100px;
			height: auto;
			padding-right: 20px;
			float: none;
			display: inline-block;
			vertical-align: middle;
		}
	}
	&__content
	{
		margin: 0;
		overflow: hidden;
		padding: 0;
	}
	&__title-block
	{
		width: 100%;
		padding: 0;
		margin: 0;
	}
	&__title
	{
		@include font-size($fontSize + 14);
		font-weight: 600;
		@include line-height($fontHeight + 14);
		margin: 0;
		padding: 0;
		&--inline
		{
			display: inline-block;
			vertical-align: middle;
		}
	}
	&__summary
	{
		@include font-size($fontSize - 1);
		@include line-height($fontHeight - 1);
		margin: 0;
		padding: 10px 0 0 0;
		&--alt
		{
			margin: 0;
		}
	}
/*	@include mq-min($bpTabletLarge)
	{
		margin-bottom: 20px;
	}
	@include mq-min($bpDesktop)
	{
		margin-bottom: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		margin-bottom: 30px;
	}
	&--tight
	{
		margin-bottom: 0;
	}*/
}