﻿.c-section {

	+ .c-section {
		margin-top: 30px;

		@include mq-min($bpDesktop) {
			margin-top: 35px;
		}
	}
}

.c-section__title {
	font-size: 20px;
	line-height: 27px;
	font-weight: 600;
	border-bottom: 1px solid $cSectionTitleBorderColor;
	margin: 0 0 30px 0;
	padding: 0 0 7px 0;
}