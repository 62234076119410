﻿@import "../config";

.m-pager
{
	margin: 0;
	padding: 0;
	&__menu
	{
		font-size: 0;
		line-height: 0;
		margin: -5px 0 0 -5px;
		padding: 0;
		&__item
		{
			@include box-sizing();
			color: $colorGrey;
			display: none;
			@include font-size($fontSize - 1);
			font-weight: 600;
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 5px 0 0 5px;
			vertical-align: top;
			@include mq-min($bpDesktop)
			{
				display: inline-block;
			}
			&--prev, &--next
			{
				display: inline-block;
			}
		}
		&__link
		{
			background: $colorWhite;
			border: 1px solid #BCBCBC;
			color: $colorPrimary;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0;
			height: 44px;
			width: 42px;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			.u-visuallyhidden {
				color: $colorBase;
			}
			&:hover, &.active
			{
				background: $colorPrimary;
				border-color: $colorPrimary; 
				color: $colorWhite;
			}
			&.disabled
			{
				border-color: #BCBCBC !important;
				background: #BCBCBC !important;
				color: #7C7C7C !important;
				cursor: not-allowed;
			}
		}
		&__range
		{
			background: $colorWhite;
			border: 1px solid $colorWhite;
			display: block;
			margin: 0;
			padding: 8px;
			text-align: center;
		}
	}
}