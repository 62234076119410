﻿@import "../config";

.m-news-block
{
	background: $colorWhite;
	@include box-shadow();
	margin: 0 auto;
	padding: 300px 0 0 0;
	position: relative;
	text-align: left;
	max-width: 800px;
	z-index: 1;
	&__image
	{
		background: $colorBase no-repeat 50% 50%;
		@include background-size(cover);
		display: block;
		height: 300px;
		left: 0;
		margin: 0;
		overflow: hidden;
		padding: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
		&:before
		{
			background: rgba($colorWhite, .15);
			bottom: 0;
			content: "";
			display: none;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 2;
		}
		@include mq-min($bpTablet)
		{
			height: 100%;
			width: 40%;
		}
		@include mq-min($bpDesktop)
		{
			width: 220px;
		}
	}
	&__content
	{
		@include box-sizing();
		margin: 0;
		overflow: hidden;
		padding: 15px;
		@include mq-min($bpTablet)
		{
			@include min-height(145px);
			padding: 30px;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}
	&__title
	{
		color: $colorPrimary;
		@include font-size($fontSize + 6);
		font-weight: 400;
		@include line-height($fontHeight + 4);
		margin: 0;
		padding: 0;
		@include mq-min($bpTabletLarge)
		{
			@include font-size($fontSize + 4);
			@include line-height($fontHeight + 2);
		}
		@include mq-min($bpDesktop)
		{
			@include font-size($fontSize + 6);
			@include line-height($fontHeight + 4);
		}
	}
	&__summary
	{
		display: none;
		margin: 0;
		padding: 5px 0 0 0;
	}
	&__date
	{
		color: $colorGreyMid;
		@include font-size($fontSize - 3);
		font-weight: 600;
		@include line-height($fontHeight - 3);
		margin: 0;
		padding: 10px 0 0 0;
		text-transform: uppercase;
	}
	&__link
	{
		bottom: 0;
		display: block;
		left: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		@include text-hidden();
		top: 0;
		z-index: 1;
	}
	&:hover
	{
		.m-news-block__image
		{
			&:before
			{
				display: block;
			}
		}
		.m-news-block__title
		{
			color: $colorBase;
		}
	}
	@include mq-min($bpTablet)
	{
		padding: 0 0 0 40%;
	}
	@include mq-min($bpDesktop)
	{
		padding-left: 220px;
	}
	&--large
	{
		&__image
		{
			@include mq-min($bpTablet)
			{
				height: 300px;
				width: 100%;
				max-width: 100%;
			}
		}
		&__content
		{
			@include mq-min($bpTabletLarge)
			{
				@include min-height(162px);
			}
		}
		&__title
		{
			@include font-size($fontSize + 6);
			@include line-height($fontHeight + 4);
		}
		&__summary
		{
			display: block;
			overflow: hidden;
			max-height: 80px;
		}
		@include mq-min($bpTablet)
		{
			padding: 300px 0 0 0;
		}
	}
}