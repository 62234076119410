﻿@import "../config";

.m-warranty-summary
{
	margin: 0;
	padding: 0 0 15px 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__details
	{
		background: $colorOffWhite;
		margin: 0;
		padding: 15px;
		&__inner
		{
			font-size: 0;
			line-height: 0;
			margin: -10px 0 0 -10px;
			padding: 0;
		}
		&__group
		{
			@include box-sizing();
			display: inline-block;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 10px 0 0 10px;
			vertical-align: top;
			width: 50%;
			&__title
			{
				@include font-size($fontSize - 1);
				font-weight: 600;
				@include line-height($fontHeight - 1);
				margin: 0;
				padding: 0;
			}
			&__value
			{
				margin: 0;
				padding: 0;
			}
			&__list
			{
				counter-reset: warranty;
				font-size: 0;
				line-height: 0;
				margin: 0 0 0 -15px;
				padding: 0;
				&__item
				{
					@include box-sizing();
					display: inline-block;
					@include font-size($fontSize - 1);
					@include line-height($fontHeight - 1);
					margin: 0;
					padding: 0 0 0 35px;
					position: relative;
					z-index: 1;
					&:before
					{
						content: counter(warranty)":";
						counter-increment: warranty;
						display: block;
						left: 15px;
						margin: 0;
						overflow: hidden;
						padding: 0;
						position: absolute;
						top: 0;
						width: 20px;
						z-index: 1;
					}
				}
			}
			@include mq-min($bpTablet)
			{
				width: 33.3333%;
			}
			&--full
			{
				width: 100%;
			}
		}
		&__images
		{
			border-top: 1px solid $colorWhite;
			border-top-color: rgba($colorWhite, .5);
			margin: 15px 0 0 0;
			padding: 15px 0 0 0;
			&__menu
			{
				font-size: 0;
				line-height: 0;
				margin: -5px 0 0 -5px;
				padding: 0;
				&__item
				{
					@include box-sizing();
					display: inline-block;
					margin: 0;
					padding: 5px 0 0 5px;
					width: 20%;
					max-width: 100px;
				}
				&__link
				{
					display: block;
					margin: 0;
					padding: 0;
				}
				&__image
				{
					display: block;
					margin: 0;
					padding: 0;
				}
			}
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding-bottom: 20px;
	}
	@include mq-min($bpDesktop)
	{
		padding-bottom: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding-bottom: 30px;
	}
	&--tight
	{
		padding-bottom: 0;
	}
}