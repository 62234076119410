﻿@import "../config";

.m-search-block
{
	@include box-sizing();
	color: $colorWhite;
	margin: 0 auto;
	padding: 0;
	@include text-shadow(1px 1px rgba($colorBlack, .75));
	max-width: $maxPageWidth - 200;
	&__inner
	{
		margin: 0;
		padding: 0;
		text-align: left;
		@include mq-min($bpTablet)
		{
			margin-left: -15px;
		}
		@include mq-min($bpTabletLarge)
		{
			margin-left: -25px;
		}
		@include mq-min($bpDesktop)
		{
			margin-left: -35px;
		}
		@include mq-min($bpDesktopLarge)
		{
			margin-left: -45px;
		}
		@include mq-min($bpLarge)
		{
			margin-left: -55px;
		}
	}
	&__title
	{
		color: $colorWhite;
		@include font-size($fontSize + 8);
		font-weight: 600;
		@include line-height($fontHeight + 8);
		margin: 0;
		padding: 0 0 20px 0;
		text-align: center;
		@include mq-min($bpDesktop)
		{
			@include font-size($fontSize + 12);
			@include line-height($fontHeight + 12);
		}
	}
	&__group
	{
		margin: 0;
		overflow: hidden;
		padding: 0 0 15px 0;
		&__title
		{
			@include font-size($fontSize + 4);
			@include line-height($fontHeight + 4);
			font-weight: 600;
			margin: 0;
			padding: 0 0 15px 0;
			@include mq-min($bpDesktop)
			{
				@include font-size($fontSize + 6);
				@include line-height($fontHeight + 6);
				padding-bottom: 20px;
			}
		}
		@include mq-min($bpTablet)
		{
			padding: 0 0 0 15px;
		}
		@include mq-min($bpTabletLarge)
		{
			padding-left: 25px;
		}
		@include mq-min($bpDesktop)
		{
			padding-left: 35px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-left: 45px;
		}
		@include mq-min($bpLarge)
		{
			padding-left: 55px;
		}
		&--search
		{
			@include mq-min($bpTabletLarge)
			{
				float: left;
				width: 50%;
				max-width: 535px;
			}			
		}
	}
	&__search
	{
		.form-row
		{
			margin: 0;
			padding: 0;
			.form-text
			{
				width: 80%;
				width: calc(100% - 50px);
				max-width: 100%;
				@include mq-min($bpDesktop)
				{
					height: 48px;
				}
			}
			.form-button
			{
				width: 20%;
				max-width: 50px;
				@include mq-min($bpDesktop)
				{
					height: 48px;
				}
			}
		}
	}
	&__adv-search
	{
		@include font-size($fontSize + 2);
		font-weight: 600;
		@include line-height($fontHeight + 2);
		margin: 0;
		padding: 10px 0 0 0;
		&__link
		{
			color: $colorWhite;
			text-decoration: underline;
			&:hover
			{
				color: $colorWhite;
				text-decoration: none;
			}
		}
		@include mq-min($bpTabletLarge)
		{
			padding-top: 15px;
		}
	}
	&__results
	{
		font-size: 0;
		line-height: 0;
		margin: -5px 0 0 -10px;
		padding: 0;
		&__item
		{
			@include box-sizing();
			display: inline-block;
			@include font-size($fontSize);
			font-weight: 600;
			@include line-height($fontHeight);
			margin: 0;
			padding: 5px 0 0 10px;
			vertical-align: top;
			width: 50%;
			@include mq-min($bpMobileLarge)
			{
				width: 33.3333%;
			}
			@include mq-min($bpTabletLarge)
			{
				width: 50%;
			}
		}
		&__link
		{
			color: $colorWhite;
			text-decoration: underline;
			display: inline-block;
			padding: 5px 0;
			&:hover
			{
				color: $colorWhite;
				text-decoration: none;
			}
		}
	}
}