﻿@import "../config";

.m-dispatch-group
{
	&__product
  {
    border-top: 1px solid $colorDivider;
    margin: 5px 0 0;
    padding: 15px 0 0;
	}
  &__details
  {
    padding: 0 0 10px;
  }
  &__image
  {
    display: inline-block;
    padding: 0 10px 10px 0;
    vertical-align: middle;
    max-width: 85px;
    &__image
    {
      display: block;
      margin: 0;
    }
  }
  &__content
  {
    display: inline-block;
    vertical-align: middle;
  }
  &__title
  {
    font-family: $fontSimple;
    font-size: $fontSize;
    font-weight: bold;
    line-height: $fontHeight;
    padding: 0;
    margin: 0 0 15px 0;
  }
  &__title--tight
  {
    margin: 0 0 5px 0;
  }
  &__stock-in
  {
    color: $colorBlue;
    font-weight: 600;
  }
  &__checks
  {
    & + .field-validation-valid
    {
      display: none;
    }
    & + .field-validation-error
    {
      display: block;
      margin: 0 0 15px 0;
      color: $colorRed;
      font-weight: bold;
    }
  }
}