@import "../config";

.l-nav {
	padding: 0;
	z-index: 8888;

	@include mq-min($bpDesktop) {
		position: relative;
		flex: 1;
		margin: 5px -10px 0 -10px;
	}

	&__inner {
		@include box-sizing();
		margin: 0 auto;
		padding: 0;
		text-align: left;
		max-width: $maxPageWidth;
		z-index: 1;

		@include mq-min($bpDesktop) {
			position: relative;
		}
	}

	&__mobile__controls {
		display: flex;
		align-items: center;
		position: absolute;
		top: 7px;
		right: -10px;
	}

	&__mobile__controls__button {
		color: $colorBase;
		padding: 15px;
	}

	&__title {
		border-right: 1px solid $colorWhite;
		height: 50px;
		margin: 0;
		padding: 0;
		width: 50px;

		&__link {
			display: block;
			height: 50px;
			margin: 0;
			padding: 0;
			position: relative;
			@include text-hidden();
			z-index: 1;

			&:before {
				@include bg('/images/prima/common/sprite.png', no-repeat, -128px -4px);
				content: "";
				display: block;
				height: 15px;
				left: 50%;
				margin: -7px 0 0 -12px;
				padding: 0;
				position: absolute;
				top: 50%;
				width: 24px;
				z-index: 1;
			}

			&:hover {
				background: lighten($colorDivider, 5%);
			}
		}

		@include mq-min($bpDesktop) {
			display: none !important;
		}
	}

	&__content {
		@include box-sizing();
		margin: 0;
		padding: 0;

		@include mq-max($bpDesktop) {
			position: relative;
			background-color: $colorBlue;
			color: $colorWhite;
			margin: 20px -15px 0 -15px;
		}

		.js & {
			display: none;
		}

		@include mq-min($bpDesktop) {
			display: block !important;
		}
	}

	&__menu {
		margin: 0;
		padding: 0;

		@include mq-max($bpDesktop) {
			padding: 0 48px;

			&--alt {
				padding-bottom: 30px;
			}
		}

		&__item {
			margin: 0;
			padding: 0;
			position: relative;

			&--find-parts {
				margin: 20px -35px 0;
			}

			&:hover,
			&.show-subnav {
				z-index: 8888;

				.l-nav__sub {
					@include mq-min($bpDesktop) {
						display: block;
					}
				}

				.l-nav__menu__link {
					&:before {
						@include mq-min($bpDesktop) {
							display: block;
						}
					}

					color: $colorWhite;

					@include mq-min($bpDesktop) {
						background: $colorBase;
						z-index: 4;

						&:after {
							display: none;
						}
					}
				}
			}

			@include mq-min($bpDesktop) {
				border: 0;
				float: left;
				padding-left: 1px;
			}

			&--divider {

				&:before {
					bottom: 15px;
					content: "";
					display: none;
					left: -5px;
					margin: 0;
					padding: 0;
					position: absolute;
					top: 15px;
					z-index: 1;
					width: 1px;
					background: $colorBase;

					@include mq-min($bpDesktopLarge) {
						display: block;
					}

					@include mq-min($bpLarge) {
						left: -10px;
					}
				}

				@include mq-min($bpDesktopLarge) {
					margin-left: 10px;
				}

				@include mq-min($bpLarge) {
					margin-left: 20px;
				}

				&--narrow {
					&:before {
						left: -1px;
					}

					@include mq-min($bpDesktopLarge) {
						margin-left: 1px;
					}
				}
			}

			&--full {
				@include mq-min($bpDesktop) {
					position: static;

					.l-nav__sub {
						top: auto;
					}
				}
			}

			&--home {
				@include mq-min($bpDesktop) {
					display: none;
				}
			}

			&--basket {
				background: $colorPrimary;
			}
		}

		&__link {
			@include box-sizing();
			color: $colorWhite;
			display: flex;
			align-items: center;
			margin: 0;
			padding: 15px 54px 15px 0;
			position: relative;
			text-decoration: none;
			z-index: 3;
			@include font-size(14px);
			font-weight: 600;
			line-height: 1;

			&--light {
				font-weight: 400;
			}


			&__icon__container {
				display: block;
				width: 40px;
			}

			&__icon {
				color: $colorWhite;
			}

			&__icon--wishlist {
				margin-left: 2px;
			}

			&__basket__total,
			&__basket__item__total {
				margin-left: 5px;
				font-weight: 600;
			}

			&:before {
				background: $colorBase;
				bottom: -1px;
				content: "";
				display: none;
				height: 1px;
				left: 0;
				margin: 0;
				padding: 0;
				position: absolute;
				right: 0;
				z-index: 3;

				.without-sub & {
					display: none !important;
				}
			}

			&:hover, &.active {
				color: $colorWhite;
				text-decoration: none;

				@include mq-min($bpDesktop) {
					position: relative;
					color: $colorPrimary;
					background-color: $colorWhite;

					&:after {
						content: '';
						position: absolute;
						left: 15px;
						right: 15px;
						bottom: 3px;
						background-color: $colorPrimary;
						height: 5px;
					}
				}
			}

			@include mq-min($bpDesktop) {
				height: 50px;
				padding: 15px 10px;
				position: relative;
				z-index: 1;
				color: $colorBase;
				font-weight: 400;

				&--alt {
					color: $colorPrimary;
					text-transform: none;

					&:hover, &.active {
						color: $colorGrey;

						@include mq-min($bpDesktop) {
							color: $colorWhite;
						}
					}
				}
			}

			@include mq-min($bpDesktopLarge) {
				@include font-size(16px);
			}

			@include mq-min($bpLarge) {
				padding: 15px;
			}


			&--basket {
				background: $colorPrimary;
				color: $colorWhite;
				padding-left: 50px;
				position: relative;
				z-index: 1;

				&:before {
					@include bg("/images/prima/common/sprite-v2.png", no-repeat, 0 -33px);
					content: "";
					display: block;
					height: 20px;
					left: 15px;
					margin: 0;
					padding: 0;
					position: absolute;
					top: 10px;
					width: 25px;
					z-index: 1;

					@include mq-min($bpDesktop) {
						left: 20px;
						top: 14px;
					}

					@include mq-min($bpLarge) {
						left: 30px;
					}
				}

				&__count {
					background: $colorWhite;
					@include border-radius(100%);
					@include box-sizing();
					color: $colorPrimary;
					display: block;
					font-family: $fontSimple;
					font-size: 9px;
					font-weight: bold;
					height: 15px;
					line-height: 9px;
					left: 10px;
					margin: 0;
					overflow: hidden;
					padding: 4px 0 0 0;
					position: absolute;
					text-align: center;
					top: 20px;
					width: 15px;
					z-index: 2;

					@include mq-min($bpDesktop) {
						left: 15px;
						top: 25px;
					}

					@include mq-min($bpLarge) {
						left: 25px;
					}
				}

				&:hover, &:focus {
					background: $colorBase;
					color: $colorWhite;
				}

				&.active {
					background: $colorPrimary;
					color: $colorWhite;
				}

				@include mq-min($bpLarge) {
					padding-left: 70px;
					padding-right: 30px;
				}
			}
		}

		&__more {
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			height: 54px;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 40px;
			z-index: 100;
			transform: rotate(90deg);
			transition: transform 300ms;
			border: none;
			background-color: transparent;
			color: $colorWhite;

			&.active {
				transform: rotate(270deg)
			}

			@include mq-min($bpDesktop) {
				display: none;
			}
		}



		@include mq-min($bpDesktop) {
			float: left;
		}

		&--alt {
			@include mq-min($bpDesktop) {
				float: right;
			}
		}
	}

	&__sub {
		@include box-sizing();
		display: none;
		font-size: 0;
		line-height: 0;
		margin: 0 -10px;
		padding: 0;
		position: relative;
		z-index: 2;

		&__inner {
			margin: 0;
			padding: 0;

			@include mq-min($bpDesktop) {
				text-align: center;
			}
		}

		&__col {
			@include box-sizing();
			display: inline-block;
			margin: 0;
			padding: 0;
			text-align: left;
			vertical-align: top;
			width: 100%;

			&__link {
				color: $colorWhite;
				display: block;
				@include font-size($fontSize);
				font-weight: 600;
				@include line-height($fontHeight);
				margin: 0;
				padding: 10px;
				text-decoration: none;

				&.active, &:hover {
					color: $colorWhite;
					text-decoration: none;

					@include mq-min($bpDesktop) {
						color: $colorWhite;
						text-decoration: underline;
					}
				}

				&.focus-visible {
					@include focus-style-alt($colorPrimary);
				}

				@include mq-min($bpDesktop) {
					color: $colorWhite;
					@include font-size($fontSize + 1);
					@include line-height($fontHeight + 1);
					text-transform: none;
				}
			}

			@include mq-min($bpDesktop) {
				padding: 0;
			}

			@include mq-min($bpDesktopLarge) {
				padding: 0 20px;
			}

			@include mq-min($bpLarge) {
				padding: 0 40px;
			}

			&--1 {
				@include mq-min($bpDesktop) {
					padding: 0;
				}
			}

			&--2 {
				@include mq-min($bpDesktop) {
					width: 50%;
				}
			}

			&--3 {
				@include mq-min($bpDesktop) {
					width: 33.3333%;
				}
			}

			&--4 {
				@include mq-min($bpDesktop) {
					width: 25%;
				}
			}
		}

		&__menu {
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 1;

			&:before {
				border-top: 1px solid $colorWhite;
				border-top-color: rgba($colorWhite, .15);
				content: "";
				display: block;
				left: 10px;
				margin: 0;
				padding: 0;
				position: absolute;
				right: 10px;
				top: 0;
				z-index: 1;
			}

			&__item {
				display: inline-block;
				margin: 0;
				padding: 5px 0 0 0;
				vertical-align: top;
				width: 50%;

				@include mq-min($bpTablet) {
					width: 33.3333%;
				}

				@include mq-min($bpDesktop) {
					width: 100%;
				}

				&--2 {
					@include mq-min($bpDesktop) {
						@include font-size($fontSize);
						@include line-height($fontHeight);
						width: 50%;
					}
				}
			}

			&__link {
				color: $colorWhite;
				display: block;
				@include font-size($fontSize - 1);
				@include line-height($fontHeight - 1);
				margin: 0;
				padding: 5px 10px;
				text-decoration: none;

				&.active, &:hover {
					color: $colorWhite;
					text-decoration: none;

					@include mq-min($bpDesktop) {
						color: $colorWhite;
					}
				}

				&.focus-visible {
					@include focus-style-alt($colorPrimary);
				}

				@include mq-min($bpDesktop) {
					color: $colorWhite;
					color: rgba($colorWhite, .8);
				}
			}

			@include mq-min($bpDesktop) {
				padding: 8px 0 15px 0;
			}

			&:first-child {
				&:before {
					display: none;
				}

				@include mq-min($bpDesktop) {
					padding-top: 0;
				}
			}
		}

		@include mq-min($bpDesktop) {
			background: $colorBase;
			border-top: 1px solid $colorWhite;
			@include box-shadow();
			left: 1px;
			margin: 0;
			padding: 15px 15px 30px 15px;
			position: absolute;
			top: 100%;
			width: 225px;
		}

		@include mq-min($bpDesktopLarge) {
			padding: 25px 15px 35px 15px;
			width: 250px;
		}

		@include mq-min($bpLarge) {
			padding: 35px 15px 40px 15px;
		}

		&--full {
			width: 100%;

			@include mq-min($bpDesktop) {
				width: calc(100% + 230px);
			}

			@include mq-min($bpLarge) {
				width: calc(100% + 290px);
			}
		}

		&--alt {
			@include mq-min($bpDesktop) {
				left: auto;
				right: 0;
			}
		}
	}

	&__search {
		@include box-sizing();
		margin: 0;
		overflow: hidden;
		padding: 0 15px;
		z-index: 1;
		margin: 0 0 20px 0;

		.form-button--search {
			background-color: transparent;
			border-left: none;
			color: $colorWhite;
		}

		.form-row {
			position: relative;
			border: 0;
			margin: 0;
			padding: 0;

			.form-text {
				float: left;
				margin: 0;
				width: 70%;
				max-width: 100%;
				width: 100%;
				background-color: $colorPrimary;
				border-color: $colorWhite;
				color: $colorWhite;
				@include placeholder-text($colorWhite,$colorWhite);
				font-family: $fontBase;
				line-height: normal;
				height: auto;
				padding: 15px 60px 15px 20px;

				&.input-validation-error {
					color: $colorWhite;
				}
			}

			.form-button {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				margin: 0;
				border-left: none;
				height: 100%;
				width: 60px;
				border: none;
			}

			@include mq-min($bpDesktop) {
				width: 100%;
			}
		}

		@include mq-min($bpDesktop) {
			height: auto;
			left: auto;
			padding: 6px;
			position: absolute;
			right: 190px;
			top: 15px;
			width: 30%;
			max-width: 340px;
		}

		@include mq-min($bpLarge) {
			width: 33.3333%;
		}
	}
}

.l-nav__mobile-separator {
	margin: 20px 30px;
	background-image: linear-gradient(to right, #ffffff 30%, rgba(255, 255, 255, 0) 0%);
	background-size: 4px 1px;
	display: block;
	height: 1px;
}

.mobile-nav-open .hide-mobile-nav-open {
	display: none;
}

.show-mobile-nav-open {
	display: none;
}

.mobile-nav-open .show-mobile-nav-open {
	display: initial;
}
