﻿@import "../config";

.m-share
{
	margin: 0;
	padding: 0 0 15px 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__title
	{
		color: $colorGrey;
		@include font-size($fontSize - 1);
		font-weight: 600;
		@include line-height($fontHeight - 1);
		margin: 0;
		padding: 0 0 10px 0;
		text-transform: uppercase;
	}
	&__menu
	{
		font-size: 0;
		line-height: 0;
		margin: -2px 0 0 -2px;
		padding: 0;
		&__item
		{
			@include box-sizing();
			display: inline-block;
			margin: 0;
			padding: 2px 0 0 2px;
			vertical-align: top;
			@include mq-min($bpDesktop)
			{
				padding: 5px 0 0 5px;
			}
		}
		&__link
		{
			display: flex;
			align-items: center;
			justify-content: center;
			background: $colorWhite;
			border: 1px solid $colorPrimary;
			@include box-sizing();
			height: 38px;
			margin: 0;
			padding: 0;
			position: relative;
			width: 38px;
			z-index: 1;
			&:hover,
			&:focus
			{
				color: $colorWhite;
				background: $colorPrimary;
				&:before
				{
					background-position-y: -95px;
				}
			}
			@include mq-min($bpDesktop)
			{
				height: 48px;
				width: 48px;
			}
			&--no-border
			{
				border: 0;
			}
			&--actual
			{
				&:hover,
				&:focus
				{
					background: $colorWhite;
					color: $colorBase;
				}
			}
		}
		@include mq-min($bpDesktop)
		{
			margin: -5px 0 0 -5px;
		}
	}
	&--tight
	{
		padding-bottom: 0;
	}
}