﻿@import "../config";

.m-section-block {
	margin: 0;
	padding: 20px 0;
	text-align: center;

	&__inner {
		margin: 0 auto;
		padding: 0;
		position: relative;
		max-width: $maxPageWidth;
		z-index: 1;

		&--narrow {
			max-width: $maxPageWidth - 200;
		}
	}

	&__title {
		color: $colorBase;
		@include font-size($fontSize + 10);
		font-weight: 700;
		@include line-height($fontHeight + 10);
		margin: 0;
		padding: 0;
		text-transform: uppercase;

		@include mq-min($bpDesktop) {
			@include font-size($fontSize + 14);
			@include line-height($fontHeight + 14);
		}

		&--alt {
			color: $colorBase;
		}

		&--with-jump-action {
			@include mq-min($bpDesktop) {
				float: left;
				text-align: left;
				width: 50%;
			}
		}
	}

	&__content {
		clear: both;
		margin: 0;
		padding: 15px 0 0 0;

		@include mq-min($bpTabletLarge) {
			padding-top: 20px;
		}

		@include mq-min($bpDesktop) {
			padding-top: 25px;
		}

		@include mq-min($bpDesktopLarge) {
			padding-top: 30px;
		}
	}

	&__jump-action {
		padding-top: 20px;
		text-align: right;

		.form-select {
			width: 100%;
			max-width: none;
		}

		@include mq-min($bpDesktop) {
			padding-top: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 40%;
			max-width: 275px;
			z-index: 1;
		}
	}

	@include mq-min($bpTabletLarge) {
		padding: 30px 0;
	}

	@include mq-min($bpDesktop) {
		padding: 40px 0;
	}

	@include mq-min($bpDesktopLarge) {
		padding: 50px 0;
	}

	&--close {
		padding-top: 0;
	}

	&--tight {
		padding-bottom: 0;
	}
}