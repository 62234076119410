﻿@import "../config";

.m-sort-options
{
	margin: 0;
	padding: 0;
	.form-row
	{
		margin: 0;
		padding: 0;
		label
		{
			color: $colorBase;
			display: none;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight);
			font-weight: 600;
			margin: 0;
			padding: 0 5px 0 0;
			vertical-align: middle;
			@include mq-min($bpMobileLarge)
			{
				display: inline-block;
			}
		}
		.form-select
		{
			@include font-size(16px);
			height: 37px;
			@include line-height(16px);
			margin: 0;
			max-width: 170px;
		}
	}
}