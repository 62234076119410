﻿@import "../config";

.m-contact-header
{
	margin: 0;
	padding: 0 0 15px 0;
	&__inner
	{
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;
	}
	&__image-wrapper
	{
		font-size: 0;
		line-height: 0;

		&__image
		{
			max-height: 360px;
			height: auto;
			max-width: 640px;
			width: 50%;
			display: inline-block;
			@include box-sizing();

			&--right
			{
				@include mq-max($bpTablet)
				{
					display: none;
				}
				border-left: 1px solid $colorWhite;
			}

			&--left
			{
				@include mq-max($bpTablet - 1)
				{
					width: 100%;
				}
			}
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding-bottom: 20px;
	}
	@include mq-min($bpDesktop)
	{
		padding-bottom: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding-bottom: 30px;
	}
	&--tight
	{
		padding-bottom: 0;
	}
}