@import "../config";

$col: 100/12; // 12 cols
$gutter: 14px;
$gutterWide: 20px;
$gutterCheckout: 15px;
$floatMode: false;

@mixin getWidth($cols)
{
	width: (($col * $cols) * 1%);
}

// generic
.m-grid
{
	clear: both;
	margin: 0 auto;
	@if $floatMode
	{
		overflow: hidden;
	}	
	padding: 0;
	position: relative;
	z-index: 1;
	&__inner
	{
		@include box-sizing();
		clear: both;
		margin: 0;
		margin-left: -$gutter;
		padding: 0;
		zoom: 1;
		&--tight
		{
			margin-left: 0;
		}
	}
	&__row
	{
		@include box-sizing();
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
		position: relative;
		text-align: center;
		z-index: 1;
		@if $floatMode
		{
			text-align: left;
		}	
		&--reverse
		{
			direction: rtl;
		}
		&--align-left
		{
			text-align: left;
		}
		&--flex
		{
			@supports (display: flex) {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;

				&.m-grid__row--align-left {
					justify-content: flex-start;
				}
			}
		}
	}
	&__col
	{
		@if $floatMode
		{
			float: left;
		}
		@else
		{
			display: inline-block;
		}
		@include box-sizing();
		direction: ltr;
		@include font-size($fontSize);
		@include line-height($fontHeight);
		margin: 0 0 $gutter 0;
		padding: 0 0 0 $gutter;
		text-align: left;
		vertical-align: top;
		width: 100%;
		&--alt
		{
			@if $floatMode
			{
				float: right;
			}
		}
		&--tight
		{
			margin-bottom: 0;
			padding-left: 0;
		}
		&--centralised
		{
			text-align: center;
		}
	}
	&__col--1
	{
		@include getWidth(1);
	}
	&__col--2
	{
		@include getWidth(2);
	}
	&__col--3
	{
		@include getWidth(3);
	}
	&__col--4
	{
		@include getWidth(4);
	}
	&__col--5
	{
		@include getWidth(5);
	}
	&__col--6
	{
		@include getWidth(6);
	}
	&__col--7
	{
		@include getWidth(7);
	}
	&__col--8
	{
		@include getWidth(8);
	}
	&__col--9
	{
		@include getWidth(9);
	}
	&__col--10
	{
		@include getWidth(10);
	}
	&__col--11
	{
		@include getWidth(11);
	}
	@include mq-min($bpMobileLarge)
	{
		&__col--1--mobile-large
		{
			@include getWidth(1);
		}
		&__col--2--mobile-large
		{
			@include getWidth(2);
		}
		&__col--3--mobile-large
		{
			@include getWidth(3);
		}
		&__col--4--mobile-large
		{
			@include getWidth(4);
		}
		&__col--5--mobile-large
		{
			@include getWidth(5);
		}
		&__col--6--mobile-large
		{
			@include getWidth(6);
		}
		&__col--7--mobile-large
		{
			@include getWidth(7);
		}
		&__col--8--mobile-large
		{
			@include getWidth(8);
		}
		&__col--9--mobile-large
		{
			@include getWidth(9);
		}
		&__col--10--mobile-large
		{
			@include getWidth(10);
		}
		&__col--11--mobile-large
		{
			@include getWidth(11);
		}
		&__col--12--mobile-large
		{
			@include getWidth(12);
		}
	}
	@include mq-min($bpTablet)
	{
		&__col--1--tablet
		{
			@include getWidth(1);
		}
		&__col--2--tablet
		{
			@include getWidth(2);
		}
		&__col--3--tablet
		{
			@include getWidth(3);
		}
		&__col--4--tablet
		{
			@include getWidth(4);
		}
		&__col--5--tablet
		{
			@include getWidth(5);
		}
		&__col--6--tablet
		{
			@include getWidth(6);
		}
		&__col--7--tablet
		{
			@include getWidth(7);
		}
		&__col--8--tablet
		{
			@include getWidth(8);
		}
		&__col--9--tablet
		{
			@include getWidth(9);
		}
		&__col--10--tablet
		{
			@include getWidth(10);
		}
		&__col--11--tablet
		{
			@include getWidth(11);
		}
		&__col--12--tablet
		{
			@include getWidth(12);
		}
	}
	@include mq-min($bpTabletLarge)
	{
		&__col--1--tablet-large
		{
			@include getWidth(1);
		}
		&__col--2--tablet-large
		{
			@include getWidth(2);
		}
		&__col--3--tablet-large
		{
			@include getWidth(3);
		}
		&__col--4--tablet-large
		{
			@include getWidth(4);
		}
		&__col--5--tablet-large
		{
			@include getWidth(5);
		}
		&__col--6--tablet-large
		{
			@include getWidth(6);
		}
		&__col--7--tablet-large
		{
			@include getWidth(7);
		}
		&__col--8--tablet-large
		{
			@include getWidth(8);
		}
		&__col--9--tablet-large
		{
			@include getWidth(9);
		}
		&__col--10--tablet-large
		{
			@include getWidth(10);
		}
		&__col--11--tablet-large
		{
			@include getWidth(11);
		}
		&__col--12--tablet-large
		{
			@include getWidth(12);
		}
	}
	@include mq-min($bpDesktop)
	{
		&__col--1--desktop
		{
			@include getWidth(1);
		}
		&__col--2--desktop
		{
			@include getWidth(2);
		}
		&__col--3--desktop
		{
			@include getWidth(3);
		}
		&__col--4--desktop
		{
			@include getWidth(4);
		}
		&__col--5--desktop
		{
			@include getWidth(5);
		}
		&__col--6--desktop
		{
			@include getWidth(6);
		}
		&__col--7--desktop
		{
			@include getWidth(7);
		}
		&__col--8--desktop
		{
			@include getWidth(8);
		}
		&__col--9--desktop
		{
			@include getWidth(9);
		}
		&__col--10--desktop
		{
			@include getWidth(10);
		}
		&__col--11--desktop
		{
			@include getWidth(11);
		}
		&__col--12--desktop
		{
			@include getWidth(12);
		}
	}
	@include mq-min($bpDesktopLarge)
	{
		&__col--1--desktop-large
		{
			@include getWidth(1);
		}
		&__col--2--desktop-large
		{
			@include getWidth(2);
		}
		&__col--3--desktop-large
		{
			@include getWidth(3);
		}
		&__col--4--desktop-large
		{
			@include getWidth(4);
		}
		&__col--5--desktop-large
		{
			@include getWidth(5);
		}
		&__col--6--desktop-large
		{
			@include getWidth(6);
		}
		&__col--7--desktop-large
		{
			@include getWidth(7);
		}
		&__col--8--desktop-large
		{
			@include getWidth(8);
		}
		&__col--9--desktop-large
		{
			@include getWidth(9);
		}
		&__col--10--desktop-large
		{
			@include getWidth(10);
		}
		&__col--11--desktop-large
		{
			@include getWidth(11);
		}
		&__col--12--desktop-large
		{
			@include getWidth(12);
		}
	}
	@include mq-min($bpLarge)
	{
		&__col--1--large
		{
			@include getWidth(1);
		}
		&__col--2--large
		{
			@include getWidth(2);
		}
		&__col--3--large
		{
			@include getWidth(3);
		}
		&__col--4--large
		{
			@include getWidth(4);
		}
		&__col--5--large
		{
			@include getWidth(5);
		}
		&__col--6--large
		{
			@include getWidth(6);
		}
		&__col--7--large
		{
			@include getWidth(7);
		}
		&__col--8--large
		{
			@include getWidth(8);
		}
		&__col--9--large
		{
			@include getWidth(9);
		}
		&__col--10--large
		{
			@include getWidth(10);
		}
		&__col--11--large
		{
			@include getWidth(11);
		}
		&__col--12--large
		{
			@include getWidth(12);
		}
	}
	&--wider
	{
		> .m-grid__inner
		{
			margin-left: -$gutterWide;
			> .m-grid__row
			{
				> .m-grid__col
				{
					padding-left: $gutterWide;
					@include mq-min($bpDesktop)
					{
						padding-left: $gutterWide + 10;
					}
					@include mq-min($bpLarge)
					{
						padding-left: $gutterWide + 20;
					}
				}
			}
			@include mq-min($bpDesktop)
			{
				margin-left: -($gutterWide + 10);
			}
			@include mq-min($bpLarge)
			{
				margin-left: -($gutterWide + 20);
			}
		}
	}
	&--checkout
	{
		margin: 0 auto;
		max-width: 960px;
		> .m-grid__inner
		{
			margin-left: -$gutterCheckout;
			> .m-grid__row
			{
				> .m-grid__col
				{
					margin-bottom: $gutterCheckout;
					padding-left: $gutterCheckout;
					@include mq-min($bpDesktop)
					{
						padding-left: $gutterCheckout + 10;
					}
					@include mq-min($bpLarge)
					{
						padding-left: $gutterCheckout + 20;
					}
				}
			}
			@include mq-min($bpDesktop)
			{
				margin-left: -($gutterCheckout + 20);
			}
			@include mq-min($bpLarge)
			{
				margin-left: -($gutterCheckout + 30);
			}
		}
	}
	&--narrow
	{
		margin: 0 auto;
		max-width: 540px;
	}
	&--content
	{
		margin: 0;
		max-width: $maxContentWidth;
		> .m-grid__inner
		{
			margin-left: -$gutterWide;
			> .m-grid__row
			{
				> .m-grid__col
				{
					margin-bottom: 0;
					padding-left: $gutterWide;
				}
			}
		}
		&--no-restrict
		{
			max-width: 100%;
		}
	}
	&--spaced
	{
		@include mq-min($bpDesktop)
		{
			margin-bottom: $gutter;
		}
	}
	&--tight
	{
		margin-bottom: -$gutter;
	}
}