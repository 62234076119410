@import "../config";

.m-warranty-bar {
	background: $colorYellow;
	border-bottom: 1px solid $colorWhite;
	@include box-shadow(0 0 10px 0 rgba($colorBlack, .5));
	color: $colorWhite;
	font-size: 0;
	height: 50px;
	left: 0;
	line-height: 0;
	margin: 0;
	overflow: hidden;
	padding: 0 15px;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 9000;

	&__inner {
		@include box-sizing();
		height: 40px;
		margin: 0 auto;
		padding: 10px 40px 0 0;
		position: relative;
		max-width: $maxPageWidth;
		text-align: left;
		z-index: 1;

		@include mq-min($bpTablet) {
			padding-right: 100px;
		}

		@include mq-min($bpTabletLarge) {
			text-align: center;
		}
	}

	&__info, &__complete {
		display: inline-block;
		@include font-size($fontSize);
		@include line-height($fontHeight);
		margin: 0;
		padding: 5px 0 5px 20px;
		position: relative;
		vertical-align: middle;
		z-index: 1;
	}

	&__info {
		padding-left: 0;

		&:before {
			display: none;
		}

		&__link {
			color: $colorWhite;
			text-decoration: underline;

			&:hover {
				color: $colorWhite;
				text-decoration: none;
			}
		}
	}

	&__complete {
		padding: 0 0 0 17px;

		.form-button {
			display: inline-block;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 5px 10px;
			min-width: 10px;

			&:hover {
				background: $colorBase;
				border-color: $colorBase;
				color: $colorWhite;
				text-decoration: none;
			}
		}
	}

	&__abandon {
		@include font-size($fontSize);
		@include line-height($fontHeight);
		margin: 0;
		overflow: hidden;
		padding: 15px 0 0 0;
		position: absolute;
		right: 0;
		top: 0;
		max-width: 100px;
		z-index: 1;

		&__link {
			color: $colorWhite;
			display: block;
			@include min-height(14px);
			margin: 0;
			padding: 0 20px 0 0;
			position: relative;
			text-decoration: none;
			z-index: 1;

			&:before {
				@include bg('/images/prima/common/sprite.png', no-repeat, -232px -4px);
				content: "";
				display: block;
				height: 14px;
				margin: -7px 0 0 0;
				padding: 0;
				position: absolute;
				right: 0;
				top: 50%;
				width: 14px;
				z-index: 1;

				@include mq-max($bpTablet - 1) {
					left: 50%;
					margin-left: -7px;
				}
			}

			&:hover {
				color: $colorWhite;
				text-decoration: underline;
			}

			@include mq-max($bpTablet - 1) {
				@include min-height(24px);
				padding: 0;
				@include text-hidden();
				width: 24px;
			}
		}

		@include mq-max($bpTablet - 1) {
			padding-top: 13px;
		}
	}
}
