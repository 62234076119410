﻿@import "../config";

.c-small-product {
	background: $colorWhite;
	border: 1px solid $colorBorder;
	@include box-shadow(1px 1px 2px rgba($colorBlack, .05));
	margin: 0;
	padding: 0;
	position: relative;
	@include transition(box-shadow .2s);
	z-index: 1;

	.atb__main__list & {

		@supports (display: flex) {
			min-height: 100%;
			display: flex;
		}
	}
}

.c-small-product:hover {
	background: lighten($colorOffWhite, 5%);
	border-color: $colorDivider;
}

.c-small-product__inner {
	@include min-height(75px);
	margin: 0;
	padding: 15px;
}

.c-small-product__image {
	left: 0;
	padding: 10px;
	position: absolute;
	top: 0;
	width: 100px;
	z-index: 1;
	left: auto;
	margin: -15px -15px 0 -15px;
	padding: 0;
	position: relative;
	top: auto;
	width: auto;
	overflow: hidden;
}

.c-small-product__image__link {
	display: block;
	margin: 0;
	padding: 0;

	&:hover, &:focus {
		.c-small-product__image__image {
			@include opacity();
		}
	}
}

.c-small-product__image__image {
	display: block;
	margin: 0;
	padding: 0;
}

.c-small-product__options {
	position: absolute;
	left: auto;
	right: 10px;
	bottom: -5px;
	@include font-size($fontSize - 2);
	font-weight: normal;
	@include line-height($fontHeight - 2);
	margin: 0;
	z-index: 2;
	white-space: nowrap;

	@include mq-min($bpTablet) {
		bottom: auto;
		right: -1px;
		padding: 5px 10px;
		background: $colorSecondary;
		color: $colorWhite;
		text-transform: uppercase;
		transform: translateY(-100%);
	}
}

.c-small-product__options__link {
	color: $colorSecondary;
	text-decoration: underline;

	&:hover,
	&:focus {
		color: $colorSecondary;
		text-decoration: underline;
	}

	@include mq-min($bpTablet) {
		color: $colorWhite;
		text-decoration: none;

		&:hover,
		&:focus {
			color: $colorWhite;
			text-decoration: none;
		}
	}
}

.c-small-product__options__link__suffix {
	display: none;
}

@include mq-min($bpTablet) {

	.c-small-product__options__link__suffix {
		display: inline;
	}

	.c-small-product__options__link__prefix {
		display: none;
	}
}

.c-small-product__content {
	@include min-height(40px);
	margin: 0;
	padding: 15px 0 0 0;
	@include min-height(60px);
}

.c-small-product__title {
	display: -webkit-box;
	@include font-size($fontSize + 1);
	@include line-height($fontHeight);
	font-family: $fontBase;
	margin: 0;
	overflow: hidden;
	padding: 5px 0 0 0;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.c-small-product__title__link {
	color: $colorBase;
	display: block;
	font-family: $fontBase;
	margin: 0;
	padding: 0;

	&:hover,
	&:focus {
		color: $colorPrimary;
	}

	&.focus-visible {
		@include focus-style-alt();
	}
}

.c-small-product__cost {
	margin: 0;
	padding: 15px 0 0 0;
	padding-top: 15px;
}

.c-small-product__price {
	@include box-sizing();
	color: $colorGrey;
	font-size: 0;
	line-height: 0;
	padding: 0;
	margin: 0 0 0 -10px;
}

.c-small-product__price__list,
.c-small-product__price__other {
	display: inline-block;
	@include font-size($fontSize - 1);
	@include line-height($fontHeight - 1);
	margin: 0;
	padding: 0 0 0 10px;
}

.c-small-product__price__list {
	color: $colorPrimary;
	@include font-size(16px);
	@include line-height(21px);
	font-weight: 700;
}

.c-small-product__price__list--sale {
	color: $colorRed;
}

.c-small-product__price__list__form {
	@include font-size($fontSize - 2);
	@include line-height($fontHeight - 2);
	font-weight: 600;
}

.c-small-product__price__list__form--sale {
	color: $colorRed;
}

.c-small-product__price__other {
	@include font-size($fontSize - 1);
	@include line-height($fontHeight - 1);
}

.c-small-product__price__other--vat {
	color: $colorRed;
	padding: 0;
}

.c-small-product__delivery {
	color: $colorGrey;
	@include font-size($fontSize - 2);
	@include line-height($fontHeight - 2);
	margin: 0;
	padding: 5px 0 0 0;
}

.c-small-product__delivery--out {
	color: $colorRed;
}

.c-small-product__actions {
	display: block;
	margin: 0;
	padding: 15px 0 0 0;
}

/* Mobile layout */

@include mq-max($bpTablet) {

	.c-small-product__image {
		left: 0;
		margin: 0;
		margin: 10px;
		padding: 0;
		position: absolute;
		top: 0;
		width: 115px;
		z-index: 1;
		border: 1px solid #dedede;
	}

	.c-small-product__body {
		padding-left: 130px;
	}

	.c-small-product__inner {
		padding: 10px;
	}

	.c-small-product__content,
	.c-small-product__cost {
		padding-top: 0;
	}

	.c-small-product__content {
		min-height: 0;
	}
}