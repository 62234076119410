@import "config";
@import "base/forms";

// inputs
.form-select
{
	> option
	{
		&.unavailable
		{
			color: #bbb;
		}
	}
}

// buttons
.form-button {
	background: $colorPrimary;
	border-color: $colorPrimary;
	color: $colorWhite;
	font-weight: 700;
	padding: 14px 25px;
	text-transform: none;
	min-width: 128px;
	transition: all 300ms;

	&:hover,
	&:focus {
		background: $colorBase;
		border-color: $colorBase;
		color: $colorWhite;
	}

	&--alt, &--base {
		background: $colorBase;
		border-color: $colorBase;
		color: $colorWhite;

		&:hover {
			background: $colorGrey;
			border-color: $colorGrey;
			color: $colorWhite;
		}
	}

	&--blue {
		background: $colorSecondary;
		border-color: $colorSecondary;
		color: $colorWhite;

		&:hover {
			background: $colorGrey;
			border-color: $colorGrey;
			color: $colorWhite;
		}
	}

	&--on-dark {
		background-color: $colorWhite;
		color: $colorBlue;

		&:hover {
			background-color: $colorBase;
			color: $colorWhite;
		}
	}

	&--large {
		@include font-size($fontSize + 2);
		@include line-height($fontHeight + 2);
		padding: 11px 20px;

		@include mq-min($bpDesktop) {
			padding: 11px 30px;
		}
	}

	&--massive {
		font-size: $fontSize + 2;
		line-height: $fontHeight + 2;
		padding: 15px 25px;

		@include mq-min($bpTablet) {
			min-width: 300px;
		}
	}

	&--line {
		background: $colorWhite;
		border-color: $colorPrimary;
		color: $colorPrimary;

		&:hover {
			background: $colorPrimary;
			border-color: $colorPrimary;
			color: $colorWhite;
		}
	}

	&--white {
		background: $colorWhite;
		border-color: $colorWhite;
		color: $colorBase;

		&:hover {
			background: $colorPrimary;
			border-color: $colorPrimary;
			color: $colorWhite;
		}
	}

	&--white-blue {
		background: $colorWhite;
		border-color: $colorWhite;
		color: $colorPrimary;

		&:hover {
			background: $colorPrimary;
			border-color: $colorPrimary;
			color: $colorWhite;
		}
	}

	&--full {
		width: 100%;
	}

	&--clear {
		background: none;
		border-color: $colorWhite;
		color: $colorWhite;

		&:hover {
			background: $colorWhite;
			border-color: $colorWhite;
			color: $colorLink;
		}
	}

	&--text {
		background: none;
		border: 0 !important;
		color: $colorPrimary;
		padding: 11px 5px;
		text-transform: none;

		&:hover {
			background: none;
			border-color: transparent;
			color: $colorBase;
		}
	}

	&--flush {
		padding-left: 0;
		padding-right: 0;
	}

	&--small {
		min-width: 0;
		padding: 11px 15px;
	}

	&--search {
		background: $colorWhite;
		color: $colorBlue;
		border-color: $colorWhite;
		height: 38px;
		padding: 0;
		position: relative;
		min-width: 38px;
		width: 20px;
		z-index: 1;

		&:hover {
			color: $colorBase;
			background: $colorWhite;
			border-color: $colorWhite;
		}
	}

	&--paypal-checkout {
		@include bg('/images/prima/common/sprite-v2.png', no-repeat, 0 -236px);
		background-color: #ffc439;
		border: 1px solid #dc911d;
		height: 42px !important;
		padding: 0;
		@include text-hidden();
		width: 226px !important;

		&:hover {
			@include bg('/images/prima/common/sprite-v2.png', no-repeat, 0 -236px);
			@include box-shadow(0 0 0 3px rgba(#dc911d, .25));
			border-color: #dc911d;
		}
	}
}

// info
.form-info
{
	@include font-size($fontSize - 2);
	@include line-height($fontHeight - 2);
	a
	{
		font-weight: 600;
	}
}

// messages
.form-message, .form-error, .form-success, .form-warning
{
	a
	{
		font-weight: 600;
	}
}

// jquery-ui: autocomplete
.ui-autocomplete
{
	background: $colorWhite;
	border: 1px solid $colorBorder;
	@include border-radius(0);
	@include box-shadow();
	color: $colorBase;
	font-family: $fontSimple;
	@include font-size($fontSize);
	max-height: 200px;
	left: 0;
	@include line-height($fontHeight);
	margin: 0;
	overflow-y: auto;
	padding: 0;
	text-align: left;
	top: 0;
	width: 240px;
	z-index: 9990;
	.ui-menu-item
	{
		border: 0;
		@include border-radius(0);
		margin: 0;
		padding: 5px 10px;
		&.ui-state-focus
		{
			background: $colorPrimary;
			color: $colorWhite;
		}
	}
	@include mq-min($bpDesktop)
	{
		max-height: 250px;
	}
	@include mq-min($bpLarge)
	{
		width: 300px;
	}
}

// jquery-ui: datepicker
.ui-datepicker 
{
	border: 0;
	@include border-radius(0);
	@include box-shadow(0 2px 2px rgba(0,0,0,.2));
	margin: 0;
	padding: 5px;
	width: 230px !important;
	z-index: 5555 !important;
	.ui-datepicker-header 
	{
		border: 0;
		height: 30px;
		margin: 0;
		padding: 0;
	}
	.ui-datepicker-title 
	{
		background: $colorDivider;
		color: $colorBase;
		font-family: $fontBase;
		@include font-size($fontSize - 1);
		font-weight: 700;
		height: 25px;
		@include line-height($fontHeight - 1);
		margin: 0;
		padding: 5px 30px 0 30px;
		text-align: center;
		text-transform: uppercase;
	}
	.ui-datepicker-next, 
	.ui-datepicker-prev
	{
		background: none;
		border: 0;
		@include border-radius(0);
		cursor: pointer;
		height: 30px;
		top: 0;
		width: 30px;
		&.ui-state-disabled 
		{
			visibility: hidden;
		}
		&.ui-state-hover 
		{
			background: lighten($colorDivider, 5%);
		}
	}
	.ui-datepicker-prev 
	{
		left: 0;
	}
	.ui-datepicker-next 
	{
		right: 0;
	}
	.ui-datepicker-calendar 
	{
		border: 0;
		border-collapse: collapse;
		margin: 0;
		padding: 0;
		table-layout: fixed;
		thead 
		{
			tr 
			{
				th, td 
				{
					background: $colorBase;
					color: $colorWhite;
					font-family: Verdana, sans-serif;
					@include font-size($fontSize - 3);
					font-weight: 400;
					height: 28px;
					@include line-height($fontHeight - 3);
					margin: 0;
					padding: 0;
					text-align: center;
					vertical-align: middle;
					width: 25px;
				}
			}
		}
		tbody 
		{
			tr 
			{
				th, td 
				{
					background: $colorWhite;
					border: 0;
					font-family: Verdana, sans-serif;
					@include font-size($fontSize - 3);
					font-weight: 400;
					height: 25px;
					@include line-height($fontHeight - 3);
					margin: 0;
					padding: 0;
					text-align: center;
					vertical-align: middle;
					width: 25px;
					span 
					{
						background: lighten($colorDivider, 5%);
						border-color: lighten($colorDivider, 5%);
						color: $colorBase;
						display: block;
						height: 20px;
						margin: 1px 0 0 1px;
						padding: 3px 2px 2px 5px;
						text-align: left;
					}
					a
					{
						display: block;
						height: 20px;
						margin: 1px 0 0 1px;
						padding: 3px 2px 2px 5px;
						text-align: left;
						&.ui-state-default 
						{
							background: lighten($colorDivider, 5%);
							border-color: lighten($colorDivider, 5%);
							color: $colorBase;
						}
						&.ui-state-highlight 
						{
							background: lighten($colorDivider, 5%);
							border-color: darken($colorDivider, 25%);
							color: $colorBase;
						}
						&:hover, &.ui-state-hover, &.ui-state-active 
						{
							background: $colorPrimary;
							border-color: $colorPrimary;
							color: $colorWhite;
						}
					}
					&:first-child 
					{
						a 
						{
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}

.form-text-wrapper {
	padding-left: 135px;
	background: $colorGreyAnother2;
	position: relative;

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 135px;
		bottom: 0;
		border: 1px solid $colorGreyAnother3;
		border-right: 0;
	}
}

.form-text-wrapper__desc {
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	width: 135px;
	text-align: center;
	transform: translateY(-50%);
	font-weight: 600;
	@include font-size(14px);
	color: $colorGreyMid;
}
