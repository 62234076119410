﻿@import "../config";

.l-content {
	margin: 0;
	padding: 0;

	&__title {
		font-size: 0;
		line-height: 0;
		margin: -10px 0 0 -10px;
		padding: 0 0 15px 0;

		&__primary {
			display: inline-block;
			@include font-size($fontSize + 10);
			font-weight: 600;
			@include line-height($fontHeight + 10);
			margin: 0;
			padding: 10px 0 0 10px;
		}

		&__secondary {
			display: inline-block;
			font-family: $fontBase;
			@include font-size($fontSize - 1);
			font-weight: 400;
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 10px 0 0 10px;

			&--results {
				font-weight: 700;
				@include font-size(16px);
				@include mq-min($bpTabletLarge) {
					padding-top: 18px;
					@include font-size(20px);
				}
			}
		}

		&__action {
			display: inline-block;
			@include font-size($fontSize - 1);
			font-weight: 600;
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 10px 0 0 10px;
		}

		&--spaced {
			@include mq-min($bpTabletLarge) {
				padding-bottom: 20px;
			}

			@include mq-min($bpDesktop) {
				padding-bottom: 25px;
			}

			@include mq-min($bpDesktopLarge) {
				padding-bottom: 30px;
			}
		}

		&--tight {
			padding-bottom: 0;
		}
	}

	&__sub-title {
		font-size: 0;
		line-height: 0;
		margin: -10px 0 0 -10px;
		padding: 0 0 15px 0;

		&__primary {
			display: inline-block;
			@include font-size($fontSize + 5);
			font-weight: 400;
			@include line-height($fontHeight + 5);
			margin: 0;
			padding: 10px 0 0 10px;
		}

		&__secondary {
			display: inline-block;
			font-family: $fontBase;
			@include font-size($fontSize - 1);
			font-weight: 400;
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 10px 0 0 10px;
		}

		&--spaced {
			@include mq-min($bpTabletLarge) {
				padding-bottom: 20px;
			}

			@include mq-min($bpDesktop) {
				padding-bottom: 25px;
			}

			@include mq-min($bpDesktopLarge) {
				padding-bottom: 30px;
			}
		}

		&--tight {
			padding-bottom: 0;
		}
	}

	&__summary {
		@include font-size($fontSize + 2);
		@include line-height($fontHeight + 2);
		margin: 0;
		padding: 0 0 5px 0;
	}
}