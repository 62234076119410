﻿.c-banner {
  position: relative;
  min-height: 350px;
  margin: 0 -10px;
  background: $colorBase;

  @include mq-min($bpTablet) {
    min-height: 600px;

    @media screen and (max-height: 1000px) {
      min-height: 500px;
    }
  }

  @include mq-min($bpDesktop) {
    min-height: 860px;

    @media screen and (max-height: 860px) {
      min-height: 600px;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $colorWhite;
    opacity: 0.5;
    z-index: 1;
  }

  .form-button--massive {
    @include font-size(15px);

    @include mq-min($bpDesktop) {
      min-width: 180px;
    }
  }
}

.c-banner__inner {
  width: 100%;
  height: 100%;
  min-height: inherit;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  max-width: 1580px;
  margin: 0 auto;
  padding: 30px;
  box-sizing: border-box;

  @include mq-min($bpTablet) {
    padding: 60px 30px;
  }

  @include mq-min($bpDesktop) {
    padding: 115px 30px;
  }
}

.c-banner__copy {
  display: inline-block;
  max-width: 490px;
}

.c-banner__subtitle {
  margin: 0;
  padding: 0;
  @include font-size(16px);
  @include line-height(18px);
  font-weight: normal;
  font-style: italic;

  @include mq-min($bpTablet) {
    @include font-size(18px);
    @include line-height(20px);
  }

  @include mq-min($bpDesktop) {
    @include font-size(28px);
    @include line-height(24px);
  }

  &:not(:last-child) {
    margin: 0 0 10px 0;
    @include mq-min($bpDesktop) {
        margin: 0 0 20px 0;
    }
  }
}

.c-banner__title {
  margin: 0;
  padding: 0;
  @include font-size(28px);
  @include line-height(28px);
  font-weight: 800;

  @include mq-min($bpTablet) {
    @include font-size(38px);
    @include line-height(38px);
  }

  @include mq-min($bpDesktop) {
    @include font-size(63px);
    @include line-height(70px);
  }

  &:not(:last-child) {
    margin: 0 0 20px 0;

    @include mq-min($bpDesktop) {
      margin: 0 0 50px 0;
    }
  }
}

.c-banner__action {
  margin: 0;
  padding: 0;
}

.c-banner__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-banner--align-top {

  .c-banner__inner {
    align-items: flex-start;
  }
}

.c-banner--align-bottom {

  .c-banner__inner {
    align-items: flex-end;
  }
}

.c-banner--align-left {

  .c-banner__inner {
    text-align: left;
    justify-content: flex-start;
  }
}

.c-banner--align-right {

  .c-banner__inner {
    text-align: left;
    justify-content: flex-end;
  }
}

.c-banner--dark {

  &:before {
    background: $colorBase;
  }

  .c-banner__copy {
    color: $colorWhite;
  }
}