﻿@import "../config";

.m-warranty-files
{
	margin: 0;
	padding: 0 0 20px 0;
	max-width: 460px;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__title
	{
		@include font-size($fontSize);
		font-weight: 600;
		@include line-height($fontHeight);
		margin: 0;
		padding: 0 0 10px 0;
		&:after
		{
			content: ":";
		}
	}
	&__menu
	{
		margin: -10px 0 0 -10px;
		padding: 0;
		&__item
		{
			@include box-sizing();
			display: inline-block;
			margin: 0;
			padding: 10px 0 0 10px;
			vertical-align: top;
			width: 20%;
			max-width: 100px;
		}
		&__link
		{
			display: block;
			margin: 0;
			padding: 0;
		}
		&__image
		{
			display: block;
			margin: 0;
			padding: 0;
		}
		&__remove
		{
			display: block;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 10px 0 0 18px;
			position: relative;
			z-index: 1;
			input
			{
				left: 0;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 13px;
				z-index: 1;
			}
		}
	}
}