﻿@import "../config";

.m-product-preview
{
	margin: 0;
	padding: 0 0 15px 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__image
	{
		display: none;
		float: left;
		margin: 0;
		padding: 0 15px 0 0;
		width: 120px;
		@include mq-min($bpTablet)
		{
			display: block;
		}
		@include mq-min($bpTabletLarge)
		{
			width: 200px;
		}
		@include mq-min($bpDesktop)
		{
			padding-right: 20px;
			width: 280px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-right: 25px;
		}
	}
	&__details
	{
		margin: 0;
		overflow: hidden;
		padding: 0;
	}
	&__title
	{
		@include font-size($fontSize + 8);
		font-weight: 400;
		@include line-height($fontHeight + 8);
		margin: 0;
		padding: 0 0 15px 0;
		&__link
		{
			color: $colorBase;
			display: block;
			margin: 0;
			padding: 0;
			text-decoration: none;
			&:hover
			{
				color: $colorPrimary;
				text-decoration: none;
			}
		}
	}
	&__price
	{
		color: $colorGrey;
		font-size: 0;
		line-height: 0;
		margin: 0 0 0 -10px;
		padding: 0 0 15px 0;
		&__list, &__other
		{
			display: inline-block;
			@include font-size($fontSize);
			@include line-height($fontHeight);
			margin: 0;
			padding: 0 0 0 10px;
		}
		&__list
		{
			color: $colorPrimary;
			@include font-size($fontSize + 10);
			font-weight: 700;
			@include line-height($fontHeight + 10);
			&--sale
			{
				color: $colorRed;
			}
		}
		&__other
		{
			@include font-size($fontSize);
			@include line-height($fontHeight);
			&--consumer
			{
				display: block;
				@include font-size($fontSize - 1);
				@include line-height($fontHeight - 1);
				padding-top: 5px;
			}
		}
	}
	&__quantity
	{
		margin: 0;
		padding: 0 0 15px 0;
	}
	&__stock
	{
		color: $colorBlue;
		display: inline-block;
		@include font-size($fontSize - 1);
		font-weight: 600;
		@include line-height($fontHeight - 1);
		margin: 0;
		padding: 0;
		vertical-align: middle;
		&.out
		{
			color: $colorRed;
		}
	}
	&__add
	{
		margin: 0;
		padding: 0;
	}
	&--tight
	{
		padding-bottom: 0;
	}
}