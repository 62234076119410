﻿@import "../config";

.l-back-to-top {
	position: relative;
	z-index: 1;
	padding: 0;
	max-width: 1520px;
	margin: 0 auto;
}

.l-back-to-top__link {
	position: absolute;
	right: 0;
	top: -30px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: $colorFadedYellow;
	color: $colorBase;
	height: 60px;
	width: 60px;
	border-radius: 100%;
	box-shadow: 0 3px 10px rgba(0,0,0,0.2);
	transition: all 300ms;
}

.l-back-to-top__link:hover {
	color: $colorFadedYellow;
	background: $colorBase;
}

@include mq-min($maxPageWidth) {
	.l-back-to-top {
		display: none;
	}

}