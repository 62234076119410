﻿@import "../config";

.m-vin-lookup
{
	margin: 0;
	padding: 0 0 15px 0;
	.form-row
	{
		padding-bottom: 0;
		label
		{
			@include mq-min($bpMobileLarge)
			{
				display: inline-block;
				margin: 0;
				padding: 0 10px 0 0;
				vertical-align: middle;
			}
		}
		.form-text
		{
			width: 100%;
			max-width: 115px;
		}
	}
	&__clear
	{
		color: $colorGrey;
		@include font-size($fontSize - 2);
		@include line-height($fontHeight - 2);
		margin: 0;
		padding: 10px 0 0 0;
		&__link
		{
			color: $colorGrey;
			text-decoration: none;
			&:hover
			{
				color: $colorPrimary;
				text-decoration: none;
			}
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding-bottom: 20px;
	}
	@include mq-min($bpDesktop)
	{
		padding-bottom: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding-bottom: 30px;
	}
}