﻿@import "../config";

.m-banded-block
{
	background: $colorOffWhite no-repeat 50% 50%;
	@include background-size(cover);
	margin: 0 -10px;
	padding: 20px 15px;
	position: relative;
	z-index: 1;
	&__inner
	{
		margin: 0 auto;
		padding: 0;
		position: relative;
		text-align: left;
		max-width: $maxPageWidth;
		z-index: 2;
	}
	@include mq-min($bpTabletLarge)
	{
		padding: 20px;
	}
	@include mq-min($bpDesktop)
	{
		padding: 30px 15px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding: 40px 15px;
	}
	@include mq-min($bpLarge)
	{
		padding: 50px 15px;
	}
	&--image
	{
		&:before, &:after
		{
			content: "";
			display: block;
			height: 10px;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			z-index: 1;
		}
		&:before
		{
			@include gradient-vertical(rgba($colorBlack, .15), rgba($colorBlack, 0));
			top: 0;
		}
		&:after
		{
			bottom: 0;
			@include gradient-vertical(rgba($colorBlack, 0), rgba($colorBlack, .15));
		}
		&--dark
		{
			background-color: $colorGrey;
			.focus-visible {
				@include focus-style-alt($colorPrimary);
			}
		}
		&:not(.keep-image)
		{
			@include mq-max($bpDesktop)
			{
				background-image: none !important;
			}
		}
	}
	&--alt
	{
		background-color: $colorWhite;
	}
	&--close
	{
		padding-top: 0;
	}
}