﻿@import "../config";

$bpSwitch: $bpTabletLarge;

.m-banner
{
	background: $colorDivider;
	margin: 0 -10px;
	overflow: hidden;
	padding: 0;
	&__inner
	{
		margin: 0 auto;
		padding: 0;
		position: relative;
		max-width: 760px;
		max-width: $maxPageWidth;
		z-index: 1;
		@include mq-min($bpSwitch)
		{
			max-width: $maxPageWidth;
		}
	}
	&__menu
	{
		height: 100%;
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;
		@include mq-max($bpSwitch - 1)
		{
			@include ratio($ratioImageBannerWidth, $ratioImageBannerHeight);
		}
		&__item
		{
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 1;
			@include mq-max($bpSwitch - 1)
			{
				position: absolute;
				top: 0;
			}
			&--1
			{
				@include mq-min($bpSwitch)
				{
					margin-bottom: -12px;
					margin-top: -12px;
					@include rotate(-1deg);
					width: 66.6666%;
				}
				@include mq-min($bpDesktopLarge)
				{
					left: -20px;
				}
			}
			&--2, &--3
			{
				display: none;
				@include mq-min($bpSwitch)
				{
					position: absolute;
					right: 0;
					z-index: 2;
					display: block;
				}
			}
			&--2
			{
				@include mq-min($bpSwitch)
				{
					right: -8px;
					@include rotate(-1deg);
					width: 37%;
					top: 2px;
				}
				@include mq-min($bpDesktopLarge)
				{
					right: -20px;
					top: -7px;
					width: 40%;
				}
			}
			&--3
			{
				@include mq-min($bpSwitch)
				{
					bottom: -12px;
					@include rotate(1deg);
					right: -8px;
					width: 35%;
				}
				@include mq-min($bpDesktopLarge)
				{
					bottom: -17px;
					right: 10px;
				}
			}
		}
	}
	&__block
	{
		background: $colorBase;
		margin: 0;
		padding: 0;
		&__content
		{
			@include box-sizing();
			color: $colorWhite;
			left: 0;
			margin: 0;
			padding: 15px 45px 35px 45px;
			position: absolute;
			text-align: left;
			@include text-shadow(1px 1px 2px rgba($colorBlack, .8));
			top: 0;
			max-width: 450px;
			z-index: 2;
			@include mq-min($bpTablet)
			{
				padding: 25px 55px 35px 55px;
			}
			@include mq-min($bpSwitch)
			{
				padding: 25px 20px;
			}
			@include mq-min($bpDesktop)
			{
				padding: 40px 30px;
			}
			@include mq-min($bpDesktopLarge)
			{
				padding: 45px 40px;
			}
			@include mq-min($bpLarge)
			{
				padding: 55px 40px;
			}
			&--top-right
			{
				left: auto;
				right: 0;
				text-align: right;
			}
			&--bottom-left, &--bottom-right
			{
				bottom: 0;
				top: auto;
				.m-banner__menu__item--2 &
				{
					@include mq-min($bpSwitch)
					{
						padding-bottom: 60px;
					}
					@include mq-min($bpDesktop)
					{
						padding-bottom: 75px;
					}
					@include mq-min($bpDesktopLarge)
					{
						padding-bottom: 85px;
					}
					@include mq-min($bpLarge)
					{
						padding-bottom: 95px;
					}
				}
			}
			&--bottom-right
			{
				left: auto;
				right: 0;
				text-align: right;
			}
			.m-banner__menu__item--1 &
			{
				@include mq-min($bpSwitch)
				{
					padding: 35px 30px;
				}
				@include mq-min($bpDesktop)
				{
					padding: 45px 40px;
				}
				@include mq-min($bpDesktopLarge)
				{
					padding: 55px 65px;
				}
				@include mq-min($bpLarge)
				{
					padding: 105px 75px;
				}
			}
			.m-banner__menu__item--1 &,
			.m-banner__menu__item--2 &
			{
				@include mq-min($bpSwitch)
				{
					@include rotate(1deg);
				}
			}
			.m-banner__menu__item--3 &
			{
				@include mq-min($bpSwitch)
				{
					@include rotate(-1deg);
				}
			}
		}
		&__title, &__description, &__price
		{
			margin: 0;
			padding: 0;
		}
		&__title
		{
			@include font-size($fontSize + 10);
			font-style: italic;
			font-weight: 600;
			@include line-height($fontHeight + 10);
			@include mq-min($bpSwitch)
			{
				@include font-size($fontSize + 5);
				@include line-height($fontHeight + 5);
			}
			@include mq-min($bpDesktopLarge)
			{
				@include font-size($fontSize + 10);
				@include line-height($fontHeight + 10);
			}
			.m-banner__menu__item--1 &
			{
				@include mq-min($bpLarge)
				{
					@include font-size($fontSize + 15);
					@include line-height($fontHeight + 15);
				}
			}
		}
		&__description
		{
			@include font-size($fontSize + 2);
			@include line-height($fontHeight + 2);
			@include mq-min($bpSwitch)
			{
				@include font-size($fontSize + 1);
				@include line-height($fontHeight + 1);
			}
			@include mq-min($bpDesktopLarge)
			{
				@include font-size($fontSize + 2);
				@include line-height($fontHeight + 2);
			}
			.m-banner__menu__item--1 &
			{
				@include mq-min($bpDesktopLarge)
				{
					@include font-size($fontSize + 4);
					@include line-height($fontHeight + 4);
				}
			}
		}
		&__price
		{
			display: inline-block;
			@include font-size($fontSize + 2);
			@include line-height($fontHeight + 2);
			margin: 0;
			padding: 10px 15px 10px 0;
			vertical-align: middle;
		}
		&__action
		{
			margin: 0;
			padding: 15px 0 0 0;
			.form-button
			{
				@include text-shadow(none);
			}
			@include mq-min($bpSwitch)
			{
				display: none;
			}
			@include mq-min($bpDesktop)
			{
				display: block;
			}
		}
		&__link
		{
			bottom: 0;
			display: block;
			left: 45px;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 45px;
			@include text-hidden();
			top: 0;
			z-index: 5;
			color: $colorWhite;
			@include mq-min($bpSwitch)
			{
				left: 0;
				right: 0;
			}
		}
		@include mq-min($bpSwitch)
		{
			border: 8px solid $colorWhite;
			@include box-shadow(0 0 4px rgba($colorBlack, .25));
		}
		&:hover
		{
			.m-banner__block__action
			{
				.form-button
				{
					background: $colorBase;
					border-color: $colorBase;
					color: $colorWhite;
				}
			}			
		}
	}
	&-wrapper
	{
		border-bottom: 8px solid $colorWhite;
		border-top: 8px solid $colorWhite;
	}

	.bx-pager
	{
		display: none;
	}
}