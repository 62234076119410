@import "../config";

.m-vin-search
{
	border-bottom: 1px solid $colorDivider;
	margin: 0 0 15px 0;
	padding: 0 0 15px 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__title
	{
		@include font-size($fontSize + 4);
		font-weight: 600;
		@include line-height($fontHeight + 4);
		margin: 0;
		padding: 0 0 15px 0;
		@include mq-min($bpDesktop)
		{
			padding-bottom: 20px;
		}
	}
	&__vin
	{
		border-bottom: 1px dashed $colorDivider;
		margin: 0 0 15px 0;
		padding: 0 0 20px 0;
		.form-row
		{
			padding-bottom: 0;
			label
			{
				@include font-size($fontSize - 1);
				@include line-height($fontHeight - 1);
				@include mq-min($bpLarge)
				{
					display: inline-block;
					margin: 0 0 5px 0;
					padding: 0;
					vertical-align: middle;
				}
				.m-popup &
				{
					@include mq-min($bpTablet)
					{
						display: inline-block;
						margin: 0;
						padding: 0 10px 0 0;
						vertical-align: middle;
					}
				}
			}
			.form-text
			{
				width: 100%;
				margin-right: 0;
			}
			@include mq-min($bpTablet)
			{
				.m-popup & {
					.form-error {
						margin-left: 82px;
					}
				}
			}

		}
		&--no-divider
		{
			border-bottom: 0;
			margin-bottom: 0;
		}
		&--filter
		{
			@include mq-min($bpTabletLarge)
			{
				border-bottom-color: $colorGreyLight;
			}
		}
	}
	&__help-link {
		display: flex;
		align-items: center;
		@include font-size(12px);
		font-weight: 600;
		&:hover
		{
			text-decoration: underline;
		}
	}
	&__help-link-icon {
		margin-right: 5px;
	}
	&__search-button {
		margin-top: 15px;
	}
	&__model
	{
		margin: 0 0 -10px 0;
		padding: 0;
		.form-row
		{
			padding: 10px 0 0 0;
			.form-select
			{
				margin-right: 2px;
				width: 100%;
				max-width: 250px;
			}
			&:first-child
			{
				padding-top: 0;
			}
		}
		&--filter
		{
			margin-bottom: 0;
		}
	}
	&__clear
	{
		color: $colorGrey;
		@include font-size($fontSize - 2);
		@include line-height($fontHeight - 2);
		margin: 0;
		padding: 10px 0 0 0;
		&__link
		{
			color: $colorGrey;
			text-decoration: none;
			&:hover
			{
				color: $colorPrimary;
				text-decoration: none;
			}
		}
	}
	@include mq-min($bpTabletLarge)
	{
		margin-bottom: 20px;
		padding-bottom: 20px;
	}
	@include mq-min($bpDesktop)
	{
		margin-bottom: 25px;
		padding-bottom: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		margin-bottom: 30px;
		padding-bottom: 30px;
	}
	.js &
	{
		display: block;
	}
	&--no-divider
	{
		border-bottom: 0;
		margin-bottom: 0;
	}
	&--tight
	{
		border-bottom: 0;
		margin-bottom: 0;
		padding-bottom: 0;
	}
	&--filter
	{
		border: 0;
		margin: 0;
		@include mq-max($bpTabletLarge - 1)
		{
			background: lighten($colorDivider, 10%);
			border-bottom: 1px solid $colorWhite;
			padding: 10px;
		}
	}
}

.m-vin-search--alternate {
	border: 0;
	margin: 0;
	padding: 0;

	.m-vin-search__vin {
		border: 0;
		margin: 0;
		padding: 0;
	}

	.form-row {
		padding: 0;
		margin: 0;

		&:not(:last-child) {
			margin: 0 0 20px 0;
		}

		&.form-action {

			@include mq-min($bpDesktopLarge) {
				padding: 20px 0 0 0;
			}
		}
	}

	.m-vin-search__vin {

		.form-row {

			&.form-action {

				@include mq-min($bpDesktopLarge) {
					padding: 10px 0 0 0;
				}
			}
		}
	}

	.m-vin-search__vin__help {
		margin: 0 0 15px 0;
		padding: 0;

		@include mq-min($bpDesktopLarge) {
			margin: 0 0 30px 0;
		}
	}

	.form-label {
		font-weight: 600;
		display: block !important;
		padding: 0 !important;
		margin: 0 0 5px 0 !important;
		@include font-size(14px);
		@include line-height(18px);
	}

	.form-error {
		margin-left: 0 !important;
	}

	.form-text-wrapper__desc {
		@include font-size(15px);
	}

	.form-text,
	.form-select {
		height: 53px;
		@include font-size(16px);
		line-height: 51px;
		padding: 0 20px;
		border: 1px solid $colorBorder;
		color: $colorBase;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border-radius: 0;
		width: 100%;
		max-width: 100% !important;
	}

	.form-select {
		background-image: url(../images/prima/common/select-arrow.svg);
		background-size: 24px auto;
		background-position: calc(100% - 20px) 50%;
		background-repeat: no-repeat;
		padding-right: 55px;

		&::-ms-expand {
			display: none;
		}

		&::-ms-value {
			background: none;
			color: $colorBase;
		}
	}

	.form-button {
		padding-top: 20px;
		padding-bottom: 19px;

		@include mq-min($bpDesktopLarge) {
			min-width: 240px;
		}
	}

	.m-vin-search__help {
		padding: 30px 0 0 0;
	}

	.m-vin-search__help__copy {
		margin: 0 0 15px 0;

		@include mq-min($bpDesktopLarge) {
			margin: 0 0 35px 0;
		}

		.cms {

			a {
				text-decoration: underline;

				&:hover,
				&:focus {
					text-decoration: none;
				}
			}

			> * {

				&:last-child {
					padding: 0;
				}
			}
		}
	}

	.m-vin-search__model__title,
	.m-vin-search__help__title,
	.m-vin-search__vin__title {
		@include font-size(18px);
		@include line-height(21px);
		font-weight: 600;
		margin: 0 0 15px 0;
		padding: 0;
	}

	.m-popup & .m-tabs {

		.m-vin-search__help {

			@include mq-min($bpDesktopLarge) {
				position: absolute;
				top: 116px;
				padding: 0;
				right: 30px;
				width: 295px;
				z-index: 1;
			}
		}

		.m-vin-search__vin,
		.m-vin-search__model {

			@include mq-min($bpDesktopLarge) {
				width: 370px;
			}
		}
	}
}
