﻿@import "../config";

.m-tabs
{
	margin: 0;
	padding: 0 0 15px 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__action
	{
		display: none;
		float: right;
		margin: 0;
		padding: 0 0 0 15px;
		@include mq-min($bpDesktop)
		{
			display: block;
		}
	}
	&__menu
	{
		font-size: 0;
		line-height: 0;
		margin: 0 0 15px 0;
		overflow: hidden;
		padding: 0;
		position: relative;
		z-index: 1;
		&:before
		{
			border-bottom: 1px solid $colorDivider;
			bottom: 0;
			content: "";
			display: block;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			z-index: 1;
		}
		&__item
		{
			@include box-sizing();
			display: inline-block;
			margin: 0;
			padding: 0;
			position: relative;
			vertical-align: top;
			max-width: 33.3333%;
			z-index: 2;
		}
		&__link
		{
			@include box-sizing();
			border: 1px solid $colorWhite;
			border-bottom-color: $colorDivider;
			color: $colorLink;
			display: block;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 11px 10px;
			position: relative;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			z-index: 2;
			@include appearance-none();
			cursor: pointer;
			&:hover, &:focus
			{
				color: $colorBase;
				text-decoration: none;
			}
			&.active
			{
				background: $colorWhite;
				border: 1px solid $colorDivider;
				border-bottom-color: $colorWhite;
				color: $colorGrey;
			}
			&.focus-visible {
				@include focus-style-alt();
			}
			@include mq-min($bpDesktop)
			{
				@include font-size($fontSize);
				@include line-height($fontHeight);
				padding: 13px 20px;
			}
			@include mq-min($bpDesktopLarge)
			{
				padding: 13px 30px;
			}
		}
	}
	&__content
	{
		margin: 0;
		padding: 10px 10px 0 10px;
		position: relative;
		z-index: 1;
		@include mq-min($bpDesktop)
		{
			padding: 10px 20px 0 20px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding: 10px 30px 0 30px;
		}
	}
	&__tab
	{
		margin: 0;
		padding: 0;

		.js &:not(:first-child) {
			display: none;
		}

		&__title
		{
			@include font-size($fontSize + 5);
			font-weight: 400;
			@include line-height($fontHeight + 5);
			margin: 0;
			padding: 0 0 15px 0;
			@include mq-min($bpTabletLarge)
			{
				padding-bottom: 20px;
			}
		}
		&__content
		{
			margin: 0;
			padding: 0;
		}
	}
	&--tight
	{
		padding-bottom: 0;
	}
}

.m-tabs--alternate {
	padding: 0;

	.m-tabs__menu {
		margin: 0 0 30px 0;

		@include mq-min($bpDesktopLarge) {
			margin: 0 0 60px 0;
		}
	}

	.m-tabs__menu__item {
		max-width: 100%;

		&:not(:first-child) {
			margin: 0 0 0 10px;

			@include mq-min($bpDesktopLarge) {
				margin: 0 0 0 20px;
			}
		}
	}

	.m-tabs__menu__link {
		text-transform: none;
		@include font-size(14px);
		@include line-height(14px);
		font-weight: 600;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;

		&:not(.active) {
			background: $colorGreyAnother;
			color: $colorBase;
		}

		@include mq-min($bpDesktopLarge) {
			padding: 20px 45px;
		}
	}

	.m-tabs__menu__link__context {

		@include mq-max(399px) {
			border: 0;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
			white-space: nowrap;
		}
	}

	.m-popup & {

		@include mq-min($bpDesktopLarge) {
			position: relative;
		}

		.m-tabs__content {
			padding: 0;

			@include mq-min($bpDesktopLarge) {
				padding: 0 70px;
			}
		}

		.m-tabs__menu {

			@include mq-min($bpDesktopLarge) {
				padding: 0 70px;
			}
		}
	}
}