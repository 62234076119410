﻿$smallPreFooterBreakpoint: $maxPageWidth;

.c-pre-footer {
    background-color: $colorBlue;
    color: $colorWhite;
    margin: 0 -10px;
    padding: 0 20px;
}

.c-pre-footer__inner {
    max-width: 1520px;
    margin: 0 auto;
    padding: 40px 0;

    @include mq-min($bpTabletLarge) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
        padding: 40px 0;
    }

    @include mq-min($smallPreFooterBreakpoint) {
        padding: 60px 0;
    }

    .c-pre-footer__item:last-child {
        @include mq-min($smallPreFooterBreakpoint) {
            margin-left: auto;
        }

        @media screen and (min-width: $bpTabletLarge) and (max-width: $smallPreFooterBreakpoint - 1px) {
            &:after {
                display: none;
            }
        }
    }
}

.c-pre-footer__item {
    max-width: 275px;
    margin: 0 auto 35px auto;
    padding-bottom: 40px;
    border-bottom: 1px solid #3670b0;

    @include mq-min($bpMobileLarge) {
        max-width: 380px;
    }

    .form-button {
        padding: 18px 25px;
        width: 100%;

        @include mq-min($bpMobileLarge) {
            width: auto;
        }

        @include mq-min($bpTabletLarge) {
            max-width: 80%;
            margin: 0 auto;
        }

        @include mq-min($smallPreFooterBreakpoint) {
            min-width: 270px;
            max-width: none;
        }
    }

    @include mq-max($bpTabletLarge - 1) {
        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
        }
    }

    @include mq-min($bpTabletLarge) {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    @include mq-min($smallPreFooterBreakpoint) {
        margin: 0;

        p {
            @include font-size(16px);
        }
    }

    @include mq-max($smallPreFooterBreakpoint - 1px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    @media screen and (min-width: $bpTabletLarge) and (max-width: $smallPreFooterBreakpoint - 1px) {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: -10px;
            background-color: #3670b0;
            width: 1px;
        }
    }
}

.c-pre-footer__title {
    font-weight: 900;
    @include font-size(18px);
    @include line-height(24px);
    padding: 0;
    margin: 0 0 10px 0;

    @include mq-min($bpTabletLarge) {
        margin: 0 0 20px 0;
    }

    @include mq-min($smallPreFooterBreakpoint) {
        @include font-size(28px);
        @include line-height(38px);
    }
}

.c-pre-footer__title--close {
    @include mq-min($smallPreFooterBreakpoint) {
        margin: 0 0 10px 0;
    }
}

.c-pre-footer__title--left-aligned {
    @include mq-min($smallPreFooterBreakpoint) {
        text-align: left;
    }
}

.c-pre-footer__icon-group {
    display: flex;
    align-items: center;
    text-align: left;
}

.c-pre-footer__icon-group--link {
    color: $colorWhite;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $colorWhite;
        text-decoration: none;
    }
}

.c-pre-footer__icon-group-icon {
    margin: 0 auto;
    flex-shrink: 0;

    @include mq-min($smallPreFooterBreakpoint) {
        margin: 0;
    }
}

.c-pre-footer__icon-group-text {
    margin-left: 20px;
    padding: 0;
}

.c-pre-footer__icon-group-text-trustpilot-intro { 
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
}

.c-pre-footer__item-trustpilot {
    max-width: 255px;
    margin: 0 auto -40px auto;

    @include mq-min($bpTabletLarge) {
        margin: 0;
    }
}

.c-pre-footer__hide-small {
    @include mq-max($smallPreFooterBreakpoint - 1px) {
        display: none;
    }
}