﻿@import "../config";

.c-skip-link {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $colorWhite;
    color: $colorBase;
    padding: 5px;
    display: block;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px,1px,1px,1px);
}

.c-skip-link:focus {
    height: auto;
    width: auto;
    clip: auto;
}