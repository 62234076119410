﻿@import "../config";

.m-blog-category
{
	margin: 0;
	padding: 0 0 15px 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__menu
	{
		margin: 0;
		padding: 0;
		&__item
		{
			margin: 0;
			padding: 15px 0 0 0;
			&:first-child
			{
				padding-top: 0;
			}
		}
	}
	&__post
	{
		margin: 0;
		padding: 0;
		&__picture
		{
			margin: 0;
			padding: 0 0 10px 0;
			&__link
			{
				display: block;
				margin: 0;
				padding: 0;
			}
			@include mq-min($bpTablet)
			{
				float: left;
				padding: 0 15px 0 0;
				width: 300px;
			}
			@include mq-min($bpTabletLarge)
			{
				width: 240px;
			}
			@include mq-min($bpDesktop)
			{
				padding-right: 20px;
				width: 240px;
			}
			@include mq-min($bpDesktopLarge)
			{
				padding-right: 25px;
			}
		}
		&__content
		{
			margin: 0;
			overflow: hidden;
			padding: 0;
		}
		&__title
		{
			@include font-size($fontSize + 4);
			font-weight: 600;
			@include line-height($fontHeight + 4);
			margin: 0;
			padding: 0 0 10px 0;
			&__link
			{
				color: $colorBase;
				display: block;
				margin: 0;
				padding: 0;
				text-decoration: none;
				&:hover
				{
					color: $colorBase;
					text-decoration: none;
				}
			}
		}
		&__summary
		{
			@include box-sizing();
			margin: 0;
			padding: 0 0 10px 0;
			&__content
			{
				display: inline;
				margin: 0;
				padding: 0 5px 0 0;
			}
			&__more
			{
				display: inline;
				margin: 0;
				padding: 0;
			}
			@include mq-min($bpTablet)
			{
				@include min-height(100px);
			}
		}
		&__meta
		{
			color: $colorGreyMid;
			font-size: 0;
			line-height: 0;
			margin: 0;
			padding: 5px 0 0 0;
			&__item
			{
				@include box-sizing();
				display: inline-block;
				@include font-size($fontSize - 3);
				@include line-height($fontHeight - 5);
				margin: 0;
				padding: 0 13px 0 0;
				position: relative;
				text-transform: uppercase;
				z-index: 1;
				&:before
				{
					background: $colorDivider;
					content: "";
					display: block;
					height: 10px;
					margin: 0;
					padding: 0;
					position: absolute;
					right: 6px;
					top: 3px;
					width: 1px;
					z-index: 1;
					@include mq-min($bpTabletLarge)
					{
						right: 10px;
					}
				}
				&:last-child
				{
					padding-right: 0;
					&:before
					{
						display: none;
					}
				}
				@include mq-min($bpTabletLarge)
				{
					padding-right: 20px;
				}
			}
		}
		&:hover
		{
			@include mq-min($bpTablet)
			{
				background: lighten($colorOffWhite, 5%);
			}			
		}
		@include mq-min($bpTablet)
		{
			cursor: pointer;
			margin: 0 -10px;
			padding: 15px;
		}
		@include mq-min($bpLarge + 50)
		{
			margin: 0 -15px;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding-bottom: 20px;
	}
	@include mq-min($bpDesktop)
	{
		padding-bottom: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding-bottom: 30px;
	}
	&--tight
	{
		padding-bottom: 0;
	}
}