﻿@import "../config";

.m-addresses
{
	margin: 0;
	padding: 0 0 15px 0;
	max-width: $maxContentWidth;
	&__inner
	{
		border-bottom: 1px solid $colorDivider;
		border-top: 1px solid $colorDivider;
		margin: 0;
		padding: 15px 0 10px 0;
		@include mq-min($bpTabletLarge)
		{
			padding: 20px 0;
		}
	}
	&__menu
	{
		font-size: 0;
		line-height: 0;
		margin: -15px 0 0 -15px;
		padding: 0;
		&__item
		{
			@include box-sizing();
			display: inline-block;
			@include font-size($fontSize);
			@include line-height($fontHeight);
			margin: 0;
			padding: 15px 0 10px 15px;
			vertical-align: top;
			width: 50%;
			@include mq-min($bpTablet)
			{
				width: 33.3333%;
			}
			@include mq-min($bpTabletLarge)
			{
				padding: 20px 0 0 20px;
			}
			@include mq-min($bpDesktopLarge)
			{
				width: 25%;
			}
		}
		&__address
		{
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 0;
			&__link
			{
				color: $colorBase;
				display: inline-block;
				font-weight: 600;
				margin: 0;
				padding: 0 0 5px 0;
				text-decoration: none;
				&:hover
				{
					color: $colorBase;
					text-decoration: underline;
				}
			}
		}
		&__menu
		{
			font-size: 0;
			line-height: 0;
			margin: 0 0 0 -15px;
			padding: 0 0 5px 0;
			&__item
			{
				@include box-sizing();
				display: inline-block;
				@include font-size($fontSize - 3);
				@include line-height($fontHeight - 3);
				margin: 0;
				padding: 10px 0 0 15px;
				vertical-align: top;
			}
			&__link
			{
				font-weight: 600;
				text-decoration: none;
				text-transform: uppercase;
				&:hover
				{
					text-decoration: none;
				}
				&--delete
				{
					color: $colorRed;
					&:hover
					{
						color: $colorBase;
					}
				}
			}
		}
		@include mq-min($bpTabletLarge)
		{
			margin: -20px 0 0 -20px;
		}
	}
	&--tight
	{
		padding-bottom: 0;
	}
}