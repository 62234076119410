﻿.c-search-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.9);
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms;
    padding: 1rem;
    transform: translateY(-100%);

    &[aria-hidden="false"] {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    .focus-visible {
        @include focus-style-alt($colorPrimary);
    }

    @include mq-min($bpDesktop) {
        display: none;

        &.is-active {
            display: none;
        }
    }
}

.c-search-overlay__close {
    display: flex;
    align-items: center;
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: transparent;
    padding: 10px;
    @include font-size(16px);
    font-family: inherit;
    color: $colorWhite;
    border: none;
    cursor: pointer;
}

.c-search-overlay__close-icon {
    margin-left: 10px;
}

.c-search-overlay__content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 100px 0 0 0;
}

.c-search-overlay__form {
    width: 100%;
}

.c-search-overlay__form-row {
    position: relative;
    border: 0;
    margin: 0;
    padding: 0;

    .form-text {
        float: left;
        margin: 0;
        width: 70%;
        max-width: 100%;
        width: 100%;
        background-color: transparent;
        border-color: $colorWhite;
        color: $colorWhite;
        @include placeholder-text($colorWhite,$colorWhite);
        font-family: $fontBase;
        @include font-size(16px);
        line-height: normal;
        height: auto;
        padding: 15px 60px 15px 20px;

        &.input-validation-error {
            color: $colorWhite;
        }
    }

    .form-button {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        border-left: none;
        height: 100%;
        width: 60px;
        border: none;
        background-color: transparent;
        color: $colorWhite;
    }
}