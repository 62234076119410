﻿@import "../config";

.m-side-nav {
	position: relative;
	margin: 0;
	padding: 0 0 15px 0;

	&__title {
		margin: 0;
		padding: 0;

		&__link {
			background: $colorOffWhite;
			color: $colorBase;
			display: block;
			@include font-size(15px);
			font-weight: 600;
			@include line-height($fontHeight);
			margin: 0;
			padding: 10px 25px 10px 10px;
			position: relative;
			text-decoration: none;
			text-transform: uppercase;
			z-index: 1;

			&:before {
				border: 5px solid transparent;
				border-bottom-width: 0;
				border-top-color: $colorBase;
				content: "";
				display: block;
				height: 0;
				margin: -3px 0 0 0;
				padding: 0;
				position: absolute;
				right: 10px;
				top: 50%;
				width: 0;
				z-index: 1;
			}

			&:hover {
				background: darken($colorOffWhite, 5%);
				color: $colorBase;
				text-decoration: none;

				&:before {
					border-top-color: $colorBase;
				}
			}

			&.active {
				background: darken($colorOffWhite, 10%);

				&:before {
					border-bottom-color: $colorBase;
					border-bottom-width: 5px;
					border-top-color: transparent;
					border-top-width: 0;
				}
			}
		}

		@include mq-min($bpTabletLarge) {
			display: none !important;
		}
	}

	&__inner {
		background: lighten($colorDivider, 10%);
		margin: 0;
		padding: 0;

		.js & {
			display: none;

			@include mq-min($bpTabletLarge) {
				display: block !important;
			}
		}

		@include mq-min($bpTabletLarge) {
			background: none;
		}
	}

	&__section {
		margin: 0;
		padding: 0;

		&__link {
			color: $colorBase;
			display: block;
			font-weight: 600;
			margin: 0;
			padding: 10px;
			text-decoration: none;

			&:hover {
				background: darken($colorOffWhite, 5%);
				color: $colorBase;
				text-decoration: none;

				@include mq-min($bpTabletLarge) {
					background: none;
					color: $colorPrimary;
				}
			}

			@include mq-min($bpTabletLarge) {
				@include font-size($fontSize + 4);
				@include line-height($fontHeight + 4);
				padding: 5px 0;
			}
		}

		@include mq-min($bpTabletLarge) {
			padding-bottom: 5px;
		}

		@include mq-min($bpDesktop) {
			padding-bottom: 10px;
			padding-left: 10px;
		}

		@include mq-min($bpDesktopLarge) {
			padding-bottom: 15px;
		}
	}

	&__menu {
		margin: 0;
		padding: 0;

		&__item {
			border-top: 1px solid $colorWhite;
			margin: 0;
			padding: 0;

			@include mq-min($bpTabletLarge) {
				border-bottom: 1px solid $colorDivider;
				border-top: 0;
			}
		}

		&__item:last-child {
			@include mq-min($bpTabletLarge) {
				border-bottom: 0;
			}
		}

		&__link {
			color: $colorBase;
			display: block;
			margin: 0;
			padding: 10px 10px 10px 25px;
			position: relative;
			text-decoration: none;
			z-index: 1;

			&:before {
				background: $colorGreyMid;
				content: "";
				display: block;
				height: 1px;
				left: 12px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 19px;
				width: 5px;
				z-index: 1;

				@include mq-min($bpTabletLarge) {
					display: none;
				}
			}

			&:hover, &.active {
				background: darken($colorOffWhite, 5%);
				color: $colorBase;
				text-decoration: none;

				&:before {
					background: $colorBase;
				}

				@include mq-min($bpTabletLarge) {
					background: none;
					color: $colorPrimary;
				}
			}

			@include mq-min($bpTabletLarge) {
				font-weight: 600;
				padding: 10px 0;
			}

			@include mq-min($bpDesktop) {
				padding: 20px 10px;
			}
		}
	}

	&__sub-menu {
		margin: 0;
		padding: 0 0 15px 0;

		&__item {
			margin: 0;
			padding: 0;
		}

		&__link {
			color: $colorBase;
			display: block;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 5px 10px 5px 25px;
			position: relative;
			text-decoration: none;
			z-index: 1;

			&:hover, &.active {
				color: $colorPrimary;
				text-decoration: none;
			}

			&.active {
				font-weight: 600;
				background-position: 10px 50%;
				background-repeat: no-repeat;
				background-size: 8px;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2LjUxMyIgaGVpZ2h0PSIxMi4wNTMiIHZpZXdCb3g9IjAgMCA2LjUxMyAxMi4wNTMiPg0KICA8ZyBpZD0iQ29tcG9uZW50XzgzXzEiIGRhdGEtbmFtZT0iQ29tcG9uZW50IDgzIOKAkyAxIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxLjQxMyAxLjQxNCkiPg0KICAgIDxwYXRoIGlkPSJQYXRoXzIzNiIgZGF0YS1uYW1lPSJQYXRoIDIzNiIgZD0iTTE3ODg1Ljk0Myw3NzQuOWw0LjgsNC4xLDQuNDI2LTQuMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTc3NC45IDE3ODk1LjE3NCkgcm90YXRlKC05MCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzA5NTA5ZSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiLz4NCiAgPC9nPg0KPC9zdmc+DQo=);

				@include mq-min($bpDesktop) {
					background-position: 0 50%;
				}
			}
		}
	}

	&__in-section-title {
		font-weight: 600;
		font-style: italic;
		margin-top: -10px;
		padding-bottom: 15px;
		border-bottom: 1px solid $colorDivider;
		color: $colorGreyMid;

		@include mq-min($bpDesktop) {
			padding-left: 10px;
			margin-top: -15px;
		}
	}

	&__reveal {
		margin: 0;
		padding: 0;
		position: relative;
		@include transition(max-height .5s);
		z-index: 1;

		&__action {
			display: none;
			margin: 0;
			padding: 10px 0 0 0;

			&__link {
				display: inline-block;
				@include font-size($fontSize - 1);
				font-weight: 600;
				@include line-height($fontHeight - 1);
				margin: 0;
				padding: 0;

				&:after {
					content: " +";
					font-family: $fontSimple;
					@include font-size($fontSize);
					font-weight: 400;
				}
			}

			@include mq-min($bpDesktop) {
				padding: 10px 10px 0 10px;
			}

			.js & {
				@include mq-min($bpTabletLarge) {
					display: block;
				}
			}
		}

		.js & {
			@include mq-min($bpTabletLarge) {
				overflow: hidden;
				max-height: 205px;
			}
		}

		&.active {
			@include mq-min($bpTabletLarge) {
				max-height: 1000px;
			}
		}
	}

	@include mq-min($bpTabletLarge) {
		padding-bottom: 20px;
	}

	@include mq-min($bpDesktop) {
		padding-bottom: 25px;
	}

	@include mq-min($bpDesktopLarge) {
		padding-bottom: 30px;
	}

	&:last-child, &--tight {
		padding-bottom: 0;
	}

	&--divider {
		@include mq-min($bpTabletLarge) {
			border-bottom: 1px solid $colorDivider;
			margin-bottom: 15px;
			padding-bottom: 15px;
		}
	}
}