﻿@import "../config";

.c-image {
  position: relative;
  @include ratio(16,9);
  display: block;
  overflow: hidden;
  width: 100%;
  width: 100%;

  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: block;
  }
}

.c-image--banner {
  @include ratio($ratioImageBannerWidth, $ratioImageBannerHeight);
}

.c-image--category-product {
  @include ratio(580,435);
}

.c-image--image-block {
  @include ratio(800,450);
}

.c-image--recent-image {
  @include ratio(188,142);
}

.c-image--product {
  @include ratio($ratioImageProductWidth, $ratioImageProductHeight);
}

.c-image--product-pager {
  @include ratio(145,110);
}

.c-image--promo {
  @include ratio(300,150);
}

.c-image--header,
.c-image--category {
  @include ratio(240,135);
}

.c-image--blog {
  @include ratio(240,160);
}

.c-image--logo {
  @include ratio(191, 64);
}
