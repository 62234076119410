﻿@import "../config";

.m-content-banner
{
	@include box-sizing();
	margin: 0 -10px;
	position: relative;
	z-index: 1;
	&__image
	{
		background: $colorBase no-repeat 50% 50%;
		@include background-size(cover);
		overflow: hidden;
		padding: 0;
		position: relative;
		z-index: 1;
		&:before
		{
			content: "";
			display: block;
			margin: 0;
			padding: 46% 0 0 0;
			@include mq-min($bpDesktop)
			{
				display: none;
			}
		}
		@include mq-min($bpDesktop)
		{
			left: 0;
			height: 100%;
			max-height: 400px;
			position: absolute;
			top: 0;
			width: 68%;
		}
	}
	&__content
	{
		background: $colorWhite;
		@include box-sizing();
		margin: 0;
		padding: 15px;
		position: relative;
		z-index: 2;
		@include mq-min($bpDesktop)
		{
			float: right;
			overflow: hidden;
			padding: 25px 5px 25px 35px;
			width: 52%;
			width: calc(33.3333% + 240px);
		}
		@include mq-min($bpDesktopLarge)
		{
			padding: 35px 0 35px 45px;
		}
		@include mq-min($bpLarge)
		{
			padding: 40px 0 40px 50px;
		}
	}
	&__icon
	{
		float: left;
		margin: -70px 0 0 0;
		padding: 0;
		position: relative;
		z-index: 2;
		@include mq-min($bpDesktop)
		{
			display: none;
		}
		&--alt
		{
			display: none;
			margin: 2px 5px 0 0;
			max-width: 50px;
			@include mq-min($bpDesktop)
			{
				display: block;
			}
		}
	}
	&__title
	{
		@include font-size($fontSize + 10);
		@include line-height($fontHeight + 10);
		padding-bottom: 15px;
		@include mq-min($bpDesktop)
		{
			@include font-size($fontSize + 13);
			@include line-height($fontHeight + 13);
		}
		@include mq-min($bpDesktopLarge)
		{
			@include font-size($fontSize + 16);
			@include line-height($fontHeight + 16);
		}
	}
	&__summary
	{
		clear: both;
		@include line-height($fontHeight + 6);
		overflow: hidden;
	}
	@include mq-min($bpDesktop)
	{
		min-height: 400px;
		margin: 0;
		padding-top: 35px;
	}
}