﻿@import "../config";

.m-checkout-help
{
	background: $colorOffWhite;
	margin: 0 0 15px 0;
	padding: 15px;
	text-align: center;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__title
	{
		@include font-size($fontSize + 4);
		font-weight: 600;
		@include line-height($fontHeight + 4);
		margin: 0;
		padding: 0 0 15px 0;
		@include mq-min($bpTabletLarge)
		{
			padding-bottom: 20px;
		}
	}
	&__summary
	{
		@include font-size($fontSize - 1);
		@include line-height($fontHeight - 1);
		margin: 0 auto;
		padding: 0;
		max-width: 280px;
		a
		{
			font-weight: 700;
			color: $colorBase;
			text-decoration: none;
			&:hover
			{
				color: $colorPrimary;
				text-decoration: none;
			}
		}
	}
	&__phone, &__email
	{
		color: $colorBase;
		display: block;
		margin: 0;
		padding: 5px 0 0 0;
	}
	&__phone
	{
		@include font-size($fontSize + 8);
		@include line-height($fontHeight  + 8);
		font-weight: 700;
		@include mq-min($bpTabletLarge)
		{
			@include font-size($fontSize + 14);
			@include line-height($fontHeight  + 14);
		}
	}
	&__email
	{
		@include font-size($fontSize + 2);
		@include line-height($fontHeight  + 2);
		@include mq-min($bpTabletLarge)
		{
			@include font-size($fontSize + 4);
			@include line-height($fontHeight  + 4);
		}
	}
	&__notes
	{
		color: $colorGreyMid;
		@include font-size($fontSize - 2);
		@include line-height($fontHeight - 2);
		margin: 15px auto 0 auto;
		padding: 15px 0 0 0;
		position: relative;
		max-width: 220px;
		z-index: 1;
		&:before
		{
			background: $colorGreyLight;
			content: "";
			display: block;
			height: 1px;
			left: 50%;
			margin: 0 0 0 -30px;
			padding: 0;
			position: absolute;
			top: 0;
			width: 60px;
			z-index: 1;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		margin-bottom: 20px;
		padding: 20px;
	}
	@include mq-min($bpDesktop)
	{
		margin-bottom: 25px;
		padding: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		margin-bottom: 30px;
		padding: 30px;
	}
	&--tight
	{
		margin-bottom: 0;
	}
}