﻿@import "../config";

.m-link-list
{
	&__title
	{
		@include font-size($fontSize + 2);
		@include line-height($fontHeight + 2);
		padding-bottom: 5px;
	}
	&__menu
	{
		font-size: 0;
		line-height: 0;
		list-style: none;
		margin: 0;
		padding: 0;
		&__item
		{
			display: inline-block;
			@include font-size($fontSize);
			@include line-height($fontHeight);
			margin: 0;
			padding: 5px 0 0 0;
			position: relative;
			vertical-align: top;
			z-index: 1;
			&:not(:last-child)
			{
				padding-right: 26px;
				&:before
				{
					background: $colorGrey;
					@include border-radius(100%);
					content: "";
					display: block;
					height: 4px;
					right: 11px;
					position: absolute;
					top: 13px;
					width: 4px;
					z-index: 1;
				}
			}
		}
		&__link
		{
			color: $colorGrey;
			text-decoration: none;
			&:hover
			{
				color: $colorLink;
				text-decoration: none;
			}
		}
	}
}