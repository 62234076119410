﻿@import "../config";

.m-history
{
	margin: 0;
	padding: 0 0 15px 0;
	&__inner
	{
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;
	}
	&__timeline
	{
		display: none;
		height: 90px;
		left: 0;
		margin: 0 20px;
		padding: 0;
		position: absolute;
		top: 545px;
		right: 0;
		z-index: 2;
		&:before
		{
			background: $colorDivider;
			content: "";
			display: block;
			height: 1px;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			top: 60px;
			z-index: 1;
		}
		&__inner
		{
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 2;
		}
		&__year, &__event
		{
			display: block;
			margin: 0;
			overflow: hidden;
			padding: 0;
			position: absolute;
			text-align: center;
		}
		&__year
		{
			color: $colorGrey;
			@include font-size($fontSize - 2);
			height: 40px;
			@include line-height($fontHeight - 2);
			margin-left: -20px;
			top: 20px;
			width: 40px;
			&:before
			{
				background: $colorDivider;
				bottom: 0;
				content: "";
				display: block;
				height: 20px;
				left: 20px;
				margin: 0;
				padding: 0;
				position: absolute;
				width: 1px;
				z-index: 1;
			}
		}
		&__event
		{
			height: 10px;
			margin-left: -5px;
			top: 55px;
			width: 10px;
			&__link
			{
				background: $colorGreyLight;
				@include border-radius(100%);
				display: block;
				height: 100%;
				margin: 0;
				padding: 0;
				position: relative;
				@include text-hidden();
				z-index: 1;
				&:hover, &.active
				{
					background: $colorPrimary;
					color: $colorWhite;
					z-index: 2;
				}
			}
		}
		.js &
		{
			@include mq-min($bpDesktop)
			{
				display: block;
			}
		}
	}
	&__content
	{
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;
		.bx-prev, .bx-next
		{
			background: none;
			height: 80px;
			@include opacity(.25);
			top: 315px;
			width: 30px;
			@include mq-min($bpMobileLarge)
			{
				top: 345px;
			}
			@include mq-min($bpTablet)
			{
				top: 425px;
			}
			@include mq-min($bpTabletLarge)
			{
				top: 400px;
			}
			@include mq-min($bpDesktop)
			{
				top: 630px;
			}
		}
	}
	&__menu
	{
		margin: 0;
		padding: 0;
		&__item
		{
			margin: 0;
			padding: 0;
			.js &
			{
				display: none;
			}
			&:first-child
			{
				.js &
				{
					display: block;
				}
			}
		}
	}
	&--tight
	{
		padding-bottom: 0;
	}
}