﻿@import "../config";

.m-model-summary
{
	background: lighten($colorDivider, 10%);
	border: 1px solid $colorDivider;
	margin: 0 0 15px 0;
	padding: 15px;
	&__inner
	{
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;
	}
	&__title
	{
		@include font-size($fontSize + 4);
		font-weight: 600;
		@include line-height($fontHeight + 4);
		margin: 0;
		padding: 0 0 10px 0;
		@include mq-min($bpMobileLarge)
		{
			padding-right: 150px;
		}
	}
	&__details
	{
		margin: 0;
		padding: 0 0 15px 0;
	}
	&__menu
	{
		font-size: 0;
		line-height: 0;
		margin: -5px 0 0 -5px;
		padding: 0;
		text-align: center;
		&__item
		{
			@include box-sizing();
			display: inline-block;
			@include font-size($fontSize);
			@include line-height($fontHeight);
			font-size: $fontSize;
			line-height: $fontHeight;
			margin: 0;
			padding: 5px 0 0 5px;
			text-align: center;
			vertical-align: top;
			width: 50%;
			@include mq-min($bpTablet)
			{
				width: 25%;
			}
		}
		&__link
		{
			background: $colorPrimary;
			color: $colorWhite;
			display: block;
			font-weight: 600;
			height: 40px;
			margin: 0;
			padding: 10px 5px;
			position: relative;
			text-decoration: none;
			z-index: 1;
			&__inner
			{
				display: block;
				margin: 0;
				padding: 0;
				.csstransforms &
				{
					position: relative;
					top: 50%;
					@include transform(translateY(-50%));
				}
			}
			&:hover
			{
				background: $colorBase;
				color: $colorWhite;
				text-decoration: none;
			}
		}
	}
	&__clear
	{
		@include font-size($fontSize - 1);
		@include line-height($fontHeight - 1);
		margin: -5px 0 0 0;
		padding: 0 0 15px 0;
		position: relative;
		z-index: 1;
		&__link
		{
			color: $colorGreyMid;
			text-decoration: none;
			&:hover
			{
				color: $colorBase;
				text-decoration: none;
			}
		}
		@include mq-min($bpMobileLarge)
		{
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			top: 2px;
			max-width: 145px;
			z-index: 1;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		margin-bottom: 20px;
	}
	@include mq-min($bpDesktop)
	{
		margin-bottom: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		margin-bottom: 25px;
	}
	&--tight
	{
		margin-bottom: 0;
	}
}