﻿.c-truncate {

  .js &:not([data-truncate="off"]) {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal;

    &.m-content-banner__summary {
      height: 104px;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }
}

.c-truncate-more {
  padding: 20px 0 0 0;
  margin: 0;
}

.c-truncate-more__button {
  color: $colorPrimary;
  display: inline-block;
  @include font-size($fontSize + 1);
  font-weight: 600;
  @include line-height($fontHeight + 1);
  padding-left: 25px;
  position: relative;
  z-index: 1;
  background: none;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;

  &:before, &:after {
    border-top: 2px solid $colorPrimary;
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    z-index: 1;
  }

  &:before {
    left: 0;
    top: 9px;
  }

  &:after {
    left: 0;
    top: 9px;
    @include rotate(-90deg);
  }

  &:hover {
    color: $colorBase;

    &:before, &:after {
      border-color: $colorGrey;
    }
  }

  &[data-truncate-more-button="expanded"] {
    &:before {
      @include rotate(45deg);
    }

    &:after {
      @include rotate(-45deg);
    }
  }
}