﻿@import "../config";

.m-order-summary
{
	margin: 0;
	padding: 0 0 15px 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__details
	{
		background: $colorOffWhite;
		margin: 0 0 15px 0;
		padding: 15px;
		&__inner
		{
			font-size: 0;
			line-height: 0;
			margin: -10px 0 0 -10px;
			padding: 0;
		}
		&__group
		{
			@include box-sizing();
			display: inline-block;
			@include font-size($fontSize - 1);
			@include line-height($fontHeight - 1);
			margin: 0;
			padding: 10px 0 0 10px;
			vertical-align: top;
			width: 50%;
			&__title
			{
				@include font-size($fontSize - 1);
				font-weight: 600;
				@include line-height($fontHeight - 1);
				margin: 0;
				padding: 0;
			}
			&__value
			{
				margin: 0;
				padding: 0;
			}
			@include mq-min($bpTablet)
			{
				width: 25%;
			}
		}
		@include mq-min($bpTabletLarge)
		{
			margin-bottom: 20px;
		}
		@include mq-min($bpDesktop)
		{
			margin-bottom: 25px;
		}
		@include mq-min($bpDesktopLarge)
		{
			margin-bottom: 30px;
		}
		&--tight
		{
			margin-bottom: 0;
		}
	}
	&__content
	{
		margin: 0;
		padding: 15px 0 0 0;
		&--close
		{
			padding-top: 0;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding-bottom: 20px;
	}
	@include mq-min($bpDesktop)
	{
		padding-bottom: 25px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding-bottom: 30px;
	}
	&--tight
	{
		padding-bottom: 0;
	}
}