@import "../config";

.l-header {
	position: relative;
	margin: 0 -10px;
	padding: 0 15px;
	background-color: $colorWhite;
	transition: background-color 250ms;

	@include mq-min($bpLarge) {
		margin: 0;
	}

	&__inner {
		@include box-sizing();
		font-size: 0;
		line-height: 0;
		margin: 0 auto;
		padding: 15px 0 0 0;
		position: relative;
		text-align: center;
		max-width: 1520px;
		z-index: 8888;

		@include mq-min($bpMobileLarge) {
			padding: 20px 0 0 0;
			text-align: left;
		}

		@include mq-min($bpDesktop) {
			display: flex;
			align-content: center;
			padding: 40px 0;
		}
	}

	&--slim .l-header__inner {
		@include mq-max($bpDesktop - 1px) {
			padding: 20px 0;
		}
	}

	&__logo {
		display: block;
		float: left;
		margin: 0;
		padding: 0;
		position: relative;
		vertical-align: middle;
		width: 100%;
		width: 105px;
		z-index: 2;

		&__link {
			display: block;
			margin: 0;
			padding: 0;
		}

		&__image {
			display: block;
			margin: 0;
			padding: 0;
		}

		@include mq-min($bpMobileLarge) {
			margin: 0 10px 0 0;
			padding: 0;
		}

		@include mq-min($bpTablet) {
			margin-right: 30px;
		}

		@include mq-min($bpDesktop) {
			width: 191px;
			margin-right: 15px;
		}

		@media (min-width: $bpDesktop) and (max-width: $bpLarge) {
			width: 130px;
			margin-top: 10px;
		}

		@include mq-min(1400px) { // was 1290px ($bpLarge)
			margin-right: 100px;
		}
	}

	&__skip {
		font-size: 0;
		left: -15px;
		line-height: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		top: 0;
		max-width: 100%;
		z-index: 3;

		&__item {
			display: block;
			@include font-size($fontSize);
			@include line-height($fontHeight);
			margin: 0;
			padding: 0;
		}

		&__link {
			background: $colorWhite;
			color: $colorBase;
			display: block;
			margin: 0;
			padding: 5px;
			text-decoration: none;
			position: fixed;
			top: -200px;
			width: auto;
			z-index: 1;

			&:hover, &:focus {
				color: $colorBase;
			}

			&:focus {
				left: 0;
				top: 0;
				z-index: 100;
			}
		}
	}

	&__adv-search__block {
		display: none;

		@include mq-min($bpDesktop) {
			display: block;
		}
	}

	&__adv-search {
		color: $colorWhite;
		background-color: $colorPrimary;
		margin: 15px -15px 0;
		padding: 0;
		text-align: center;

		&__link {
			align-items: center;
			justify-content: center;
			@include font-size(14px);
			@include line-height(19px);
			font-weight: 600;
			color: $colorWhite;
			font-style: normal;
			margin: 0;
			padding: 13px 0;
			text-decoration: none;
			width: 100%;

			&:hover {
				color: $colorWhite;
				text-decoration: none;
			}
		}

		&__link__search-icon {
			margin: 0 10px -3px 0;
		}

		&__link__chevron-icon {
			margin: 0 0 -2px 10px;
		}


		@include mq-min($bpMobileLarge) {
			display: block;
		}

		@include mq-min($bpDesktop) {
			display: none;
		}

		&--mobile {
			@include mq-min($bpDesktop) {
				display: none;
			}
		}
	}

	&__help {
		display: none;
		@include font-size($fontSize - 1);
		font-weight: 600;
		@include line-height($fontHeight - 1);
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		text-align: right;
		top: 30px;
		max-width: 190px;
		z-index: 1;

		&__phone {
			color: $colorPrimary;
			display: block;
			@include font-size($fontSize + 2);
			@include line-height($fontHeight + 2);
			font-weight: 700;
			margin: 0;
			padding: 0;

			@include mq-min($bpDesktop) {
				@include font-size($fontSize + 8);
				@include line-height($fontHeight + 8);
			}
		}

		@include mq-min($bpTabletLarge) {
			display: block;
		}

		@include mq-min($bpDesktop) {
			@include font-size($fontSize + 1);
			@include line-height($fontHeight + 1);
			top: 50px;
			max-width: 250px;
		}
	}

	&--slim .l-header__help {
		@include mq-max($bpDesktop - 1px) {
			top: 20px;
		}
	}

	&__secure {
		background: $colorOffWhite;
		font-size: 0;
		@include min-height(24px);
		line-height: 0;
		margin: 0 -10px;
		padding: 15px 10px;
		text-align: center;

		&__inner {
			color: $colorBase;
			display: inline-block;
			@include font-size($fontSize - 1);
			font-weight: 400;
			@include min-height(17px);
			@include line-height($fontHeight - 1);
			margin: 0 auto;
			padding: 2px 0 0 25px;
			position: relative;
			text-transform: uppercase;
			max-width: $maxPageWidth;
			z-index: 1;

			&:before {
				@include bg('/images/prima/common/sprite-v2.png', no-repeat, -210px -130px);
				content: "";
				display: block;
				height: 19px;
				left: 0;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 2px;
				width: 14px;
				z-index: 1;
			}

			@include mq-min($bpMobileLarge) {
				padding-right: 25px;
			}
		}
	}

	@include mq-min($bpLarge) {
		padding: 0;
	}
}

.l-header__search-form {
	display: flex;
	align-items: center;
	margin-left: auto;

}

.l-header__search-row {
	position: relative;
	width: 220px;

	@include mq-min(1150px) {
		width: 265px;
	}

	@include mq-min($bpLarge) {
		width: 300px;
	}
}

.l-header__search-input {
	box-sizing: border-box;
	font-family: inherit;
	@include font-size(16px);
	font-weight: 600;
	color: $colorBase;
	padding: 10px 30px 10px 5px;
	border: none;
	border-bottom: 1px solid $colorBase;
	width: 100%;
	@include placeholder-text($colorBase, $colorBase);
}

.l-header__search-button {
	background-color: transparent;
	border: none;
	color: $colorBase;
	position: absolute;
	top: 50%;
	right: 0;
	bottom: 0;
	cursor: pointer;
	margin-top: -15px;
}

@include mq-max($bpDesktop) {
	.mobile-nav-open .l-header {
		background-color: $colorPrimary;
	}

	.mobile-nav-open .l-header__logo {
		filter: invert(99%) sepia(75%) saturate(330%) hue-rotate(270deg) brightness(115%) contrast(100%);
	}
}
