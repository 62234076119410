﻿@import "../config";

.m-trade-search {
	background-color: $colorOffWhite;
	margin: 0 -10px;
	padding: 15px 15px 20px;
	text-align: left;

	@include mq-min($bpTabletLarge) {
		padding-top: 25px;
		padding-bottom: 30px;
	}
}

.m-trade-search__inner {
	margin: 0 auto;
	padding: 0;
	max-width: 1520px;

	@include mq-min($bpTabletLarge) {
		display: flex;
		justify-content: center;
	}
}

.m-trade-search__model {
	margin: 15px 0 0 0;

	@include mq-min($bpTabletLarge) {
		margin: 0 0 0 30px;
		max-width: calc(60% - 15px);
	}
}

.m-trade-search__vin {

	@include mq-min($bpTabletLarge) {
		max-width: calc(40% - 15px);
	}
}

.m-trade-search__title {
	margin: 0 0 15px 0;
	padding: 0;
}

.m-trade-search__form {

	@include mq-min($bpTabletLarge) {
		display: flex;
	}
}

.m-trade-search__form__row {

	&:not(:first-child) {
		margin: 15px 0 0 0;

		@include mq-min($bpTabletLarge) {
			margin: 0 0 0 15px;
		}
	}
}

.m-trade-search__button {
	height: 38px;
	padding: 0 15px;
	line-height: 38px;
	min-width: 100px;
}

.m-trade-search__text,
.m-trade-search__select {

	&:focus {
		outline: 2px solid $colorBase !important;
	}

	@include mq-max($bpTabletLarge - 1) {
		width: 100%;
		max-width: 100%;
	}
}

.m-trade-search__select {

	@include mq-min($bpDesktopLarge) {
		width: 180px;
	}
}