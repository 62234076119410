﻿@import "../config";

.m-shipment-header
{
	background: $colorOffWhite;
	margin: 15px 0 0 0;
	padding: 15px;
	&__title
	{
		font-family: $fontBase;
		@include font-size($fontSize + 2);
		font-weight: 600;
		@include line-height($fontHeight + 2);
		margin: 0;
		padding: 0;
		small
		{
			display: block;
			@include font-size($fontSize - 2);
			font-style: italic;
			font-weight: 400;
			@include line-height($fontHeight - 2);
			margin: 2px 0;
			padding: 0;
			@include mq-min($bpTabletLarge)
			{
				float: right;
			}
		}
	}
	&__summary
	{
		clear: both;
		margin: 0;
		padding: 5px 0 0 0;
		a
		{
			font-weight: 600;
		}
	}
}