﻿@import "../config";

.m-recents
{
	height: 144px;
	margin: 0;
	overflow: hidden;
	padding: 0;
	&__menu
	{
		font-size: 0;
		line-height: 0;
		margin: -13px 0 0 -13px;
		overflow: hidden;
		padding: 0;
		&__item
		{
			display: inline-block;
			margin: 0;
			padding: 13px 0 0 13px;
			position: relative;
			vertical-align: middle;
			z-index: 1;
			width: 186px;
			&--empty
			{
				display: none;
				height: 142px;
				&:before
				{
					background: $colorWhite;
					border: 1px solid $colorOffWhite;
					bottom: 0;
					content: "";
					display: block;
					left: 13px;
					margin: 0;
					padding: 0;
					position: absolute;
					right: 0;
					top: 13px;
					z-index: 1;
				}
				@include mq-min($bpTablet)
				{
					display: inline-block;
				}
			}
		}
		&__link
		{
			border: 1px solid $colorDivider;
			display: block;
			margin: 0;
			padding: 0;
			&:hover
			{
				border-color: $colorGrey;
			}
		}
	}
}