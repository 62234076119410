﻿@import "../config";

.m-rss
{
	margin: 0;
	padding: 15px 0;
	&__link
	{
		color: $colorSocialRss;
		display: inline-flex;
		align-items: center;
		font-weight: 600;
		@include min-height(17px);
		margin: 0;
		padding: 0 10px 0 0;
		text-decoration: none;
		z-index: 1;
		&:hover
		{
			color: $colorBase;
			text-decoration: none;
		}
	}
	&--close
	{
		padding-top: 0;
	}
	&--tight
	{
		padding-bottom: 0;
	}
}